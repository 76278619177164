import axios from 'axios';
import {
    X_TENANT_ID,
    SETTINGS,
    ADVANCE_GET_REQUEST,
    ADVANCE_POST_REQUEST,
    UPLOAD_CUSTOM_ARTWORK,
    PAYMENTS_ORDER,
    PUNCH_OUT_ORDER,
    ADVANCE_ORDER
} from '../../common/ActionUrl';
const requestOptions = {
    headers: { 'X-TENANT-ID': X_TENANT_ID },
}
export const fetchOrders = (limit = 50, offset = 0, order = "", orient = "asc", filters = {}) => {
    let url = `/api/v1/orders?source=cp&limit=${limit}&offset=${offset}&order=${order}&orient=${orient}&term[orderType][]=Order&term[orderType][]=CreditMemo&term[orderType][]=StoreOrder`;
    for (let key in filters) {
        if (filters[key] && Array.isArray(filters[key])) {
            if (key == 'paymentStatus' && filters[key] == 0) {
                url += `&term[${key}][]=All`
            } else {
                filters[key].forEach(el => {
                    url += `&term[${key}][]=${el}`
                });
            }
        } else if (filters[key] && (key === 'contact_id' || key === 'account_id' || key === 'source' || key === 'cp_user_id')) {
            url += `&${key}=${filters[key]}`;
        } else if (!Array.isArray(filters[key])) {
          if(key === 'orderSource')
            url += `&termIn[${key}][]=${filters[key]}`
          else 
            url += `&term[${key}]=${filters[key]}`
        }
    }
    return axios.post(ADVANCE_GET_REQUEST, {url}, requestOptions);
}


export const fetchOrdersByUser = (approver, groups, limit = 50, offset = 0, order = "", orient = "asc") => {
    const params = {
        limit,
        offset,
        order,
        orient,
        approver,
        groups
    }

    let url = `/api/v1/hold-orders`;
    return axios.get(url, {...requestOptions, params});
}

export const updateStatusHold = ( params) => {
    return axios.post('/api/v1/advance/order-hold/status', params)
}


export const fetchAllCountryList = () => {
    return axios.post(ADVANCE_GET_REQUEST, {
        url: '/api/v1/orders/global-country-list'
    });
};
export const fetchAllStates = (data) => {
    return axios.post(`api/v1/advance/orders/global-state-list-by-country`, data)
}


export const fetchQuotes = (limit = 50, offset = 0, order = "", orient = "asc", filters = {}) => {
    let url = `/api/v1/orders?limit=${limit}&offset=${offset}&order=${order}&orient=${orient}&term[orderType][]=Quote`;
    for (let key in filters) {
        if (filters[key] && Array.isArray(filters[key])) {
            filters[key].forEach(el => {
                url += `&term[${key}][]=${el}`
            });
        } else if (filters[key] && (key === 'contact_id' || key === 'account_id' || key === 'source' || key === 'cp_user_id')) {
            url += `&${key}=${filters[key]}`;
        } else if (filters[key] && filters[key].length && !Array.isArray(filters[key])) {
          if(key === 'orderSource')
            url += `&termIn[${key}][]=${filters[key]}`
          else 
            url += `&term[${key}]=${filters[key]}`
        }
    }
    return axios.post(ADVANCE_GET_REQUEST, {url}, requestOptions);
}
export const uploadArtwork = (data) => {
    let URL = window.advanceApiurl;
    if (window.advanceApiurl == undefined || window.advanceApiurl == "undefined") {
        URL = ""
    }
    let url = URL + `/content/file-upload`;
    const formData = new FormData()
    formData.append('File', data.File, data.File.name)
    return axios.post(UPLOAD_CUSTOM_ARTWORK, formData);
};
export const createArtwork = (data) => {
    let url = `api/v1/artwork`;
    let params = {url: url, params: data};
 	return axios.post(ADVANCE_POST_REQUEST, params, requestOptions);
};

export const createCustomArtwork = (data) => {
    let url = `/api/v1/artworkdata/custom`;
 	let params = {url: url, params: data};
 	return axios.post(ADVANCE_POST_REQUEST, params, requestOptions);
}

export const fetchQuote = (quoteId) => {
    let url = `/api/v1/orders/` + quoteId + "?source=cp";
    return axios.post('api/v1/advance/get-values', {url});
};
export const fetchOrderPayments = (quoteId) => {
    let advanceUrl = ""
    if (window.advanceApiurl) {
        advanceUrl = window.advanceApiurl
    }
    let url = advanceUrl + "/content/get-payment-track";
    let params = {url: url, params: { oId: quoteId }};
 	return axios.post(ADVANCE_POST_REQUEST, params, requestOptions);
};

export const fetchOrderPaymentsAether = (quoteId) => {
    return axios.get(PAYMENTS_ORDER + quoteId, requestOptions);
};

export const deleteOrderItem = (orderId, itemId, userId = null, details = null) => {
    return axios.delete(`${ADVANCE_ORDER}/${orderId}/items/${itemId}`, {
        params: userId ? {
            userId,
            details
        } : undefined
    });
}

export const creatOrderItem = (orderId, item, userId) => {
    const params = {
        userId
    }
    return axios.post(`${ADVANCE_ORDER}/${orderId}/items/`, item, { params });
}


export const punchOutOrderCheckout = (requestDataParams) => {
    return axios.post(PUNCH_OUT_ORDER,requestDataParams, requestOptions);
};


export const fetchOrderOnHoldPendingByOrderId = (orderId) => {
    let url = `/api/v1/hold-orders/order/${orderId}`;
    return axios.get(url, requestOptions);
}