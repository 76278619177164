import { useContext, useEffect, useState } from "react"
import { AuthContext } from "./Auth"
import { Link, useHistory, Redirect, Route, useLocation } from "react-router-dom";
import {SSOSIGNIN, SSOTOKENSIGNIN, STORE_ADMIN_LOGIN, X_TENANT_ID} from "./ActionUrl";
import axios from 'axios';

//TODO: You should redirect to the url you wanted initially.
const Authentication = ({ children }) => {
    const location = useLocation()
    const { user, settingData, userType, isPublic, login, logout, enableLandingPage, landingPageRedirect, ipV4, accessToken } = useContext(AuthContext)
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    
    let impersonateUser = sessionStorage.getItem("impersonateUser") ? JSON.parse(sessionStorage.getItem("impersonateUser")) : ""
    
    useEffect(() => {
        if (user?.impersonateUser && impersonateUser == "") {
            logout()
        }
    }, [user])
    
    
    const loginRequest = async (requestData, redirectTo, impersonating) => {
        const requestOptions = {
            headers: { 'Content-Type': 'application/json', 'X-TENANT-ID': X_TENANT_ID },
        };
        
        await axios
            .post(STORE_ADMIN_LOGIN, requestData, requestOptions)
            .then(async (res)=>{
                let data = res?.data
                data.impersonateUser = impersonating
                login(data);
                setLoading(false);
                return history.push(redirectTo);
    
            })
            .catch((err) => {
                setLoading(false)
                console.log('err', err);
            });
    }
    
    const getSessionFromSSO = async (accessToken, tokenId, code)=>{
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                'X-TENANT-ID': X_TENANT_ID,
                'access-token': accessToken,
                'token-id': tokenId,
                'client-ip': ipV4,
            },
        }
        if ( code ) {
            requestOptions.headers.code = code
        }
        await axios.get(SSOSIGNIN, requestOptions).then(x=>{
            if (x.data?.accessToken) {
                localStorage.setItem('sso-token', x.data.accessToken)
            }
            login(x.data);
            return history.push('/');
        }).catch(err=>{
            console.log(err)
            return history.push('/');
        })
    }
    const getSessionFromSSOToken = async (accessToken)=>{
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                'X-TENANT-ID': X_TENANT_ID,
                'access-token': accessToken,
            },
        }
        await axios.get(SSOTOKENSIGNIN, requestOptions).then(x=>{
            login(x.data);
            return history.push('/');
        }).catch(err=>{
            console.log(err)
            return history.push('/');
        })
    }

    
    useEffect(async () => {
        const params = new URLSearchParams(window.location.search);
        const punchOutSession = params.get("poc-ssid")
        const punchOutUser = params.get("poc-userId")
        let getImpersonateUserId = params.get("users")
        const tokenId = params.get('id_token')
        const accessToken = params.get('access_token')
        const ssoCode = params.get('code')
        const ssoToken = params.get('sso_token')
        if ( (tokenId && accessToken) || ssoCode ) {
            localStorage.removeItem('sso-token')
            localStorage.removeItem('user')
            localStorage.removeItem('impersonateUser')
            setLoading(true)
            await getSessionFromSSO(accessToken, tokenId, ssoCode)
            
        } else if(ssoToken){
            localStorage.removeItem('sso-token')
            localStorage.removeItem('user')
            localStorage.removeItem('impersonateUser')
            setLoading(true)
            await getSessionFromSSOToken(ssoToken)
        } else if (getImpersonateUserId) {
            console.count();
            setLoading(true)
            if (impersonateUser?.isImpersonateUser === true) {
                localStorage.removeItem('user')
                // localStorage.removeItem('contactDetails')
                localStorage.removeItem('costCenter')
                localStorage.removeItem('categoriesByUserGroup');
            }
            let requestData = {
                userId: getImpersonateUserId
            }
            let impersonate = {
                isImpersonateUser : true,
                userName:`abc`
            }
            sessionStorage.setItem("impersonateUser", JSON.stringify(impersonate))
            await loginRequest(requestData, "/", true)
        }
        else if(punchOutSession) {
            localStorage.removeItem("punch-out-session")
            localStorage.setItem("punch-out-session", punchOutSession)
            const finalRoute = location.pathname
            if(punchOutUser) {
                let requestData = {
                    userId: punchOutUser
                }
                await loginRequest(requestData, finalRoute, false)
            } else {
                return history.push(finalRoute);
            }
        }
    }, []);

    if (isPublic) {
        return children;
    } else if(!isPublic && enableLandingPage && !user){
        return user?.id && accessToken ? children : window.location.replace("/login")
    } else {
        if (user) {
            if(!isPublic && enableLandingPage && location.pathname == "/") {
                window.location.replace(landingPageRedirect)
            } else {
                return children 
            }
        } else {
            if(location.pathname === "/cb") {
                return children
            }
            window.location.replace("/login")
        }        
    }
}

export default Authentication
