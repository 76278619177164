export const firstLetterUpper = (str) => {
    if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
}

export const productImageUrl = (imgUrl) => {
  let newUrl = replaceOrigin(imgUrl);
  if(newUrl) {
    if(typeof newUrl === 'string') {
      newUrl = newUrl.replace('cdnl.sanmar.com','cdnm.sanmar.com');
    } else if(typeof newUrl === 'object' && newUrl instanceof Array){
      newUrl.forEach(url => {
        url = url.replace('cdnl.sanmar.com','cdnm.sanmar.com');
      });
    }
  }
  if (typeof newUrl === 'string' && newUrl.includes('cdnm.sanmar.com')) {
    const operations = encodeURI(
      JSON.stringify([
        {
          operation: 'thumbnail',
          params: {
            width: 300,
            background: '255,255,255',
            type: 'jpeg',
            quality: 100,
          },
        },
        {
          operation: 'convert',
          params: {
            type: 'jpeg',
            quality: 100,
            width: 600,
          },
        },
      ]),
    )
    return (newUrl = `https://h4x0rt3hp74n37L4vv7.anterasaas.com/api/v1/imaginary/pipeline?url=${encodeURIComponent(
      newUrl,
    )}&operations=${operations}`)
  } else {
    return newUrl
  }
}
export const productImageExt = (imge) => {
  if(!!imge)
  {
    let ext = ''
    if(typeof imge !== 'string' &&  !!imge?.length && imge.length > 0)
    {
      ext = imge[0].split('.').pop().split(/\#|\?/)[0];
    }
    else if(typeof imge === 'string')
    {
      ext = imge.split('.').pop().split(/\#|\?/)[0];
    }
    if(ext === 'png')
      return true
    else 
      return false
  }
  else
    return false
}

export const replaceOrigin = (url) => {
  if(url) {
    if(typeof url === 'string') {
      url = url.replace('cdnl.sanmar.com','cdnm.sanmar.com');
    } else if(typeof url === 'object' && url instanceof Array){
      url.forEach(row => {
        row = row.replace('cdnl.sanmar.com','cdnm.sanmar.com');
      });
    }
  }
  return url;
}

function getRgbObj(color) {
    var rgb = [];
    if (color.indexOf('rgb') >= 0 || color.indexOf(',') >= 0) {
        rgb = color.split(',');
        rgb.forEach(function (d, i) {
            rgb[i] = d.replace(/\D/g, '');
        })
    } else {
        rgb = getRgbFromHex(color);
    }
    return rgb;
}
function getRgbFromHex(hex) {
    var rgb = [];

    //validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    for (let i = 0; i < 3; i++) {
        let c = parseInt(hex.substr(i * 2, 2), 16);
        rgb.push(c);
    }
    return rgb;
}
export const getBrightnessFromColor = (color) => {
    var brightness = 0;
    var rgb = getRgbObj(color);

    //brightness  =  sqrt( .299 R2 + .587 G2 + .114 B2 )
    brightness = Math.sqrt((.299 * (rgb[0] * rgb[0])) + (.587 * (rgb[1] * rgb[1])) + (.114 * (rgb[2] * rgb[2])));

    //convert to 0-100 scale
    brightness = Math.round(brightness / 255 * 100);

    return brightness;
}

export const getErrorMessageForQuickBookPopup = (product, RequiredDecorationForCheckOutPro) => {
  let blankTest = []
  let message = ''
    if (!!product.personalizationArray) {
      blankTest = !!product?.personalizationArray ? product.personalizationArray.filter(x => x.displayText === '') : []
    }

    if((product?.WebstoreProductPartArray && product?.WebstoreProductPartArray?.length > 1 ? (product?.WebstoreProductPartArray) : (product?.ProductPartArray?.ProductPart && product?.ProductPartArray?.ProductPart?.length > 1))) {
        message = 'Please select color and size'
        return {'message' : message, 'type' : false}
    } else if((RequiredDecorationForCheckOutPro === true  || product?.IsRequiredDecorationForCheckOutPro === true) && !((!!product?.selectedProductDecorations && product.selectedProductDecorations.length > 0))) {
      message = 'Please choose a decoration to add this product to your cart'
      return {'message' : message, 'type' : false}
    } else if (blankTest.length > 0) {
      message = 'You have added personalization to this product, but you haven’t added your text. Please add your text or delete Personalization to add this product to your cart.'
      return {'message' : message, 'type' : false}
    } else {
      return {'message' : '', 'type' : true}
    }
}

export  const productPath = (product) => {
    const name  = product.productName;
    const id = product.id;
    const str = `${id} ${name}`
    let formattedStr = str.replace(/[^a-zA-Z0-9]/g, '-');
    formattedStr = formattedStr.replace(/-+/g, '-');
    formattedStr = formattedStr.replace(/^-+|-+$/g, '');
    if (formattedStr.endsWith('-')) {
        formattedStr = formattedStr.slice(0, -1);
    }
    return formattedStr.toLowerCase();
}
export const findSizeList = (data) => {
  const dataRegex = /"data":\s*{\s*("[^"]*":\s*"[^"]*"\s*,?\s*)+}/;
  const valuesRegex = /"([^"]*)":\s*"[^"]*"/g;
  const match = data.match(dataRegex);
  const valuesString = match ? match[0] : '';
  if (!valuesString) {
    return [];
  }
  const values = valuesString.match(valuesRegex);
  return values ? values.map(entry => entry.match(/"([^"]+)":"[^"]*"/)[1]) : [];
};