
import * as productsAPIUtil from "../../../util/products/productsAPIUtil";

export const useProductArtworkState = (props) => {
    const {artworksFromState, getSettingStatus, setLoading} = props;

    const getDecoPrice = async (artWorkID) => {
        let data = null;
        try {
            let artwork_selected = artworksFromState?.filter(
                (artorkArray) => artorkArray.id === artWorkID,
            )[0]
            if (artwork_selected?.design) {
                let decodata = {
                    offset: '0',
                    limit: '500',
                    order: 'name',
                    orient: 'desc',
                    term: {
                        id: '',
                        name: '',
                        vendorId: artwork_selected?.design?.decoVendorId,
                        vendorName: artwork_selected?.design?.decoVendorName,
                        decoratorType: artwork_selected?.design?.designType,
                        decoratorSubType: '',
                        decorationIdentifier: '',
                        stitchesStart: '',
                        stitchesUpto: '',
                        setupCharge: '',
                        qunatityStart: '',
                        quantityUpto: '',
                        price: '',
                        salePrice: '',
                        setupChargeSalePrice: '',
                        decorationDetail: '',
                    },
                }
                setLoading(true)
                await productsAPIUtil.getDecorationPrice(decodata).then(result => {
                    if (result?.data?.data) {
                        data = result.data.data;
                    }
                }).catch(error => {
                    console.log("error", error)
                }).finally(() => setLoading(false))
            }
        } catch (error) {
            setLoading(false)
            console.log('error', error)
        }
        return data;
    }

    const filterArtworks = (artworks) => {
        const artworkMap = new Map();

        artworks.forEach(artwork => {
            const location = artwork.location;
            if (!artworkMap.has(location) || (artworkMap.get(location).isRequiredForStore === "0" && artwork.isRequiredForStore === "1")) {
                artworkMap.set(location, artwork);
            }
        });

        const filteredArtworks = Array.from(artworkMap.values());

        return filteredArtworks.sort((a, b) => {
            if (a.isRequiredForStore === '1' && b.isRequiredForStore !== '1') {
                return 1; // Move a to the end
            } else if (a.isRequiredForStore !== '1' && b.isRequiredForStore === '1') {
                return -1; // Move b to the end
            } else {
                return 0; // Keep original order
            }
        });
    }

    const selectDecoration = async (color, isHatsLayout = false)=>{
        let tempDecoArray = []
        if ( getSettingStatus('showDecoration') ) {
            for ( let val of artworksFromState ) {
                let artWorkId = val.id
                if ( val?.productToDecorationMapping[0]?.isRequiredForStore === '1' || (isHatsLayout && val?.productToDecorationMapping[0]?.autoAttach === '1' )) {
                    let locationId = val?.productToDecorationMapping[0]?.location
                    let locationName = val?.productToDecorationMapping[0]?.locationName
                    let checkLocation = val.productToDecorationMapping.filter(val=>val.location === locationId)
                    if ( checkLocation.length === 1 ) {
                        const decoPricedetails = await getDecoPrice(val.id);
                        let getVariationId = checkLocation[0].mapping.filter((val)=>val.color === color)
                        if ( getVariationId.length > 0 ) {
                            let getvarionIdFromMap = getVariationId[0].variationId
                            let decoData = {
                                "artworkID": artWorkId,
                                "location": locationName,
                                "locationId": locationId,
                                "variationUniqID": getvarionIdFromMap,
                                "isRequiredForStore": checkLocation?.[0]?.isRequiredForStore,
                                decoPricedetails: decoPricedetails,
                                "autoAttach": checkLocation?.[0]?.isRequiredForStore === "1" && isHatsLayout ? '0' : checkLocation?.[0]?.autoAttach,
                            }
                            tempDecoArray.push(decoData)
                        }
                    }
                }
            }
        }
        if (isHatsLayout === true) {
            tempDecoArray = filterArtworks(tempDecoArray)
        }
        return tempDecoArray
    }

    const photoZoom = (zmImg = '', singlelogoDetails = {}, key = '', decokey) => {
        const node = document.querySelectorAll(".single-image-sticky")[key]
        let zoomPictureNode = document.getElementsByClassName("lg-img-wrap")
        if (!!zoomPictureNode && !!zoomPictureNode?.[0] && zmImg !== '') {
            zoomPictureNode[0].classList.add(zmImg)
        }

        if (node) {
            const clone = node.cloneNode(true);
            zoomPictureNode[0].classList.add('lg-img-zoom')
            if (zoomPictureNode) {
                zoomPictureNode[0].appendChild(clone);
            }

            var fourChildNode = zoomPictureNode[0].querySelector('.containerimg');
            if (!!fourChildNode) {
                fourChildNode.style.maxWidth = 'unset'
                fourChildNode.style.width = 'unset'
                fourChildNode.style.margin = 'unset'
            }
        }

        let getSingleImage = singlelogoDetails
        let getOuterBOx = document.querySelector('.lg-img-wrap .react_lightgallery_item')
        if (!!getOuterBOx && !!getSingleImage) {
            let imgWidthZoom = getSingleImage?.iw
            let imgHeightZoom = getSingleImage?.ih
            let singleZoomX = getSingleImage?.x
            let singleZoomY = getSingleImage?.y
            let singleZoomW = getSingleImage?.w
            let singleZoomH = getSingleImage?.h

            if (getSingleImage?.isNewLogo == "1" && !!getSingleImage?.processedImage) {
                imgWidthZoom = getSingleImage?.processedImageWidth
                imgHeightZoom = getSingleImage?.processedImageHeight
                singleZoomX = getSingleImage?.newX
                singleZoomY = getSingleImage?.newY
                singleZoomW = getSingleImage?.newW
                singleZoomH = getSingleImage?.newH
            }
            let imgWidth = imgWidthZoom
            let imgHeight = imgHeightZoom

            if (imgWidth > 900 || imgHeight > 1100) {
                var ratio = Math.min(800 / imgWidth, 800 / imgHeight);

                imgWidth = imgWidth * ratio
                imgHeight = imgHeight * ratio
            }

            let positionSetLeft = (getOuterBOx.offsetWidth * singleZoomX) / imgWidth
            let positionSetTop = (getOuterBOx.offsetHeight * singleZoomY) / imgHeight
            let positionSetBoxWidth = (getOuterBOx?.offsetWidth * singleZoomW) / imgWidth
            let positionSetBoxHeight = (getOuterBOx?.offsetHeight * singleZoomH) / imgHeight

            let overlayImag = document.querySelectorAll(`.lg-img-wrap .decokey${decokey}`)
            const css = '.overlayimg:hover{  transform: scale(1.5); }';
            const style = document.createElement('style');
            if (style.sheet) {
                style.sheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }
            for (let i = 0; i < overlayImag.length; i++) {
                overlayImag[i].style.left = positionSetLeft + "px"
                overlayImag[i].style.top = positionSetTop + "px"
                overlayImag[i].style.width = positionSetBoxWidth + "px"
                overlayImag[i].style.height = positionSetBoxHeight + "px"
                overlayImag[i].style.transition = 'transform 0.3s';
                overlayImag[i].appendChild(style)
            }

            let overlayImag2 = document.querySelectorAll(`.lg-img-wrap .decokey${decokey} .img-fluid`)
            for (let i = 0; i < overlayImag2.length; i++) {
                overlayImag2[i].style.display = 'block'
                overlayImag2[i].style.maxWidth = positionSetBoxWidth + 'px'
                overlayImag2[i].style.maxHeight = positionSetBoxHeight + 'px'
                overlayImag2[i].style.width = '100%'
                overlayImag2[i].style.height = '100%'
                overlayImag2[i].style.objectFit = 'contain'
            }
        }
        let getOuterBOxCheck2 = document.querySelector('.lg-img-wrap .lightGal')
        if (!getOuterBOxCheck2) {
            return
        }
        let zoomInBtn = document.getElementById('lg-zoom-in');
        let zoomOutBtn = document.getElementById('lg-zoom-out');

        let scaleItem = 1

        zoomInBtn.addEventListener('click', function (event) {
            getOuterBOxCheck2.style.transform = `scale(${scaleItem + 0.5}) translateX(-50%)`
            scaleItem = scaleItem + 0.5
        });
        zoomOutBtn.addEventListener('click', function (event) {
            getOuterBOxCheck2.style.transform = `scale(${scaleItem - 0.5}) translateX(-50%)`
            scaleItem = scaleItem - 0.5
        })
    }

    return {
        getDecoPrice,
        selectDecoration,
        photoZoom
    }
}
