import { AuthContext } from "../../common/Auth"
import { useContext } from "react";
export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";
export const UPDATE_TO_CART = "UPDATE_TO_CART";
export const ADD_ROWS_TO_CART = "ADD_ROWS_TO_CART";
export const DELETE_DECO_CART = "DELETE_DECO_CART";
export const EDIT_QTY_CART = "EDIT_QTY_CART";
export const EDIT_SIZE_CART = "EDIT_SIZE_CART";
export const DELETE_SIZE_FROM_CART = "DELETE_SIZE_FROM_CART";
export const UPDATE_TO_CART_COMMENTS = "UPDATE_TO_CART_COMMENTS";
export const EDIT_COLOR_CART = "EDIT_COLOR_CART";
export const CART_DATA="CART_DATA";


//add to cart
export const updateCartItem = (
  item,
  ipV4,
) => {
  return dispatch => {
    dispatch({
      type: UPDATE_TO_CART,
      payload: item,
      publicIP: ipV4,
    });
  };
};

export const updateCartItemComments = (
  item,
  ipV4,
) => {
  return dispatch => {
    dispatch({
      type: UPDATE_TO_CART_COMMENTS,
      payload: item,
      ipV4: ipV4,
    });
  };
};

export const addItemsRowsTocart = (item, rows, addToast, isPriceBreak, ipV4) => {
  if (addToast) {
    addToast('Added To Cart', {appearance: 'success', autoDismiss: true})
  }
  return (dispatch) => {
    dispatch({
      type: ADD_ROWS_TO_CART,
      payload: {product: item, rowsData: rows},
      isPriceBreak: isPriceBreak,
      ipV4: ipV4,
    })
  }
}
const getRowArray = (
  item,
  addToast,
  quantityCount,
  selectedProductColor,
  selectedProductSize,
  selectedProductDecorations,
  personalizationArray,
  personalizationCost,
  userGroupDiscount,
) => {
  let temprowData = {
    id: 1,
    size: selectedProductSize
      ? selectedProductSize
      : item.selectedProductSize
      ? item.selectedProductSize
      : getDefaultSize(item),
    quantity: quantityCount,
    image: '',
    color: selectedProductColor
      ? selectedProductColor
      : item.selectedProductColor
      ? item.selectedProductColor
      : getDefaultColor(item),
    product: { sku: 0 },
    decorations: selectedProductDecorations ? selectedProductDecorations : [],
    personalization: personalizationArray ? personalizationArray : null,
	personalizationCost:personalizationCost?personalizationCost:{},
    price: 0,
    totalPrice: 0,
    cost: 0,
    totalCost: 0,
    setupCharges: item.ChargeArray.Charge,
    weight: item.weight,
    shipFrom: {
      countryFrom: item.shipFromCountry,
      zipCodeFrom: item.shipFromPostalCode,
    },
  }
  let flag = true
  item &&
    item.MediaContent &&
    item.MediaContent.map((mediaArray) => {
      if (mediaArray.color == temprowData.color && flag) {
        temprowData.image = mediaArray.url
        flag = false
      }
    })
  if (temprowData.image == '') {
    temprowData.image =
      item &&
      item.MediaContent &&
      item.MediaContent[0] &&
      item.MediaContent[0].url
  }
  let groups = []

  let priceTable = []

  // validation
  if (item?.WebstoreProductPartArray?.length) {
    item.WebstoreProductPartArray.forEach(part => {
      let priceOption = part
      const size = part.ApparelSize.labelSize
      if (!groups[size]) {
        groups[size] = []
      }
      groups[size].push(priceOption)
    })
  } else if (item?.ProductPartArray?.ProductPart?.length) {
    item.ProductPartArray.ProductPart.forEach(part => {
      let priceOption = part
      const size = part.ApparelSize.labelSize
      if (!groups[size]) {
        groups[size] = []
      }
      groups[size].push(priceOption)
    })
  }

  for (let size in groups) {
    priceTable.push({ size: size, priceOptions: groups[size] })
  }
  priceTable.map((priceTableRow) => {
    if (priceTableRow.size === temprowData.size) {
      let priceOptionsArray = priceTableRow.priceOptions
      priceOptionsArray.map((priceOption) => {
        if (priceOption.ColorArray.Color.colorName == temprowData.color) {
          temprowData.product.sku = priceOption.sku
          if (priceOption.partPrice.PartPriceArray.PartPrice.length == 1) {
            temprowData.price =
              priceOption.partPrice.PartPriceArray.PartPrice[0].salePrice
            temprowData.cost =
              priceOption.partPrice.PartPriceArray.PartPrice[0].price
          } else {
            priceOption.partPrice.PartPriceArray.PartPrice.map((p, pKey) => {
              if (Number(p.minQuantity) <= Number(temprowData.quantity)) {
                temprowData.price = p.salePrice
                temprowData.cost = p.price
              } else if (pKey == 0) {
                temprowData.price = p.salePrice
                temprowData.cost = p.price
              }
            })
          }

          let showSell = false
          let showSellPrice = false
          let offerPrice = 0
          let discountType = ''

          let todayDate = new Date().toISOString()

          if (todayDate >= item?.startDate && todayDate <= item?.endDate) {
            if (item?.makeProductSale) {
              showSell = true
            }
          } else if (item?.startDate == '' || item?.startDate == undefined) {
            if (item?.makeProductSale) {
              showSell = true
            }
          }

          if (
            item?.showPriceBreak &&
            todayDate >= item?.startDate &&
            todayDate <= item?.endDate
          ) {
            showSellPrice = true
          }
          if (item?.sellingPrice) {
            offerPrice = item.sellingPrice
          }
          if (item?.discountType == '0' || item?.discountType == '1') {
            discountType = item?.discountType
          }

          let finalProductDiscPrice = 0

          if (Number(userGroupDiscount > 0)) {
            temprowData.price =
              Number(temprowData.price) -
              (Number(temprowData.price) * Number(userGroupDiscount)) / 100
            temprowData.price = Number(temprowData.price).toFixed(2)
          }

          if (discountType == '1' && showSell) {
            if (offerPrice > 0) {
              temprowData.price = Number(temprowData.price) - Number(offerPrice)
            }
          } else if (discountType == '0' && showSell) {
            temprowData.price =
              Number(temprowData.price) -
              (Number(temprowData.price) * Number(offerPrice)) / 100
          } else {
          }
          temprowData.totalPrice = temprowData.price * temprowData.quantity
          temprowData.totalCost = temprowData.cost * temprowData.quantity
        }
      })
    }
  })
  return temprowData
}
export const addToCart = (
  item,
  addToast,
  quantityCount,
  selectedProductColor,
  selectedProductSize,
  selectedProductDecorations,
  personalizationArray,
  personalizationCost,
  userGroupDiscount,
  isPriceBreak=false,
  needValidation=true,
  RequiredDecorationForCheckOutPro=false,
  ipV4,
) => {
  return (dispatch) => {
    let blnktest = []
    if (!!personalizationArray || !!item.personalizationArray) {
      blnktest = !!personalizationArray ? personalizationArray.filter(x => x.displayText === '') : !!item?.personalizationArray ? item.personalizationArray.filter(x => x.displayText === '') : []
    }
    if (needValidation && (item?.WebstoreProductPartArray && item?.WebstoreProductPartArray?.length > 1 ?
      (item?.WebstoreProductPartArray && (!selectedProductSize || !selectedProductColor)) :
      (item?.ProductPartArray?.ProductPart && item?.ProductPartArray?.ProductPart?.length > 1)
      && (!selectedProductSize || !selectedProductColor))) {
      if (addToast) {
        addToast('Please select color and size', { appearance: 'error', autoDismiss: true })
      }
    }
    else if((RequiredDecorationForCheckOutPro === true  || item?.IsRequiredDecorationForCheckOutPro === true) && !((!!selectedProductDecorations && selectedProductDecorations.length > 0) || (!!item?.selectedProductDecorations && item.selectedProductDecorations.length > 0)))
    {
      if (addToast) {
        addToast('Please choose a decoration to add this product to your cart', { appearance: 'error', autoDismiss: true })
        return;
      }
    }
    else if (blnktest.length > 0) {
      if(addToast) {
        addToast('You have added personalization to this product, but you haven’t added your text. Please add your text or delete Personalization to add this product to your cart.', { appearance: 'error', autoDismiss: true })
        return;
      }
    } else {
      if (addToast) {
        addToast('Added To Cart', { appearance: 'success', autoDismiss: true })
      }
      dispatch({
        type: ADD_ROWS_TO_CART,
        payload: {
          ipV4: ipV4,
          product: item,
          rowsData: [
            [
              getRowArray(
                item,
                addToast,
                quantityCount ? quantityCount : 1,
                selectedProductColor
                  ? selectedProductColor
                  : item.selectedProductColor
                    ? item.selectedProductColor
                    : getDefaultColor(item),
                selectedProductSize
                  ? selectedProductSize
                  : item.selectedProductSize
                    ? item.selectedProductSize
                    : getDefaultSize(item),
                selectedProductDecorations
                  ? selectedProductDecorations
                  : item.selectedProductDecorations
                    ? item.selectedProductDecorations
                    : null,
				  personalizationArray
					  ? personalizationArray
					  : item.personalizationArray
						  ? item.personalizationArray
						  : null,
				  personalizationCost
					  ? personalizationCost
					  : [],
                userGroupDiscount,
              ),
            ],
          ],
        },
        isPriceBreak: isPriceBreak
      })
    }
  }
}
export const getDefaultColor = (product) => {
  let colors_final = [];
  let colors = [];
  product.ProductPartArray && product.ProductPartArray.ProductPart && product.ProductPartArray.ProductPart.forEach(part => {
    const color = part.ColorArray.Color.colorName;
    if (!colors[color]) {
      colors[color] = [];
    }
  });
  for (let color in colors) {
    colors_final.push(color);
  }
  const numericSort = (a, b) => {
		const aNumeric = parseInt(a);
		const bNumeric = parseInt(b);
		return aNumeric - bNumeric;
	};
	colors_final = colors_final.sort(numericSort);
  if (colors_final && colors_final.length > 0) {
    return colors_final[0]
  }
  return null
}
export const getDefaultSize = (product) => {
  let priceTable = [];
  let groups = [];
  product.ProductPartArray && product.ProductPartArray.ProductPart && product.ProductPartArray.ProductPart.forEach(part => {
    let priceOption = (part);
    const size = part.ApparelSize.labelSize;
    if (!groups[size]) {
      groups[size] = [];
    }
    groups[size].push(priceOption);
  });
  for (let size in groups) {
    priceTable.push({ size: size, priceOptions: groups[size] });
  }
  if (priceTable && priceTable.length > 0) {
    return priceTable[0].size
  }
  return null
}
//decrease from cart
export const decreaseQuantity = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Item Decremented From Cart", {
        appearance: "warning",
        autoDismiss: true
      });
    }
    dispatch({ type: DECREASE_QUANTITY, payload: item });
  };
};
//delete from cart
export const deleteFromCart = (item, addToast, isPriceBreak = false, mainIndex, rowIndex, ipV4) => {
  return dispatch => {
    if (addToast) {
      addToast("Removed From Cart", {appearance: "error", autoDismiss: true});
    }
    dispatch({type: DELETE_FROM_CART, payload: item, isPriceBreak: isPriceBreak, mainIndex, rowIndex, ipV4: ipV4});
  };
};
//delete all from cart
export const deleteAllFromCart = (addToast, ipV4) => {
  return dispatch => {
    if (addToast) {
      addToast("Removed All From Cart", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: DELETE_ALL_FROM_CART, ipV4: ipV4 });
  };
};
export const deleteDecoFromCart = (item, addToast, rowIndex, decoIndex, mainIndex, ipV4) => {
  return dispatch => {

    dispatch({ type: DELETE_DECO_CART, payload: item, rowIndex: rowIndex, decoIndex: decoIndex, mainIndex : mainIndex, ipV4 });
  };
};
export const editQtyCart = (item, addToast,  sizeindex, rowIndex, qty,isPriceBreak, mainRowIndex, ipV4) => {

  return dispatch => {
    dispatch({ type: EDIT_QTY_CART, payload: item, sizeindex: sizeindex, rowIndex: rowIndex, qty: qty,isPriceBreak:isPriceBreak, mainRowIndex, ipV4: ipV4});
  };
};

export const editColor = (item, addToast, rowIndex,  color, image, ipV4) => {
  return dispatch => {

    dispatch({ type: EDIT_COLOR_CART, payload: item, rowIndex: rowIndex, color: color, image:image, ipV4: ipV4 });
  };
};

export const editSizeCart = (item, addToast, rowIndex, sizeindex, size, mainRowIndex,isPriceBreak, color, ipV4) => {
  return dispatch => {
    dispatch({ type: EDIT_SIZE_CART, payload: item, sizeindex: sizeindex, rowIndex: rowIndex, size: size, mainRowIndex,isPriceBreak:isPriceBreak, color: color, ipV4: ipV4 });
  };
};
export const deleteSizeFromCart = (item, addToast, rowIndex, sizeindex, isPriceBreak, ipV4) => {
  return dispatch => {
    if (addToast) {
      addToast("Removed From Cart", { appearance: "error", autoDismiss: true });
    }
    dispatch({ type: DELETE_SIZE_FROM_CART, payload: item, sizeindex: sizeindex, rowIndex: rowIndex , isPriceBreak: isPriceBreak, ipV4});
  };
};
// get stock of cart item
export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock;
  } else {
    return (item.variation && item.variation
      .filter(single => single.color === color)[0]
      .size.filter(single => single.name === size)[0].stock);
  }
};

export const cartItemData = (data) => {
	return dispatch => {
	  dispatch({ 
		  type:CART_DATA,
			payload: data 
		});
	};
};
