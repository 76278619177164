import React, { Fragment, useState, useEffect, useContext } from "react";
import { connect, useSelector } from "react-redux";
import { AuthContext } from "../../../common/Auth"
import { replaceOrigin } from "../../../util/helper";
import { useToasts } from "react-toast-notifications";
const AddDecoration = ({ artworkList,
    setAddnewart,
    selectedProductArtwork,
    setSelectedProductArtwork,
    addDecoration,
    selectedProductLocation,
    artworkListById,
    selectedColor,
    selectedProductColor,
    locationName,
	searchTextArtwork,
    artworkSetting,
    storeArtwork,
    decorationLocations,
    ThemeFile,
	allowCustomDecoration,
    selectedDecorationsList,
    artworkDuplicatesAllowed,
	artworkDuplicatesAllowedPro,
    locationID,
    tempDecorationLocations,
    product,
    artworkLocations,
    allowOneDec,
    isAdded,
    setIsAdded,
    decorationListOrder,
    preventAutoAttach,
}) => {
    let getLocationName = decorationLocations && decorationLocations.filter(item => item.id === selectedProductLocation)

    const { storeDetails, userType, user } = useContext(AuthContext)
    const [productDesignId, setProductDesignId] = useState('')
    const [ArtworkDuplicatesAllowed, setArtworkDuplicatesAllowed] = useState(true)
    const [artwork_selected, setArtwork_selected] = useState(null)
    const [findProductArtvariationId, setFindProductArtvariationId] = useState('')
	const [tempArtworkListById,setArtworkListById]=useState(artworkListById)
    const [showArtwork, setShowArtwork] = useState(true)
    const {addToast} = useToasts();
    const [remainDecoLocation, setRemainDecoLocation] = useState(
        tempDecorationLocations,
        )
    
    const artworkGlobalIndicator = useSelector((state) => state.productData.artworkGlobalIndicator);
    
    useEffect(() => {
        if ((artworkSetting && artworkGlobalIndicator) && artworkListById.length === 0) {
            artworkListById = artworkListById?.concat(artworkList?.filter(al => artworkListById?.every(ali => ali?.id != al?.id)));
        } else {
            if ( !artworkGlobalIndicator && artworkListById.length === 0 ) {
                artworkListById = artworkListById?.concat(artworkList?.filter(al=>artworkListById?.every(ali=>ali?.id != al?.id)));
            }
        }
    }, [artworkList,artworkListById, searchTextArtwork, artworkGlobalIndicator])
  
    
    useEffect(()=>{
		let temp =artworkListById.filter((item) => {
			if (item.artworkSource == 'aether' && (!(!!user?.userId) || artworkSetting)) {
				if (allowCustomDecoration || artworkSetting) {
					return item
				}
			} else {
				return item
			}
		})
        temp = temp.filter((item =>item.singleUseOnly == '0'))
		let tempartworkListById=temp.filter((item => item.identity&&item.identity.toLowerCase().indexOf(searchTextArtwork.length>0?searchTextArtwork.toLowerCase():searchTextArtwork) !== -1 || item.designNo&&item.designNo.toLowerCase().indexOf(searchTextArtwork.length>0?searchTextArtwork.toLowerCase():searchTextArtwork) !== -1 || item.designTypeName&&item.designTypeName.toLowerCase().indexOf(searchTextArtwork.length>0?searchTextArtwork.toLowerCase():searchTextArtwork) !== -1));
        if(!!decorationListOrder && decorationListOrder.length > 0 && artworkListById.length > 0) {
            tempartworkListById = tempartworkListById.sort(function(a,b) {
              return decorationListOrder.indexOf(a.id) - decorationListOrder.indexOf(b.id);
            })
        }
	    setArtworkListById(tempartworkListById)
	},[searchTextArtwork,artworkListById])
    useEffect(() => {
        if (selectedProductArtwork) {
            let artwork = artworkListById.filter(val => val.id == selectedProductArtwork)
            if (artwork.length > 0) {
                setArtwork_selected(artwork[0])
            }

        } else if (artworkListById && artworkListById.length) {
            let locationCount = 0
            artworkListById.map((val, key) => {
                if (val?.productToDecorationMapping[0]?.location === selectedProductLocation && val?.productToDecorationMapping[0]?.isRequiredForStore == '1') {
                    val.productToDecorationMapping[0].mapping.map((mapVal, mapkey) => {
                        if (mapVal.color === selectedProductColor) {
                            locationCount = locationCount + 1
                            setFindProductArtvariationId(mapVal.variationId)
                            setProductDesignId(val?.id)
                        }
                    })

                    if (locationCount > 1) {
                        // setShowData('yes')
                        setFindProductArtvariationId('')
                        setProductDesignId('')
                    }
                } else {
                    setFindProductArtvariationId('')
                    setProductDesignId('')
                    //setShowData('yes')
                }
            })
        }
    }, [artworkListById, selectedProductArtwork])

    useEffect(() => {
        if (artworkListById && artworkListById.length) {
          let locationCount = 0
          let tempartWorkCount = 0
          artworkListById.map((val, key) => {
    
            if (val?.productToDecorationMapping[0]?.location === selectedProductLocation && val?.productToDecorationMapping[0]?.isRequiredForStore == '1') {
              tempartWorkCount = tempartWorkCount + 1
              val.productToDecorationMapping[0].mapping.map((mapVal, mapkey) => {
                if (mapVal.color === selectedProductColor) {
                  locationCount = locationCount + 1
                  setFindProductArtvariationId(mapVal.variationId)
                  setProductDesignId(val?.id)
                }
              })
    
              if (locationCount > 1) {
                setFindProductArtvariationId('')
                setProductDesignId('')
              }
            } else {
              setFindProductArtvariationId('')
              setProductDesignId('')
              val.productToDecorationMapping.map((val) => {
              if (val.location == selectedProductLocation && val.isRequiredForStore == '0') {
                  tempartWorkCount = tempartWorkCount + 1
                  setTimeout(() => {
                    if (tempartWorkCount == 1 && !!document.querySelectorAll(".artMain")?.length && document.querySelectorAll(".artMain")?.length === 1 && !isAdded && !preventAutoAttach) {
                      setIsAdded(true)
                      let id = 'artew_' + '1'
                      var link = document.getElementById(id);
                      link && link.click()
                    }
                  }, 2000)
                }
              })
            }
          })
        }
    }, [artworkListById, selectedProductLocation, selectedProductColor])
    
      const getStatusLocation = (artworkId) => {
        let status = true;
        if(product.restrictAfterFirstChoice) {
            let selectedArtworks = []
            let numberChoice = 0;
            selectedDecorationsList.forEach((row) => {
              if(row.artworkID && (getLocationName?.[0]?.locationName === undefined || getLocationName?.[0]?.locationName !== row.location)) {
                selectedArtworks.push(row);
              }
            })
            numberChoice = selectedArtworks.length;
            artworkLocations.length > 0 && artworkLocations.forEach(location => {
              if(location.artworkId === artworkId && location.numberChoice === numberChoice) {
                status = location.isEnable;
              }
            });
        }
        return status;
      }

    const handleArtworkselection = (artwork) => {

        setSelectedProductArtwork(artwork.id)

        setArtwork_selected(artwork)
        if (artwork.design.variation.length == 1) {
            addDecoration(artwork.design.variation[0], artwork.id, selectedProductLocation, selectedProductColor, artworkListById)
        } else {
            let checkLocation = artwork.productToDecorationMapping.filter(val => val.location == selectedProductLocation)
            if (checkLocation.length >= 1) {
                let getVariationId = checkLocation[checkLocation.length - 1].mapping.filter((val) => val.color == selectedProductColor)
                if (getVariationId.length > 0) {
                    let getvarionIdFromMap = getVariationId[0].variationId

                    let getdesignVationid = artwork.design.variation.filter(val => val.design_variation_unique_id == getvarionIdFromMap)
                    addDecoration(getdesignVationid[0], artwork.id, selectedProductLocation, selectedProductColor, artworkListById)
                }
            } else {
                let VarDat = !!artwork?.design?.variation && artwork.design.variation.filter(res => {                          
                  if (!!res?.design_variation_color && res?.design_variation_color.split(',').length > 0 && res?.design_variation_color.split(',').map(word => word.toLowerCase()).includes(selectedProductColor.toLowerCase()) && !!res?.itemImageThumbnail?.[0]) {
                    return res;
                  }
                })
                if(VarDat && VarDat.length > 0) {
                  addDecoration(VarDat[0], artwork.id, selectedProductLocation, selectedProductColor, artworkListById)
                } else if(!!artwork?.design?.variation?.[0]) {
                  addDecoration(artwork?.design?.variation?.[0], artwork.id, selectedProductLocation, selectedProductColor, artworkListById)
                }
            }
        }
    }

    useEffect(() => {
        if(artworkDuplicatesAllowedPro === true || artworkDuplicatesAllowedPro === false) {
            if(artworkDuplicatesAllowedPro === true)
                setArtworkDuplicatesAllowed(true)
            else
                setArtworkDuplicatesAllowed(false)
        } else if (artworkDuplicatesAllowed === true || artworkDuplicatesAllowed === false) {
            if(artworkDuplicatesAllowed === true)
                setArtworkDuplicatesAllowed(true)
            else
                setArtworkDuplicatesAllowed(false)
        }
    },[artworkDuplicatesAllowed, artworkDuplicatesAllowedPro])

    useEffect(() => {
        let newLocation = []
        tempDecorationLocations &&
          tempDecorationLocations.map((val, index) => {
            let artworkSelected = null
            let checkLocation =
            selectedDecorationsList && selectedDecorationsList.find((d) => d.locationId === val.id && d.variationUniqID && d.variationUniqID !== "" && d.variationUniqID !== undefined && allowOneDec !== "2")
            if (checkLocation === undefined || checkLocation === '') {
              newLocation.push(val)
            }
          })
        setRemainDecoLocation(newLocation)
      }, [selectedDecorationsList, tempDecorationLocations])

    return (
        <div className="col-md-12 mt-4">
            <div className="row pl-2 pr-4">
                <div className="col-md">
                    {remainDecoLocation.length > 0 &&
                        <b>Select Artwork:</b>
                    }
                    {productDesignId ? (
                        <div>
                            <div className="artworkList" style={{ display: "flex", overflowX: "auto" }}>
                                {tempArtworkListById.length > 0 && showArtwork && tempArtworkListById.map((artwork, index) => {
                                    let imgUrl = ""
                                    let getCustomArtworkByUser = true
                                    let displayArtwork = true
                                    // let imgUrl = artwork.thumbnail
                                    //     ? artwork.thumbnail
                                    //     : artwork.featureImage
                                    //         ? artwork.featureImage
                                    //         : ''
                                   if(ArtworkDuplicatesAllowed === false) {
                                      let isExist = selectedDecorationsList.filter(decoration => {
                                          return decoration.artworkID == artwork.id
                                      })
                                      if(selectedDecorationsList.length > 0) {
                                          if(isExist.length > 0) {
                                              displayArtwork = false
                                          }
                                      }
                                   }
                                  if (selectedDecorationsList?.length > 0) {
                                      let isExistLocations = selectedDecorationsList.filter(decoration => (
                                          (decoration?.location === artwork?.artworkLocation ||
                                              artwork.productToDecorationMapping.some(res => res.locationName === decoration.location)) &&
                                          decoration?.isRequiredForStore === '1'
                                      ));

                                      if (isExistLocations.length > 0) {
                                          displayArtwork = false;
                                      }
                                  }
                                 if(displayArtwork === true && getStatusLocation(artwork.id)) {
                                    let storeItem = storeArtwork.filter(item => item.designId == artwork?.designId)
                                    getCustomArtworkByUser = (storeItem?.[0]?.userId === user.userId)
                                    let checkLocation = artwork.productToDecorationMapping.filter(val => val.location == selectedProductLocation)

                                    if (checkLocation.length >= 1) {
                                        let getVariationId = checkLocation[checkLocation.length - 1].mapping.filter(val => val.color == selectedProductColor);
                                        if (getVariationId.length > 0) {
                                            let imageUrl = artwork.design.variation.filter((val) => val.design_variation_unique_id == getVariationId[0].variationId)
                                            if (imageUrl.length > 0) {
                                                if (storeItem && storeItem.length > 0) {
                                                  if(storeItem?.[0]?.isEnable && getCustomArtworkByUser){
                                                    imgUrl = imageUrl[0].itemImageThumbnail
                                                  } else if(storeItem?.[0]?.isEnable && (artworkSetting || allowCustomDecoration)) {
                                                    imgUrl = imageUrl[0].itemImageThumbnail
                                                  } else if(artwork.artworkSource !== 'aether' && !(!!storeItem?.[0]?.userId)) {
                                                    imgUrl = imageUrl[0].itemImageThumbnail
                                                  }
                                                } else {
                                                  imgUrl = imageUrl[0].itemImageThumbnail
                                                }
                                            }
                                        }
                                    } else {
                                         if((!(!!artwork?.artworkLocation) || artwork?.artworkLocation === locationName) && artwork?.design?.variation != "" && artwork.productToDecorationMapping == "") {
                                            let VarDat = artwork.design.variation.filter(res=> {
                                                if (!!res?.design_variation_color && res?.design_variation_color.split(',').length > 0 && res?.design_variation_color.split(',').map(word => word.toLowerCase().trim()).includes(selectedProductColor.toLowerCase()) && !!res?.itemImageThumbnail?.[0]) {
                                                    return res
                                                }  
                                            })
                                             if(VarDat && VarDat.length > 0) {
                                                 if (storeItem && storeItem.length > 0 && artworkGlobalIndicator) {
                                                     if(storeItem?.[0]?.isEnable && getCustomArtworkByUser){
                                                         imgUrl = VarDat?.[0]?.itemImageThumbnail?.[0]
                                                     } else if(storeItem?.[0]?.isEnable && (artworkSetting || allowCustomDecoration)) {
                                                         imgUrl = VarDat?.[0].itemImageThumbnail?.[0]
                                                     } else if(artwork.artworkSource !== 'aether' && !(!!storeItem?.[0]?.userId) && storeItem?.[0]?.isEnable) {
                                                         imgUrl = VarDat?.[0]?.itemImageThumbnail?.[0]
                                                     }
                                                 } else {
                                                     if(artwork?.statusId === "5"){
                                                         imgUrl = VarDat?.[0]?.itemImageThumbnail?.[0]
                                                     }
                                                 }
                                             } else {
                                                 if (storeItem && storeItem.length > 0 && artworkGlobalIndicator) {
                                                     if(storeItem?.[0]?.isEnable && getCustomArtworkByUser){
                                                         imgUrl = artwork?.design?.variation?.[0]?.itemImageThumbnail?.[0]
                                                     } else if(storeItem?.[0]?.isEnable && (artworkSetting || allowCustomDecoration)) {
                                                         imgUrl = artwork?.design?.variation?.[0].itemImageThumbnail?.[0]
                                                     } else if(artwork.artworkSource !== 'aether' && !(!!storeItem?.[0]?.userId) && storeItem?.[0]?.isEnable) {
                                                         imgUrl = artwork?.design?.variation?.[0]?.itemImageThumbnail?.[0]
                                                     }
                                                 } else {
                                                     if(artwork?.statusId === "5"){
                                                         imgUrl = artwork?.design?.variation?.[0]?.itemImageThumbnail?.[0]
                                                     }
                                                 }
                                             }
                                        }
                                    }
                                     let customeIndex = 0
                                     if (!!imgUrl && imgUrl.length > 0 && ThemeFile === "ProductDescriptionInfoSlider") {
                                        customeIndex = +1
                                        return <div className="artMain">
                                            {productDesignId === artwork?.designId && (
                                                <div className={artwork_selected && artwork_selected.id === artwork.designId ? "col-md-2 artworkbox artworkbox-selected decorows_selartwork" : "col-md-2 artworkbox decorows_selartwork"} onClick={() => {
                                                    handleArtworkselection(artwork)
                                                }} key={index}>
                                                    <img
                                                        id={'artew_' + customeIndex}
                                                        src={replaceOrigin(imgUrl)}
                                                        className="img-fluid mt-3"
                                                        alt="No Image"
                                                        style={{ maxHeight: "100px" }}
                                                    />
                                                    <label style={{ display: "Block" }}>{artwork.designTypeName}</label>
                                                    <label style={{ display: "Block" }}>{artwork.identity}</label>
                                                </div>)}
                                        </div>
                                    }
                                    else if(!!imgUrl && imgUrl.length > 0) {
                                        customeIndex = +1
                                        return <div className={ThemeFile === "ProductImageDescriptionSticky" ? "ArtDecSticky artMain" : "ArtDecOther artMain"}>
                                            {(productDesignId === artwork?.designId || productDesignId === artwork?.id) && (
                                                <div className={artwork_selected && artwork_selected.id === artwork.designId ? "col-md-2 artworkbox artworkbox-selected decorows_selartwork" : "col-md-2 artworkbox decorows_selartwork"} onClick={() => {
                                                    handleArtworkselection(artwork)
                                                }} key={index}>
                                                    <img
                                                        id={'artew_' + customeIndex}
                                                        src={replaceOrigin(imgUrl)}
                                                        className="img-fluid mt-3"
                                                        alt="No Image"
                                                        style={{ maxHeight: "100px" }}
                                                    />
                                                    <label style={{ display: "Block" }}>{artwork.designTypeName}</label>
                                                    <label style={{ display: "Block" }}>{artwork.identity}</label>
                                                </div>)}
                                        </div>
                                    }
                                 }
                                })}
                            </div>
                        </div>
                    ) : (
                        <div>
                        <div className="artworkList" style={{ display: "flex", overflowX: "auto" }}>
                                    {tempArtworkListById.length > 0 && showArtwork && tempArtworkListById.map((artwork, index) => {
                                        let imgUrl = ""
                                        let displayArtwork = true
                                        let getCustomArtwotkByUser = true
                                        // let imgUrl = artwork.thumbnail
                                        //     ? artwork.thumbnail
                                        //     : artwork.featureImage
                                        //         ? artwork.featureImage
                                        //         : ''
                                        let storeItem = storeArtwork.filter(item => item.designId == artwork?.designId)
                                        getCustomArtwotkByUser = (storeItem?.[0]?.userId === user.userId)
                                        let checkLocation = artwork && artwork.productToDecorationMapping && artwork.productToDecorationMapping.filter(val => val.location == selectedProductLocation)
                                      if(ArtworkDuplicatesAllowed === false) {
                                        let isExist = selectedDecorationsList.filter(decoration => {
                                            return decoration.artworkID == artwork.id
                                        })
                                        if(selectedDecorationsList.length > 0) {
                                            if(isExist.length > 0) {
                                                displayArtwork = false
                                            }
                                        }
                                      }
                                        if (selectedDecorationsList?.length > 0) {
                                            let isExistLocations = selectedDecorationsList.filter(decoration => (
                                                (decoration?.location === artwork?.artworkLocation ||
                                                    artwork.productToDecorationMapping.some(res => res.locationName === decoration.location)) &&
                                                decoration?.isRequiredForStore === '1'
                                            ));

                                            if (isExistLocations.length > 0) {
                                                displayArtwork = false;
                                            }
                                        }
                                      if(displayArtwork === true && getStatusLocation(artwork.id)) {
                                        if (checkLocation.length >= 1) {
                                            let getVariationId = checkLocation[checkLocation.length - 1].mapping.filter(val => val.color == selectedProductColor);

                                            if (getVariationId.length > 0) {
                                                let imageUrl = artwork.design.variation.filter((val) => val.design_variation_unique_id == getVariationId[0].variationId)
                                                if (imageUrl.length > 0) {
                                                    if (storeItem && storeItem.length > 0) {
                                                      if(storeItem?.[0]?.isEnable && getCustomArtwotkByUser){
                                                        imgUrl = imageUrl[0].itemImageThumbnail
                                                      } else if(storeItem?.[0]?.isEnable && (artworkSetting || allowCustomDecoration)) {
                                                        imgUrl = imageUrl[0].itemImageThumbnail
                                                      } else if(artwork.artworkSource !== 'aether' && !(!!storeItem?.[0]?.userId)) {
                                                        imgUrl = imageUrl[0].itemImageThumbnail
                                                      }
                                                    } else {
                                                      imgUrl = imageUrl[0].itemImageThumbnail
                                                    }
                                                }
                                            }
                                        } else {
                                            // if (getLocationName[0]?.id === selectedProductLocation && artwork?.design?.variation != "" && artwork.productToDecorationMapping == "") {
                                                if((!(!!artwork?.artworkLocation) || artwork?.artworkLocation === locationName) && artwork?.design?.variation != "" && artwork.productToDecorationMapping == "" ) {
                                                    let VarDat = artwork.design.variation.filter(res=> {
                                                        if (!!res?.design_variation_color && res?.design_variation_color.split(',').length > 0 && res?.design_variation_color.split(',').map(word => word.toLowerCase().trim()).includes(selectedProductColor.toLowerCase()) && !!res?.itemImageThumbnail?.[0]) {
                                                            return res
                                                        }  
                                                    })
                                                    if(VarDat && VarDat.length > 0) {
                                                        if (storeItem && storeItem.length > 0 && artworkGlobalIndicator) {
                                                            if(storeItem?.[0]?.isEnable && getCustomArtwotkByUser){
                                                                imgUrl = VarDat?.[0]?.itemImageThumbnail?.[0]
                                                            } else if(storeItem?.[0]?.isEnable && (artworkSetting || allowCustomDecoration)) {
                                                                imgUrl = VarDat?.[0].itemImageThumbnail?.[0]
                                                            } else if(artwork.artworkSource !== 'aether' && !(!!storeItem?.[0]?.userId) && storeItem?.[0]?.isEnable) {
                                                                imgUrl = VarDat?.[0]?.itemImageThumbnail?.[0]
                                                            }
                                                        } else {
                                                            if(artwork?.statusId === "5"){
                                                                imgUrl = VarDat?.[0]?.itemImageThumbnail?.[0]
                                                            }
                                                        }
                                                    } else {
                                                        if (storeItem && storeItem.length > 0 && artworkGlobalIndicator) {
                                                            if(storeItem?.[0]?.isEnable && getCustomArtwotkByUser){
                                                                imgUrl = artwork?.design?.variation?.[0]?.itemImageThumbnail?.[0]
                                                            } else if(storeItem?.[0]?.isEnable && (artworkSetting || allowCustomDecoration)) {
                                                                imgUrl = artwork?.design?.variation?.[0].itemImageThumbnail?.[0]
                                                            } else if(artwork.artworkSource !== 'aether' && !(!!storeItem?.[0]?.userId) && storeItem?.[0]?.isEnable) {
                                                                imgUrl = artwork?.design?.variation?.[0]?.itemImageThumbnail?.[0]
                                                            }
                                                        } else {
                                                            if(artwork?.statusId === "5"){
                                                                imgUrl = artwork?.design?.variation?.[0]?.itemImageThumbnail?.[0]
                                                            }
                                                        }
                                                    }
                                                }
                                        }
                                        {/*let getVariationId = artwork.productToDecorationMapping[0].mapping.filter(val => val.color == selectedProductColor);

                                        if (getVariationId.length > 0) {

                                            let imageUrl = artwork.design.variation.filter((val) => val.design_variation_unique_id == getVariationId[0].variationId)
                                            if (imageUrl.length > 0) {
                                                imgUrl = imageUrl[0].itemImageThumbnail
                                            }
                                        }*/}
                                        let customeIndex = 0
                                        let artmatchwithLocation = artwork.productToDecorationMapping.filter(val => val.location == selectedProductLocation)
                                        if (!!imgUrl && imgUrl.length > 0 && ThemeFile === 'ProductDescriptionInfoSlider') {
                                                customeIndex = +1
                                                return (<div className="artMain">
                                                <div
                                                  className={
                                                    artwork_selected && artwork_selected.id === artwork.designId
                                                      ? "col-md-2 artworkbox artworkbox-selected decorows_selartwork"
                                                      : "col-md-2 artworkbox decorows_selartwork"
                                                  }
                                                  onClick={() => {
                                                    handleArtworkselection(artwork);
                                                  }}
                                                  key={index}
                                                >
                                                  <img
                                                    id={'artew_' + customeIndex}
                                                    src={replaceOrigin(imgUrl)}
                                                    className="img-fluid mt-3"
                                                    alt="No Image"
                                                    style={{ maxHeight: "100px" }}
                                                  />
                                                  <label style={{ display: "Block" }}>{artwork.designTypeName}</label>
                                                  <label style={{ display: "Block" }}>{artwork.identity}</label>
                                                </div>
                                              </div>
                                              )
                                            }
                                       else if (!!imgUrl && imgUrl.length > 0) {
                                            customeIndex = +1
                                        // if (artmatchwithLocation.length > 0) {

                                            return <div className={ThemeFile === "ProductImageDescriptionSticky" ? "ArtDecSticky artMain" : "ArtDecOther artMain"} key={'artew_' + index}><div className={artwork_selected && artwork_selected.id === artwork.designId ? "col-md-2 artworkbox artworkbox-selected decorows_selartwork" : "col-md-2 artworkbox decorows_selartwork"} onClick={() => { handleArtworkselection(artwork) }} key={index}>
                                                <img
                                                    id={'artew_' + customeIndex}
                                                    src={replaceOrigin(imgUrl)}
                                                    className="img-fluid mt-3"
                                                    alt="No Image"
                                                    style={{ maxHeight: "100px" }}
                                                />
                                    <label style={{ display: "Block" }}>{artwork.designTypeName}</label>
                                    <label style={{ display: "Block" }}>{artwork.identity}</label>
                                </div></div>
                                        }
                               }
                            })}
                        </div>
                    </div>
                    )}
                </div>
            </div>
            {/*<div className="row">
                <div className="col-md">
                    {selectedProductArtwork && <div className="col-md-12 p-0">
                        <b>Select Variation:</b>
                        {artwork_selected &&
                            <div className="col-md-12" style={{ display: "flex", overflowX: "auto" }}>
                                {artwork_selected.design.variation && artwork_selected.design.variation.map((vari, index) => {
                                    return <div className={"col-md-2 artworkbox"} key={index} onClick={() => addDecoration(vari)}>
                                        <img
                                            src={vari.itemImageThumbnail ? vari.itemImageThumbnail : ""}
                                            className="img-fluid mt-2"
                                            alt="No Image"
                                        />
                                        <label style={{ display: "Block" }}>{vari.design_variation_color}</label>

                                        <label style={{ display: "Block" }}>{vari.design_variation_title}</label>
                                    </div>
                                })}
                            </div>
                        }
                    </div>}
                </div>
            </div>*/}
        </div>
    );
}
const mapDispatchToProps = dispatch => {
    return {
    };
};
const mapStateToProps = state => {
    return {
        artworkList: state.productData.artworkList,
        artworkListById: state.productData.artworkListById,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddDecoration);

