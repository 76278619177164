import PropTypes, { element } from "prop-types";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import {
  getDiscountPrice,
  getFirstPrice,
  showCurrencycodeSymbol,
  getminQuantity,
  getShowProductPrice
} from "../../helpers/product";
import Rating from "./sub-components/ProductRating";
import ProductModal from "./ProductModal";
import { AuthContext } from "../../common/Auth";
import * as productsAPIUtil from "../../util/products/productsAPIUtil";
import { productImageExt, productImageUrl, getErrorMessageForQuickBookPopup, productPath } from "../../util/helper";
import {
  getDefaultSize,
  getDefaultColor,
} from "../../redux/actions/cartActions";
import AddnewArtDialogue from "../../components/artwork/AddnewArtDialogue";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineSharpIcon from '@material-ui/icons/RemoveCircleOutlineSharp';
import {fetchArtworksById} from "../../redux/actions/productActions";
import { useThemeSettingsData } from "../../layouts/state/useThemeSettingsData";
import { useThemeSettings } from "../../layouts/state/useThemeSettings";
import ProductPriceUni from "./sub-components/ProductPriceUni";
import { useProductPriceState } from "./state/useProductPriceState";
import { useProductConfigState } from "../../pages/shop-product/state/useProductConfigState";
const ProductGridListSingle = ({
  product,
  currency,
  addToCart,
  addToWishlist,
  addToCompare,
  cartItem,
  wishlistItem,
  compareItem,
  sliderClassName,
  spaceBottomClass,
}) => {

  const saleStyles = {
    'color': product?.bannerFontColor ? product?.bannerFontColor : 'black',
    'backgroundColor': product?.bannerBgColor ? product?.bannerBgColor : '#FF7F00'
  }

  const [productStock, setProductStock] = useState("");
  const [productDstInventory, setProductDstInventory] = useState(null);
  const [productInventory, setProductInventory] = useState(null);
  const [loadFlag, setLoadFlag] = useState(0);
  const [showAddnewart, setAddnewart] = useState(false);
  const { storeDetails,isDirectToCart, userGroupDiscount, showAddToCartOnCat, usePointsAsCurrency, pointValue } = useContext(AuthContext);
  const [productId, setProductId] = useState(null)
  const history = useHistory();
  const [showHexColor, setShowHexColor] = useState(true)
  const disIndProductPrice = getShowProductPrice(product)
  const [RequiredDecorationForCheckOutPro, setRequiredDecorationForCheckOutPro] = useState(false)
  const [productLayout, setProductLayout] = useState('')
  const [productLayoutMain, setProductLayoutMain] = useState('')

  const { settingData, themeSettingsData, user } = useContext(AuthContext);
  const [modalShow, setModalShow] = useState(false);
  const [newArtData, setNewArtData] = useState(false);
  const [isMoq,setIsMoq]=useState(false);
  const [isSkuMoq,setIsSkuMoq]=useState(false);
  const [partPrice,setPartPrice]=useState([]);
  const [minQty,setMinQty]=useState("");
  const [isHover,seIsHover]=useState(false);
  const { addToast } = useToasts();
  const [decimalsToShow, setDecimalsToShow] = useState(2)
  const {
    isAllowoutofStockOrder,
    isStock,
    SettingsRequiredDecorationForCheckOut,
    showHexColorSet,
    isSecondaryImgHover,
    generalProductLayout,
    productSettings,
  } = useThemeSettingsData({themeSettingsData})

  const{
    priceDisplay,
    itempriceDisplay,
    showCompareToggle,
    showWishListToggle,
    showQuickView,
  } = useThemeSettings({settingData})


  const {
    productPrice,
    showSellPrice,
    productDiscount,
    discountedPrice,
    finalProductDiscPrice,
    discountType,
    finalProductPrice,
    finalDiscountedPrice,
    offerPrice
  } = useProductPriceState({product, disIndProductPrice, currency})


  const goToCart = async () => {
    /*if (disIndProductPrice !== false) {*/
      let IsAllowOutOfStockOrder = false;
      if(product?.AllowOutofStock === true || product?.AllowOutofStock === false) {
          IsAllowOutOfStockOrder = product?.AllowOutofStock
      } else {
        IsAllowOutOfStockOrder = isAllowoutofStockOrder
      }
      if (!IsAllowOutOfStockOrder) {
        await fetchStockData();
      } else {
        addToCart(product, addToast, isMoq && minQty, '', '', '', '', userGroupDiscount)
        localStorage.setItem("redirectUrl", window.location.pathname)
        if (isDirectToCart) {
          history.push("/cart");
        }
      }
    /*} else {
      addToast('Price not available', {
        appearance: "error",
        autoDismiss: true
      })
    }*/

  }

  const fetchStockData = async () => {
    let inventoryData = await (productsAPIUtil.getProductStock(product.id));
    if(inventoryData && inventoryData.status === 201) {
      setProductDstInventory(inventoryData.data.dtsInventory.data)
      setProductInventory(inventoryData.data.inventory)
    }
  };

  useEffect(() => {
    if(productInventory && productDstInventory) {
      if(checkStock() <= 0 ){
        addToast('No stock for selected article', {
          appearance: "warning",
          autoDismiss: true
        })
      } else {
        addToCart(product, addToast,isMoq && minQty, '', '', '', '', userGroupDiscount)
        localStorage.setItem("redirectUrl", window.location.pathname)
        if (isDirectToCart) {
          history.push("/cart");
        }
      }
      setProductDstInventory(null);
      setProductInventory(null)
    }
  }, [productDstInventory, productInventory])

  const getStock = (sizeDefault, sizcolorDefault) => {

    let inventoryArray = null
    let dstInventoryArray = null;

    if (productInventory) {
      //supplierStock
      //if (productInventory.productId === product.productId) {
        inventoryArray = productInventory?.PartInventoryArray?.PartInventory
      //}
    }
    if (productDstInventory) {
      dstInventoryArray = productDstInventory
    }

    let stock = 0
    if ((product?.poType == 'DropShip' || product?.poType == "Stock") && (isStock == "supplierStock" || isStock == "both")) {
      inventoryArray && inventoryArray.map(stockArray => {
        if (sizcolorDefault === stockArray.partColor && stockArray.labelSize === sizeDefault) {
          if (stockArray?.InventoryLocationArray?.InventoryLocation?.length) {
            stockArray.InventoryLocationArray.InventoryLocation.map(inventoryArray => {
              if (inventoryArray?.inventoryLocationQuantity?.Quantity?.value) {
                stock = Number(stock) + Number(inventoryArray.inventoryLocationQuantity.Quantity.value)
              }
            })
          }
        }
      })
    }


    let dstStock = 0;
    //distributorStock or both
    //distributor inventory
    if (product?.poType == "Stock" && (isStock == "distributorStock" || isStock == "both")) {
      dstInventoryArray && dstInventoryArray.map(dstStockArray => {
        if (dstStockArray.productId === product.productId) {
          if (sizcolorDefault === dstStockArray.color && dstStockArray.size === sizeDefault) {
            dstStock = Number(dstStockArray.quantity)
          }
        }
      })
    }
    return Number(stock) + Number(dstStock)
  }
  const checkStock = () => {
    let sizeDefault = getDefaultSize(product);
    let sizcolorDefault = getDefaultColor(product);
    return getStock(sizeDefault, sizcolorDefault)
  };

  useEffect(()=>{
    setPartPrice(product?.WebstoreProductPartArray[0]?.partPrice?.PartPriceArray?.PartPrice)
    setIsMoq(product?.isProductMoq);
    setIsSkuMoq(product?.isProductskuMoq)
    setProductId(product?.id)
  }, [product])

  const {
		getSettingStatus
	} = useProductConfigState({product})

  const getShowQuickView = () => {
    if(product.showQuickView !== undefined) {
      return product.showQuickView
    }
    return showQuickView
  }

  useEffect(() => {
    if (!!product?.productLayout) {
      setProductLayout(product.productLayout);
    } else {
      setProductLayout(generalProductLayout)
    }
  },[product, generalProductLayout])

  useEffect(() => {
    if(!!product?.decimalsToShow) {
      setDecimalsToShow(Number(product?.decimalsToShow))
    } else if(!!productSettings.decimalsToShow){
      setDecimalsToShow(Number(productSettings.decimalsToShow))
    } else {
      setDecimalsToShow(2)
    }
  },[product, productSettings])

  useEffect(() => {
    if(product?.showHexColor === true || product?.showHexColor === false) {
      setShowHexColor(product?.showHexColor)
    } else {
      setShowHexColor(showHexColorSet)
    }
  },[product, showHexColorSet])

  useEffect(() => {
    if(product?.RequiredDecorationForCheckOutPro === true || product?.RequiredDecorationForCheckOutPro === false)
    {
        if(product?.RequiredDecorationForCheckOutPro === true)
            setRequiredDecorationForCheckOutPro(true)
        else
            setRequiredDecorationForCheckOutPro(false)
    }
    else if (SettingsRequiredDecorationForCheckOut === true || SettingsRequiredDecorationForCheckOut === false)
    {
        if(SettingsRequiredDecorationForCheckOut === true)
            setRequiredDecorationForCheckOutPro(true)
        else
            setRequiredDecorationForCheckOutPro(false)
    }
  },[product, SettingsRequiredDecorationForCheckOut])

  useEffect(()=>{
    if ( RequiredDecorationForCheckOutPro === true || RequiredDecorationForCheckOutPro === false ) {
      product['IsRequiredDecorationForCheckOutPro'] = RequiredDecorationForCheckOutPro
    }
  }, [RequiredDecorationForCheckOutPro, product])

  useEffect(()=>{
    if ( partPrice && partPrice.length > 0 ) {
      let minQty = getminQuantity(partPrice)
      setMinQty(minQty)
    }
  }, [partPrice])
  let showPrice = false;
  if (user) {
    if (itempriceDisplay !== false) {
      showPrice = true;
    }
  } else {
    if (priceDisplay !== false) {
      showPrice = true;
    }
  }
  if(disIndProductPrice === false || disIndProductPrice === true){
    showPrice = disIndProductPrice
  }

  let primaryImage = "";
  let secondaryImage = "";
  if (product && product.MediaContent) {
    product.MediaContent.map((mediaArray) => {
      if (mediaArray.primary) {
        primaryImage = mediaArray.url;
      } else if (mediaArray.secondary) {
        secondaryImage = mediaArray.url;
      }
    });
    if (primaryImage == "" && product.MediaContent.length > 0) {
      primaryImage = product.MediaContent[0].url;
    }
    if (
      secondaryImage == "" &&
      product.MediaContent.length >= 1 &&
      product.MediaContent[1]
    ) {
      secondaryImage = product.MediaContent[1].url;
    }
  }

  const handleCancel = () => {
    setAddnewart(false);
  };

  const handleAddnewArt = (addedNewartdata) => {
    let artwork = addedNewartdata.newArtData;
	fetchArtworksById({
        account_id: storeDetails.accountId,
        product_id: product.id,
      })
    setAddnewart(false);
    if (artwork.id) {
      if (artwork.design && artwork.design.variation) {
        if (artwork.design.variation.length == 1) {
          let variation = artwork.design.variation[0];
          let tempDecoArray = {
            artworkID: artwork.id,
            variation: variation,
          };
          setNewArtData(tempDecoArray);
        }
      }
    }
  };
  function isValidColor(color) {
    const hexPattern = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/;
    const rgbPattern = /^(rgb|hsl)a?\(((25[0-5]|2[0-4]\d|[01]?\d\d?)\s*,\s*?){2}(25[0-5]|2[0-4]\d|[01]?\d\d?)\s*(,\s*?[0-1]?(\.\d{1,2})?)?\)$/i;
    return hexPattern.test(color) || rgbPattern.test(color);
  }
  const getPreferredHexColor = (colors) => {
    const notPreferredColors = ["FFFFFF"];
    const validColor = colors.find((color) => {
      const colorWithoutHash = color.replace("#", "");
      return (
        notPreferredColors.indexOf(colorWithoutHash) === -1 &&
        isValidColor(`#${colorWithoutHash}`)
      );
    });
    return validColor?.replace("#", "") || colors[0];
  };

  const renderColors = () => {
    const removedDuplicates = [];
    const removedDuplicates_final = [];
    const productMedia = product?.MediaContent || []
    for (let part of product?.ProductPartArray?.ProductPart) {
      const hex = part?.ColorArray?.Color?.hex?.replace('#', '')
      const colorName = part?.ColorArray?.Color?.colorName;
      if (!hex) continue
      const color = {
        hex: `#${hex}`,
        name: colorName ? colorName : ''
      }
      if (removedDuplicates.findIndex(x => x.hex === color.hex) === -1) {
        removedDuplicates.push(color)
      }
    }
    productMedia.forEach(media => {
      const index = removedDuplicates.findIndex(hex => media.hex.replace('#', '') === hex.hex.replace('#', '')  || hex.name === media.color)
      if(index < 0 && media.hex !== '') {
        removedDuplicates.push({
          hex: `#${media.hex.replace('#', '')}`,
          name: media.color ?? ''
        })
      }
    })
    removedDuplicates.length > 0 &&
      removedDuplicates.map((hex) => {
        let img = ''
        if (productMedia?.length > 0) {
          img = productMedia.find(
            (media) => media.hex.replace('#', '') === hex.hex.replace('#', '')  || hex.name === media.color,
          )
          img = img ? img.url : img
        }
        removedDuplicates_final.push({
          hex: hex.hex.split(',').length > 1 ? `#${getPreferredHexColor(hex.hex.split(','))}` : hex.hex,
          img: img,
          name: hex.name
        })
      })
        //removedDuplicates_final.push({
        //  hex: hex.split(',').length > 1 ? `#${hex.split(',')[1]}` : hex,
        //  img:   img,
        //})
    return removedDuplicates_final;
  };
  const setImgHover = (e) => {
    let imgURL = e.target.getAttribute("imgurl")
    let elemnt = window.document.getElementById(product.id)
    if (elemnt && imgURL && imgURL != "") {
      seIsHover(true)
      elemnt.style = "background: " + `url(${process.env.PUBLIC_URL + imgURL}) center no-repeat`
      elemnt.style.visibility = "visible"
      elemnt.style.opacity = "1"
    }
  }
  const hoverClick = (e) => {
    let imgURL = e.target.getAttribute("imgurl")
    if (imgURL && imgURL != "") {
      let flag = true;
      if (e.currentTarget.parentElement.className.includes("active")) {
        flag = false;
      }
      window.document.querySelectorAll(".hovercolorDiv").forEach((el) => {
        el.classList.remove('active');
      });
      if (flag)
        e.currentTarget.parentElement.className += " active";
      window.document.getElementById("img" + product.id).src = process.env.PUBLIC_URL + e.target.getAttribute("imgurl")
    }
  }
  const minusaction = (e) => {
    let elm = e.currentTarget.parentElement.querySelector(".colorContainer__show")
    elm.className += " colorContainer"
    elm.classList.remove("colorContainer__show")
    let parentElm = e.currentTarget.parentElement
    parentElm.className += " colorDivrow"
    parentElm.classList.remove("colorDivrow__show")
  }
  const plusaction = (e) => {
    let eml = e.currentTarget.parentElement
    eml.querySelector(".colorContainer").className += "__show"
    eml.className += "__show"
  }
  let colorList = renderColors()


  let mainCls = 'product-img'
  if(productImageExt(process.env.PUBLIC_URL + primaryImage))
  {
	  mainCls = 'product-img product-img-bg'
  }
  if(!!secondaryImage && productImageExt(process.env.PUBLIC_URL + secondaryImage))
  {
	  mainCls = 'product-img product-img-bg'
  }

  return (
    <Fragment>
      <div
        className={`col-xl-4 col-sm-4 ${sliderClassName ? sliderClassName : ""
          }`}
      >
        <div
          className={`product-wrap ${spaceBottomClass ? spaceBottomClass : ""} ${!isSecondaryImgHover ? 'secondaryImage-hover-false': ''}`}
        >
          <div className={mainCls}>
            <Link to={process.env.PUBLIC_URL + "/product/" + productPath(product)}>
              <figure>
                <img
                  id={"img" + product.id}
                  className={`img-fluid ${secondaryImage ? 'img-fluid-secondary' : ''} ${isHover ? 'd-none' : ''}`}
                  src={productImageUrl(process.env.PUBLIC_URL + primaryImage)}
                  alt=""
                  loading={"lazy"}
                />
              </figure>
              {secondaryImage ? (
                <div
                  id={product.id}
                  imgref={secondaryImage}
                  className={`${productImageExt(process.env.PUBLIC_URL + secondaryImage) ? 'product-img-bg' : ''} overly-hover`}
                  onMouseOut={(e) => {
                    window.document.getElementById(product.id).style = "background: " + `url(${process.env.PUBLIC_URL + window.document.getElementById(product.id).getAttribute("imgref")}) center no-repeat`
                  }}
                  style={{
                    background: `url(${process.env.PUBLIC_URL + secondaryImage
                      }) center no-repeat`,
                  }}
                >
                  {/* <img
									className="hover-img"
									src={process.env.PUBLIC_URL + secondaryImage}
									alt=""
								/> */}
                </div>
              ) : (
                ""
              )}
              {/* <div class="row justify-content-center">
                {renderColors().map((hex) => (
                  <div style={{ background: hex, width: 20, height: 20 }}></div>
                ))}
              </div> */}
            </Link>
            {showSellPrice && (
              <div className="product-ribbons" style={saleStyles}>
                <span style={saleStyles}>{product.bannerText ? product.bannerText : "Sale"}</span>
              </div>
            )}
            {product.discount || product.new ? (
              <div className="product-img-badges">
                {product.discount ? (
                  <span className="pink">-{product.discount}%</span>
                ) : (
                  ""
                )}
                {product.new ? <span className="blue">New</span> : ""}
              </div>
            ) : (
              ""
            )}

            <div className={`product-action ${!showAddToCartOnCat && 'w-50'}`}>
              {showWishListToggle && (
                <div className="pro-same-action pro-wishlist">
                  <button
                    className={wishlistItem !== undefined ? "active" : ""}
                    disabled={wishlistItem !== undefined}
                    title={
                      wishlistItem !== undefined
                        ? "Added to wishlist"
                        : "Add to wishlist"
                    }
                    onClick={() => addToWishlist(product, addToast)}
                  >
                    <i className="pe-7s-like" />
                  </button>
                </div>
              )}

              {showAddToCartOnCat && <div className={`pro-same-action ${showWishListToggle ? showAddToCartOnCat ? "pro-cart" : "" : "pro-cart-1"}`}>
                {showAddToCartOnCat &&
                  <>
                    {product.affiliateLink ? (
                      <a
                        href={product.affiliateLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {" "}
                        Buy now{" "}
                      </a>
                    ) : product.variation && product.variation.length >= 1 ? (
                      <Link to={`${process.env.PUBLIC_URL}/product/${product.id}`}>
                        Select Option
                      </Link>
                    ) :  (
                      <Link
                        to={{
                          state: { redirectUrl: window.location.pathname }
                        }}
                        onClick={(e) => {
                          if(productLayout === "product-printable") {
                           history.push("/product/"+product.id)
                          } else {
                          let resVal = getErrorMessageForQuickBookPopup(product, RequiredDecorationForCheckOutPro);
                          if(resVal.type === true) {
                            goToCart()
                          } else {
                            addToast(resVal.message, {
                              appearance: "error",
                              autoDismiss: true
                            })
                            setModalShow(true)
                            e.preventDefault();
                          }
                        }
                          }}
                          className={
                            cartItem !== undefined && cartItem.quantity > 0
                              ? "active"
                              : ""
                          }
                          disabled={cartItem !== undefined && cartItem.quantity > 0}
                          title={
                            cartItem !== undefined ? "Added to cart" : "Add to cart"
                          }
                        >
                          {" "}
                          <i className="pe-7s-cart"></i>{" "}
                          {cartItem !== undefined && cartItem.quantity > 0
                            ? "Added"
                            : "Add to cart"}
                        </Link>
                      )
                    }
                </>
                }
              </div>}
              {getShowQuickView() !== false &&
                <div className="pro-same-action pro-quickview 333">
                  <button onClick={() => {
                  if(productLayout === "product-printable") {
                     history.push("/product/"+product.id)
                  } else {
                     setModalShow(true)
                  }}
                  } title="Quick View">
                    <i className="pe-7s-look" />
                  </button>
                </div>
              }
              {showCompareToggle && (
                    <div className="pro-same-action pro-compare-action">
                      <button
                        className={compareItem !== undefined ? "active" : ""}
                        disabled={compareItem !== undefined}
                        title={
                          compareItem !== undefined
                            ? "Added to compare"
                            : "Add to compare"
                        }
                        onClick={() => addToCompare(product, addToast)}
                      >
                        <i className="pe-7s-shuffle" />
                      </button>
                    </div>
                  )}
            </div>
          </div>
          <div className="product-content text-center">
            {/* <div class="row justify-content-center">
              {renderColors().map((hex) => (
                <div style={{ background: hex, width:20, height:20 }}></div>
              ))}
            </div> */}
          {showHexColor === true && (
            <div className="d-flex col justify-content-center colorDivrow">
              <div className="d-flex flex-wrap justify-content-center colorContainer" style={{ width: colorList?.length > 18 ? "calc(100% - 16px)" : "100%" }} onMouseOut={(e) => {
                if (window.document.getElementById(product.id))
                  window.document.getElementById(product.id).style = "background: " + `url(${process.env.PUBLIC_URL + window.document.getElementById(product.id).getAttribute("imgref")}) center no-repeat`

              }}>
                {colorList?.length > 0 && colorList.map((hex,index) => (
                  <div className="hovercolorDiv" key ={index}>
                    <div className="hovercolorDivinner"
                         onMouseOver={(e) => {setImgHover(e)}}
                         onMouseLeave={(e) => { seIsHover(false) }}
                         onClick={(e) => {hoverClick(e)}}
                         imgurl={hex.img} style={{ background: hex.hex }}>
                    </div>
                  </div>
                ))}
              </div>
              {colorList?.length > 18 && <AddCircleOutlineIcon className="ml-12 plusi" onClick={(e) => {
                plusaction(e)
              }} />}
              {colorList?.length > 18 && <RemoveCircleOutlineSharpIcon className="ml-12 minusi" onClick={(e) => {
                minusaction(e)
              }} />}
            </div>
          )}
            <h3>
              <Link to=
                        {process.env.PUBLIC_URL + "/product/" + productPath(product)}>
                {product.productName}
              </Link>
            </h3>
            {product.rating && product.rating > 0 ? (
              <div className="product-rating">
                <Rating ratingValue={product.rating} />
              </div>
            ) : (
              ""
            )}

            {showPrice === false ? (
              ""
            ) : (
                <ProductPriceUni
                    productPrice={productPrice}
                    usePointsAsCurrency={usePointsAsCurrency}
                    pointValue={pointValue}
                    showSellPrice={showSellPrice}
                    finalProductDiscPrice={finalProductDiscPrice}
                    discountType={discountType}
                    finalDiscountedPrice={finalDiscountedPrice}
                    productDiscount={productDiscount}
                    finalProductPrice={finalProductPrice}
                    currency={currency}
                    mainClass = {"product-price"}
                    decimalsToShow={decimalsToShow}
                />
            )}
          </div>
        </div>
        <div className="shop-list-wrap mb-30">
          <div className="row">
            <div className="col-xl-4 col-md-5 col-sm-6 col-5">
              <div className={`product-list-image-wrap ${!isSecondaryImgHover ? 'secondaryImage-hover-false': ''}`} >
                <div className={mainCls}>
                  <Link to={process.env.PUBLIC_URL + "/product/" + productPath(product)}>
                    <img
                      className="default-img img-fluid"
                      src={productImageUrl(process.env.PUBLIC_URL + primaryImage)}
                      alt=""
                    />
                    {secondaryImage ? (
                      <img
                        className="hover-img img-fluid"
                        src={productImageUrl(process.env.PUBLIC_URL + secondaryImage)}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                  </Link>
                  {showSellPrice && (
                    <div className="product-ribbons" style={saleStyles}>
                      <span style={saleStyles}>{product.bannerText ? product.bannerText : "Sale"}</span>
                    </div>
                  )}
                  {product.discount > 0 || product.new ? (
                    <div className="product-img-badges">
                      {product.discount ? (
                        <span className="pink">-{product.discount}%</span>
                      ) : (
                        ""
                      )}
                      {product.new ? <span className="blue">New</span> : ""}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-md-7 col-sm-6 col-7">
              <div className="shop-list-content">
                <h3>
                  <Link to={process.env.PUBLIC_URL + "/product/" + productPath(product)}>
                    {product.productName}
                  </Link>
                </h3>
                {showPrice === false ? (
                  ""
                ) : (
                    <ProductPriceUni
                        productPrice={productPrice}
                        usePointsAsCurrency={usePointsAsCurrency}
                        pointValue={pointValue}
                        showSellPrice={showSellPrice}
                        finalProductDiscPrice={finalProductDiscPrice}
                        discountType={discountType}
                        finalDiscountedPrice={finalDiscountedPrice}
                        productDiscount={productDiscount}
                        finalProductPrice={finalProductPrice}
                        currency={currency}
                        mainClass = {"product-list-price"}
                        decimalsToShow={decimalsToShow}
                    />
                )}
                {product.rating && product.rating > 0 ? (
                  <div className="rating-review">
                    <div className="product-list-rating">
                      <Rating ratingValue={product.rating} />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {product.shortDescription ? (
                  <p dangerouslySetInnerHTML={{ __html: product.shortDescription }}></p>
                ) : (
                  ""
                )}

                <div className="shop-list-actions d-flex align-items-center">
                  {((showAddToCartOnCat &&  getSettingStatus('showAddToCart') !== false) || getSettingStatus('showAddToCart') !== false) &&
                  <div className="shop-list-btn btn-hover">
                    {product.affiliateLink ? (
                      <a
                        href={product.affiliateLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {" "}
                        Buy now{" "}
                      </a>
                    ) : product.variation && product.variation.length >= 1 ? (
                      <Link
                        to={`${process.env.PUBLIC_URL}/product/${product.id}`}
                      >
                        Select Option
                      </Link>
                    ) : (
                      <Link
                        to={{
                          state: { redirectUrl: window.location.pathname }
                        }}

                        onClick={(e) => {
                          if(productLayout === "product-printable") {
                           history.push("/product/"+product.id)
                          } else {
                          let resVal = getErrorMessageForQuickBookPopup(product, RequiredDecorationForCheckOutPro);
                          if(resVal.type === true) {
                            goToCart()
                          } else {
                            addToast(resVal.message, {
                              appearance: "error",
                              autoDismiss: true
                            })
                            setModalShow(true)
                            e.preventDefault();
                          }
                        }
                        }}
                        className={
                          cartItem !== undefined && cartItem.quantity > 0
                            ? "active"
                            : ""
                        }
                        disabled={
                          cartItem !== undefined && cartItem.quantity > 0
                        }
                        title={
                          cartItem !== undefined
                            ? "Added to cart"
                            : "Add to cart"
                        }
                      >
                        {" "}
                        <i className="pe-7s-cart"></i>{" "}
                        {cartItem !== undefined && cartItem.quantity > 0
                          ? "Added"
                          : "Add to cart"}
                      </Link>
                    )
                    }
                  </div>
                  }
                  {showWishListToggle && (
                    <div className="shop-list-wishlist ml-10">
                      <button
                        className={wishlistItem !== undefined ? "active" : ""}
                        disabled={wishlistItem !== undefined}
                        title={
                          wishlistItem !== undefined
                            ? "Added to wishlist"
                            : "Add to wishlist"
                        }
                        onClick={() => addToWishlist(product, addToast)}
                      >
                        <i className="pe-7s-like" />
                      </button>
                    </div>
                  )}
                  {showCompareToggle && (
                    <div className="shop-list-compare ml-10">
                      <button
                        className={compareItem !== undefined ? "active" : ""}
                        disabled={compareItem !== undefined}
                        title={
                          compareItem !== undefined
                            ? "Added to compare"
                            : "Add to compare"
                        }
                        onClick={() => addToCompare(product, addToast)}
                      >
                        <i className="pe-7s-shuffle" />
                      </button>
                    </div>
                  )}
                  {getShowQuickView() !== false &&
                    <div className="shop-list-compare ml-10">
                      <button
                        title="Quick View"
                        onClick={() => {
                          if(productLayout === "product-printable") {
                             history.push("/product/"+product.id)
                          } else {
                             setModalShow(true)
                          }}
                        }
                      >
                        <i className="pe-7s-look" />
                      </button>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* product modal */}
      {modalShow && (
        <ProductModal
          show={showAddnewart ? false : modalShow}
          onHide={() => setModalShow(false)}
          product={product}
          setAddnewart={setAddnewart}
          currency={currency}
          discountedprice={discountedPrice}
          finalproductprice={finalProductPrice}
          finaldiscountedprice={finalDiscountedPrice}
          cartitem={cartItem}
          wishlistitem={wishlistItem}
          compareitem={compareItem}
          addtocart={addToCart}
          newArtData={newArtData}
          setNewArtData={setNewArtData}
          addtowishlist={addToWishlist}
          addtocompare={addToCompare}
          addtoast={addToast}
        />
      )}
      {showAddnewart && (
        <AddnewArtDialogue
		productId={productId}
          open={showAddnewart}
          show={showAddnewart}
          title="Add New Artwork"
          onHide={() => handleCancel()}
          handleSubmit={handleAddnewArt}
          cancel={() => handleCancel()}
          catalogueItem={product}
        />
      )}
    </Fragment>
  );
};

ProductGridListSingle.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  cartItem: PropTypes.object,
  compareItem: PropTypes.object,
  currency: PropTypes.object,
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.object,
};

export default ProductGridListSingle;
