import PropTypes from "prop-types";
import React, { useState, useEffect, Fragment, useContext } from "react";
import { useThemeSettings } from "../../../layouts/state/useThemeSettings";
import { useThemeSettingsData } from "../../../layouts/state/useThemeSettingsData";
import { AuthContext } from "../../../common/Auth";
import { showCurrencycodeSymbol } from "../../../helpers/product";
import { useProductPriceToShow } from "../state/useProductPriceToShow";

const ProductPrices = ({ discountedprice, finaldiscountedprice,
                         finalproductprice, currency,showSellPrice,
                         offerPrice,discountType,userGroupDiscount,showprice,
                         disIndProductPrice, usePointsAsCurrency, product }) => {
  
  const { settingData, user, themeSettingsData } = useContext(AuthContext)
  const [decimalsToShow, setDecimalsToShow] = useState(2);
  const {
		priceDisplay,
		itempriceDisplay,
	} = useThemeSettings({ settingData })
  const {
		productSettings,
	} = useThemeSettingsData({ themeSettingsData });


  const {
    getPointsValue
  } = useProductPriceToShow({ productSettings, product })

  useEffect(() => {
    if(!!productSettings?.decimalsToShow) {
      setDecimalsToShow(Number(productSettings?.decimalsToShow));
    }
  }, [productSettings])
  let newPrice= finalproductprice;
 
  let showPrice = false;
  if (user) {
    if (itempriceDisplay !== false) {
      showPrice = true
    }
  } else {
    if (priceDisplay !== false) {
      showPrice = true
    }
  }

  if(disIndProductPrice === false || disIndProductPrice === true){
  	showPrice = disIndProductPrice
  }
  
  if(Number(userGroupDiscount)>0){

    finalproductprice=Number(finalproductprice) - (Number(finalproductprice) * Number(userGroupDiscount) / 100)
  }

  let finalProductDiscPrice = 0;

  if(discountType == "1"){
    if(offerPrice>0){
      finalProductDiscPrice = Number(finalproductprice)-Number(offerPrice)
    }
  }else if(discountType == "0"){
    finalProductDiscPrice = Number(finalproductprice) - (Number(finalproductprice) * Number(offerPrice) / 100)
  }

  return (
    <>
    {showPrice === false ? "" :
        <div className="product-details-price">
          {
            usePointsAsCurrency ? <span>{getPointsValue(Number(finalproductprice))}</span> : <>
                 <span className={ `${ (showSellPrice) ? 'breakable-price' : '' } mr-10` }>
                   { showCurrencycodeSymbol(currency) + Number(finalproductprice).toFixed(decimalsToShow) }
                 </span>{ "  " }
              <span>
                  { (showSellPrice) && (showCurrencycodeSymbol(currency) + Number(finalProductDiscPrice).toFixed(decimalsToShow)) }
                </span>
            </>
          }
      </div>
    }
    </>
  )
}

ProductPrices.propTypes = {
  discountedprice: PropTypes.number,
  finalproductprice: PropTypes.number,
  finaldiscountedprice: PropTypes.number,
  currency: PropTypes.object,
  product: PropTypes.object
};
export default ProductPrices;
