import React, { useState } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useShopCategoryState} from "./state/useShopCategoryState";

function ShopCategories(props) {
  const {isExpand} = props
    const {
        defaultExpand,
        selectedCategories,
        dataFilterStyle
    } = useShopCategoryState(props);
  const [hiddenIds, setHiddenIds] = useState([]);

  const hideShow = (id) => {
    const hiddenIdsArr = [...hiddenIds];
    if (hiddenIdsArr.indexOf(id) === -1) {
      hiddenIdsArr.push(id);
    } else {
      hiddenIdsArr.splice(hiddenIdsArr.indexOf(id), 1);
    }
    setHiddenIds(hiddenIdsArr);
  }

  const helper = (relationObject, key, level) => {
    const isParentChecked = !!selectedCategories.find(
      (obj) => relationObject.category._id === obj.category._id
    );
    return (
      <>
      <li key={key} className=" mb-0">
        <div className="" style={{ display: 'flex', alignItems: 'center' }}>
          <FormControlLabel
            style={{ width: "100%" }}
            control={
              <Checkbox
                style={{ marginLeft: `${level * 24}px` }}
                name={relationObject.category.categoryName}
                id={relationObject.category._id}
                onClick={(e) =>
                  props.onSelectCategory(relationObject, e.target.checked)
                }
                checked={isParentChecked}
              />
            }
            label={(relationObject.category.categoryName)}
          />

            {
              relationObject.children.length > 0 && relationObject.children.filter(x => x.category.isVisibleOnSidebar).length > 0 &&
              <span style={{display:"inline-block", width:"16px", height:"16px", cursor:"pointer"}} onClick={() => hideShow(relationObject.category._id)}>
               {!(hiddenIds.indexOf(relationObject.category._id) === -1) ? <i className="fa fa-minus" /> : <i className="fa fa-plus" />}
              </span>
            }
          </div>

          {
            !(hiddenIds.indexOf(relationObject.category._id) === -1) &&
            <ul>
              {relationObject.children.length > 0 && relationObject.children.filter(x => x.category.isVisibleOnSidebar).map((children, key) =>
                helper(children, key + 10, level + 1)
              )}
            </ul>
          }
        </li>
      </>
    );
  };

  return (
    <div className="sidebar-widget">
      {isExpand!==undefined&& <Accordion defaultExpanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h4 className="pro-sidebar-title">Categories </h4>
        </AccordionSummary>
        <AccordionDetails>
          <div className="sidebar-widget-list w-100">
            {props.categories ? (
              <ul className={`${!isExpand ? 'dataFilter' : ''}`} style={dataFilterStyle}>
                {props.categories.filter(x => x.category.isVisibleOnSidebar).map((relationObject, key) =>
                  helper(relationObject, key, 0)
                )}
              </ul>
            ) : (
              "No categories found"
            )}
          </div>
        </AccordionDetails>
      </Accordion>}
    </div>
  );
}

export default ShopCategories;
