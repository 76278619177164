import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from 'react'
import { changeLanguage, multilanguage } from 'redux-multilanguage'
import GoogleTranslateLanguageChanger from './GoogleTranslateLanguageChanger'
import { setCurrency } from '../../../redux/actions/currencyActions'
import { connect } from 'react-redux'
import { AuthContext } from '../../../common/Auth'

const LanguageCurrencyChanger = ({
  currency,
  setCurrency,
  dispatch,
  themeClass,
  borderStyle,
}) => {

  const [headerTopData, setHeaderTopData] = useState([])
  const [themeName, setThemeName] = useState('')
  const { themeSettingsData } = useContext(AuthContext)
  useEffect(() => {
    if (themeSettingsData && themeSettingsData.length > 0) {
      themeSettingsData.map((i) => {
        if (i.settingsName === "headerTopMenu") {
          setHeaderTopData(i.values[0]);
        }
        if (i.settingsName === "theme") {
          setThemeName(i.values[0]['themeName'] );
        }
      })
    }
  }, [themeSettingsData])


  const changeLanguageTrigger = e => {
    const languageCode = e.target.value;
    dispatch(changeLanguage(languageCode));
  };

  const setCurrencyTrigger = e => {
    const currencyName = e.target.value;
    setCurrency(currencyName);
  };

  return (
    <div style={{
      color: headerTopData.textColor ? headerTopData.textColor : '',
      background: headerTopData?.bgColor ?? '#fff',
    }}
         className={`header-top-wap ${borderStyle === 'fluid-border' ? 'border-bottom' : ''} ${themeClass ? themeClass : ''}`}>

      <div className="d-flex flex-row">
        {themeName === 'beach' &&
          <div className="translate-sidebar-left">

          </div>
        }
        <div className="language-currency-wrap ml-2">
          {headerTopData?.isEnabledLangDropdown &&
            <div className="same-language-currency language-style" style={{minWidth: '320px'}}>
              <GoogleTranslateLanguageChanger defaultLanguage={headerTopData.defaultLanguage} />
            </div>
          }
          {headerTopData?.isEnabledCurrencyDropdown &&
            <div className="same-language-currency use-style ">
          <span>
            {currency.currencyName}
          </span>
              {/* <div className="lang-car-dropdown">
          <ul>
            <li>
              <button value="USD" onClick={e => setCurrencyTrigger(e)}>
                USD
              </button>
            </li>
            <li>
              <button value="EUR" onClick={e => setCurrencyTrigger(e)}>
                EUR
              </button>
            </li>
            <li>
              <button value="GBP" onClick={e => setCurrencyTrigger(e)}>
                GBP
              </button>
            </li>
          </ul>
        </div> */}
            </div>}
          {
            headerTopData?.isEnabledfullWidthText &&
            <div className="same-language-currency header-full-width-text" style={{ padding: '6px 0' }}
                 dangerouslySetInnerHTML={{ __html: headerTopData?.fullWidthText }}></div>
          }
        </div>
      </div>

    </div>
  );
};

LanguageCurrencyChanger.propTypes = {
  setCurrency: PropTypes.func,
  currency: PropTypes.object,
  currentLanguageCode: PropTypes.string,
  headerTopData: PropTypes.any,
  isEnabledLeftText: PropTypes.bool,
  dispatch: PropTypes.func
};


const mapStateToProps = state => {
  return {
    currency: state.currencyData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrency: currencyName => {
      dispatch(setCurrency(currencyName));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(LanguageCurrencyChanger));
