import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { LightgalleryItem, LightgalleryProvider } from "react-lightgallery";
import { AuthContext } from "../../common/Auth";
import useElementSize from "../../util/custom-hooks/useElementSize";
import { productImageExt, replaceOrigin } from "../../util/helper";

const ProductImageGalleryStickyPopup = ({ product, selectedColor, setSelectedColor,
  selectedDecorations, selectedImage,
  artworkList, singleSelectedDecorations, artworkListById, setSelectedImage }) => {
  const [imgW, imgH] = useElementSize('.product-sticky-image__single .containerimg img')
  const [imageW, setImageW] = useState(imgW)
  const [imageH, setImageH] = useState(imgH)
  const [DefaultProductBackgroundColor, setDefaultProductBackgroundColor] = useState()
  const {
		settingData
	  } = useContext(AuthContext)

  useEffect(() => {
    if (settingData?.length > 0) {
      settingData.map((i) => {
      if (i.page === 'General') {
        if (!!i?.values?.DefaultProductBackgroundColor) {
          setDefaultProductBackgroundColor(i?.values?.DefaultProductBackgroundColor);
        }
      }
      })
    }
  }, [settingData])
  let tempdata = [];
  /*useEffect(() => {
    product.MediaContent.map((single, key) => {
      if (single.color === selectedColor && tempdata[`myrefRow${key}`] && selectedColor != "") {
        tempdata[`myrefRow${key}`].current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    })

  }, [selectedColor])*/
  
  useEffect(() => {
    if (singleSelectedDecorations) {
      let getIndex = product.LogoInfo.filter(
        (val) =>
          val.color === selectedColor &&
          val.location === singleSelectedDecorations.location
      );

      if (getIndex.length > 0) {
        setSelectedImage(getIndex[0]?.image);
      } else {
        let getIndexOfExtra_logo = [];
        product.LogoInfo.map((val) => {
          if (val.color === selectedColor) {
            if (
              val.extra_logo.filter(
                (e) => e.location === singleSelectedDecorations.location
              ).length > 0
            ) {
              getIndexOfExtra_logo.push(val);
            }
          }
        });
        if (getIndexOfExtra_logo.length) {
          setSelectedImage(getIndexOfExtra_logo[0]?.image);
        } else {
          let getImgColorIndex = product.LogoInfo.filter(
            (val) => val.color === selectedColor
          );

          if (getImgColorIndex.length > 0) {
            setSelectedImage(getImgColorIndex[0]?.image);
          }
        }
      }
    }
  }, [singleSelectedDecorations]);	 

  useEffect(() => {
    setTimeout(() => {
      let element = document.querySelectorAll('.modal-body .product-sticky-image__single .isactive img');
      setImageW(element[0]?.offsetWidth)
      setImageH(element[0]?.offsetHeight)
    }, 1000);
  }, [singleSelectedDecorations]);

  useEffect(() => {
    setTimeout(() => {
      let element = document.querySelectorAll('.modal-body .product-sticky-image__single .isactive img');
      setImageW(element[0]?.offsetWidth)
      setImageH(element[0]?.offsetHeight)
    }, 500);
  },[imgW, imgH]);

  useEffect(() => {
		let key = 0
    if (selectedImage) {
      let temp = product?.WebstoreProductPartArray.filter((val) => val.image===selectedImage && val?.isSwapImage,)
      if (temp.length > 0) {
        if (product?.WebstoreProductPartArray) {
          product.WebstoreProductPartArray.filter((ele, ind) => ind ===  product?.WebstoreProductPartArray.findIndex( elem => elem.color === ele.color)).map((single, index) => {
            if (single.image === selectedImage) {
              return key = index
              
            }
          })  
        }
      } else {
        if (product.MediaContent) {
          product.MediaContent.map((single, index) => {
            if (single.url === selectedImage) {
            key = index
            }
          })
          }
      }
      tempdata[`myrefRow${key}`].current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [selectedImage])

  const getReducePer = (width, param) => {
    let reducePer = 100;
    while (((Number(width) * reducePer) / 100) > param) {
      reducePer = reducePer - 1;
    }
    return reducePer;
  }
  const photoZoom = (zmImg='', singlelogoDetails = {}, key) => {
    let node = document.querySelectorAll(".modal-body .single-image");
    node = (!!node) && node[key]
		let zoomPictureNode = document.getElementsByClassName("lg-img-wrap")
		if(!!zoomPictureNode && !!zoomPictureNode?.[0] && !!DefaultProductBackgroundColor && zmImg !== '')
			zoomPictureNode[0].classList.add(zmImg)

      if (node) {
        const clone = node.cloneNode(true);
        zoomPictureNode[0].classList.add('lg-img-zoom')
        if (zoomPictureNode) {
          zoomPictureNode[0].appendChild(clone);
        }
        var fourChildNode = zoomPictureNode[0].querySelector('.containerimg');
        if(!!fourChildNode) {				
          fourChildNode.style.maxWidth = 'unset'
          fourChildNode.style.margin = 'unset'
        }
      }
  
      //get outerbox
      let getSingleImage = singlelogoDetails
      let getOuterBOx = document.querySelector('.lg-img-wrap .react_lightgallery_item')
      if(!!getOuterBOx)
      {
        let imgWidthZoom = getSingleImage?.iw
        let imgHeightZoom = getSingleImage?.ih
        let singleZoomX = getSingleImage?.x
        let singleZoomY = getSingleImage?.y
        let singleZoomW = getSingleImage?.w
        let singleZoomH = getSingleImage?.h

        if(getSingleImage?.isNewLogo == "1" && !!getSingleImage?.processedImage) {
          imgWidthZoom = getSingleImage?.processedImageWidth
          imgHeightZoom = getSingleImage?.processedImageHeight
          singleZoomX = getSingleImage?.newX
          singleZoomY = getSingleImage?.newY
          singleZoomW = getSingleImage?.newW
          singleZoomH = getSingleImage?.newH
        }
        let imgWidth = imgWidthZoom
        let imgHeight = imgHeightZoom
    
        if(imgWidth > 900 || imgHeight > 1100){
          var ratio = Math.min(800 / imgWidth, 800 / imgHeight);
      
          imgWidth = imgWidth * ratio
          imgHeight = imgHeight * ratio
        }
  
        // add left top postion to artwork
        let positionSetLeft = (getOuterBOx.offsetWidth * singleZoomX) / imgWidth
        let positionSetTop = (getOuterBOx.offsetHeight * singleZoomY) / imgHeight
        let positionSetBoxWidth = (getOuterBOx?.offsetWidth * singleZoomW) / imgWidth
        let positionSetBoxHeight = (getOuterBOx?.offsetHeight * singleZoomH) / imgHeight
    
        let overlayImag = document.querySelectorAll(".lg-img-wrap .overlayimg")
        const css = '.overlayimg:hover{  transform: scale(1.5); }';
        const style = document.createElement('style');
        if (style.sheet) {
          style.sheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }
        for (let i = 0; i < overlayImag.length; i++) {
          overlayImag[i].style.left = positionSetLeft + "px"
          overlayImag[i].style.top = positionSetTop + "px"
          overlayImag[i].style.width = positionSetBoxWidth + "px"
          overlayImag[i].style.height = positionSetBoxHeight + "px"
          overlayImag[i].style.transition = 'transform 0.3s';
          overlayImag[i].appendChild(style)
        }
    
        //add width height to artwork
    
        let overlayImag2 = document.querySelectorAll(".lg-img-wrap .overlayimg .img-fluid")
        for (let i = 0; i < overlayImag2.length; i++) {
          overlayImag2[i].style.maxWidth = positionSetBoxWidth + 'px'
          overlayImag2[i].style.maxHeight = positionSetBoxHeight + 'px'
          overlayImag2[i].style.width = '100%'
          overlayImag2[i].style.height = '100%'
          overlayImag2[i].style.objectFit = 'contain'
        }
      }
      let getOuterBOxCheck2 = document.querySelector('.lg-img-wrap .lightGal')
      if(!getOuterBOxCheck2) {
        return
      }
      let zoomInBtn = document.getElementById('lg-zoom-in');
      let zoomOutBtn = document.getElementById('lg-zoom-out');

      let scaleItem = 1

      zoomInBtn.addEventListener('click', function (event) {
        getOuterBOxCheck2.style.transform = `scale(${scaleItem + 0.5}) translateX(-50%)`
        scaleItem = scaleItem + 0.5
      });
      zoomOutBtn.addEventListener('click', function (event) {
        getOuterBOxCheck2.style.transform = `scale(${scaleItem - 0.5}) translateX(-50%)`
        scaleItem = scaleItem - 0.5
      });
	}
  return (
    <div className="product-large-image-wrapper product-large-image-wrapper--sticky" style={{ overflow: "scroll", maxHeight: "80vh" }}>
      {product.discount || product.new ? (
        <div className="product-img-badges">
          {product.discount ? (
            <span className="pink">-{product.discount}%</span>
          ) : (
            ""
          )}
          {product.new ? <span className="blue">New</span> : ""}
        </div>
      ) : (
        ""
      )}
      <div className="product-sticky-image mb--10">
        {product && product.MediaContent &&
          product.MediaContent.map((single, key) => {
            let singlelogoDetails = {}
            let findlogoinfo = '';
            if (singleSelectedDecorations != "" && singleSelectedDecorations != null && singleSelectedDecorations != "null" && singleSelectedDecorations != undefined) {
              const itemsOnTop = product?.LogoInfo.filter(item => item.color !== selectedColor);
              const sortedItems = product?.LogoInfo.filter(item => item.color === selectedColor).sort();
              product.LogoInfo = [...itemsOnTop, ...sortedItems]

              for (let i = 0; i < product?.LogoInfo.length; i++) {
                const logoInfoData = product?.LogoInfo[i]
                if (logoInfoData.color === selectedColor) {
                  if (logoInfoData.image === selectedImage && logoInfoData.location == singleSelectedDecorations.location) {
                    findlogoinfo = logoInfoData
                    break;
                  } else if (logoInfoData.location == singleSelectedDecorations.location) {
                    findlogoinfo = logoInfoData
                    break;
                  } else if (logoInfoData.image === selectedImage) {
                    let extra_logo = logoInfoData.extra_logo.filter(val => val.location == singleSelectedDecorations.location)

                    if (extra_logo.length > 0) {

                      findlogoinfo = extra_logo
                      findlogoinfo[0].image = logoInfoData.image
                      findlogoinfo[0].color = logoInfoData.color
                      findlogoinfo[0].ih = logoInfoData.ih
                      findlogoinfo[0].iw = logoInfoData.iw
                      findlogoinfo[0].location = extra_logo[0].location
                      findlogoinfo[0].h = extra_logo[0].h

                      findlogoinfo[0].w = extra_logo[0].w
                      findlogoinfo[0].x = extra_logo[0].x
                      findlogoinfo[0].y = extra_logo[0].y
                      if (logoInfoData?.isNewLogo == "1" && !!logoInfoData?.processedImage) {
                        findlogoinfo[0].processedImageWidth = logoInfoData?.processedImageWidth
                        findlogoinfo[0].processedImageHeight = logoInfoData?.processedImageHeight
                        findlogoinfo[0].isNewLogo = logoInfoData?.isNewLogo
                        findlogoinfo[0].processedImage = logoInfoData?.processedImage
                      }
                      break;
                    }
                  }
                }
              }
            }
            if (findlogoinfo && findlogoinfo.length > 0) {
              singlelogoDetails = findlogoinfo[0];
            } else {
              singlelogoDetails = findlogoinfo
            }
            /*if (imgWidth) {
              imgWidth = (imgWidth * reducePer) / 100;
              imgHeight = (imgHeight * reducePer) / 100;
            }*/

            let ref = `myrefRow${key}`
            tempdata[ref] = React.createRef()
            let isactive = false
            if(single?.url === selectedImage && selectedImage != "") {
              isactive = true
            }
            return (
              <div className="product-sticky-image__single mb-10" id = {key} key={key} ref={tempdata[ref]}>
                <div>
                <div class={`${productImageExt(single.url) ? 'product-img-bg' : ''} single-image lightGal`}>
                <div className={`containerimg ${isactive === true ? 'isactive' : ''}`} style={{maxWidth: "451px", margin: "0px auto"}}>
                  <LightgalleryProvider onAfterOpen={() => photoZoom(productImageExt(single.url) ? 'product-img-bg' : '', singlelogoDetails, key)}>
                    <LightgalleryItem
                      galleryClassName=" "
                      group="any"
                      src={replaceOrigin(single.url)}
                    ><img
                    onClick={() => setSelectedColor(single.color)}
                    src={process.env.PUBLIC_URL + single.url}
                    alt=""
                    className="img-fluid"
                  />
                    {selectedDecorations && selectedImage === single.url && selectedDecorations.map((li, key) => {
                      let artWork = null
                      if (li?.artworkID !== '' && li?.artworkID !== undefined) {
                        const idToSearch = singleSelectedDecorations ? singleSelectedDecorations.artworkID : li.artworkID
                        artWork = artworkListById?.find(
                          (artworkArray) => artworkArray.id === idToSearch,
                        )
                        if(!!!artWork) {
                          artWork = artworkList?.find(
                            (artworkArray) => artworkArray.id === idToSearch,
                          )
                        }
                      }
                      let rowVariation = null
                      if (artWork?.design?.variation?.length > 0) {
                        rowVariation = artWork.design.variation.filter(
                          (variationArray) =>
                            variationArray.design_variation_unique_id ==
                            li.variationUniqID,
                        )[0]
                      }
                      let imgWidth = singlelogoDetails && singlelogoDetails.iw;
                      let imgHeight = singlelogoDetails && singlelogoDetails.ih;
                      if(imgWidth > 900 || imgHeight > 1100){
                        var ratio = Math.min(800 / imgWidth, 800 / imgHeight);
                        imgWidth = imgWidth * ratio
                        imgHeight = imgHeight * ratio
                      }

                      let reducePer = getReducePer(imgHeight, imageH)
                      let to = ""
                      let lef = ""
                      let wid = ""
                      let hei = ""
                      if (li.location == singlelogoDetails.location && singleSelectedDecorations.location === li.location) {
                        to = singlelogoDetails.y;
                        lef = singlelogoDetails.x;
                        wid = singlelogoDetails.w;
                        hei = singlelogoDetails.h;
                      } else {
                        singlelogoDetails.extra_logo && singlelogoDetails.extra_logo.map(el => {
                          if (li.location == el.location && singleSelectedDecorations.location === li.location) {
                            to = el.y;
                            lef = el.x;
                            wid = el.w;
                            hei = el.h;
                          }
                        })
                      }
                      let positionSetLeft = (imageW * singlelogoDetails.x) / imgWidth
                      let positionSetTop = (imageH * singlelogoDetails.y) / imgHeight

                      //artwork box width/height set
                      let positionSetBoxWidth = (imageW * singlelogoDetails.w) / imgWidth
                      let positionSetBoxHeight = (imageH * singlelogoDetails.h) / imgHeight

                      to = ((Number(to) * reducePer) / 100).toString();
                      lef = ((Number(lef) * reducePer) / 100).toString();
                      wid = ((Number(wid) * reducePer) / 100).toString();
                      hei = ((Number(hei) * reducePer) / 100).toString();
                      return <>{rowVariation && rowVariation.itemImageThumbnail[0] && <div className="overlayimg" key={key}
                        style={{
                          top: positionSetTop + "px",
                          left: positionSetLeft + "px"
                        }}
                      ><img
                          className="img-fluid"
                          src={artWork && (artWork.design.variation[0] && (artWork.design.variation[0].itemImageThumbnail ? replaceOrigin(artWork.design.variation[0].itemImageThumbnail) : replaceOrigin(artWork.design.variation[0].itemImage)))}
                          alt="thumbnail"
                          width={wid + "px"}
                          height={hei + "px"}
                          style={{
                            maxWidth: positionSetBoxWidth + 'px',
                            maxHeight: positionSetBoxHeight + 'px',
                          }}
                        /></div>}</>
                    })}
                    </LightgalleryItem>
                  </LightgalleryProvider>
                  </div>
                </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

ProductImageGalleryStickyPopup.propTypes = {
  product: PropTypes.object
};

export default ProductImageGalleryStickyPopup;
