import React, { useContext, useEffect, useState } from "react";
import { numberWithCommas2, WithCommas } from "../../../helpers/product";
import { AuthContext } from "../../../common/Auth";
import { useProductPriceToShow } from "../../../components/product/state/useProductPriceToShow";

const PrintablePriceTable = (props) => {
    const {
        usePointsAsCurrency } = useContext(AuthContext)
    const {
        getPointsFromValue,
        getPointsValue
    } = useProductPriceToShow({})

    const priceTable = props.priceTable;
    const product = props.product;
    const isModel = !!props?.isModel;
    const currency = props.currency;
    const decimalsToShow = props.decimalsToShow;
    const doNotCalculateAtOrder = props.doNotCalculateAtOrder;
    const islayout = props?.islayout ?? 0;
    return (
        <div className={!!!isModel ? "ml-3" : ""} style={{ marginRight: islayout ? "15px" : '' }}>
            <div className={!!isModel ? "sizediv product-price-tbl mb-1" : "row sizediv product-price-tbl mb-1"} style={{ overflowX: 'auto', margin: 0 }}>
                {islayout ? <div className="col-lg sizeContainer">
                    <label className="inputQty"><b>Quantity</b></label>
                    <label className="bggray"><b>Price</b></label>
                </div>:''}
                {priceTable.map((row, keyP) => {
                    if(islayout) {
                        return <div className="col-lg sizeContainer" key={'p-t' + keyP}>
                            <label className="inputQty"> {row.minQuantity} </label>
                            <label className=" bggray" title="Price">
                            {/* {
                                usePointsAsCurrency ? getPointsValue(row.salePrice) : currency.currencySymbol + numberWithCommas2(row.salePrice,
                                    decimalsToShow )
                            } */}
                                {console.log('doNotCalculateAtOrder: ', doNotCalculateAtOrder)}
                            {
                                doNotCalculateAtOrder == "1" ? (
                                    <>
                                    {usePointsAsCurrency
                                        ? getPointsValue(row.salePrice * row.minQuantity)
                                        : currency.currencySymbol + numberWithCommas2(row.salePrice * row.minQuantity, decimalsToShow)}
                                    </>
                                ) : (
                                    <>
                                    {usePointsAsCurrency
                                        ? getPointsValue(row.salePrice)
                                        : currency.currencySymbol + numberWithCommas2(row.salePrice, decimalsToShow)}
                                    </>
                                )
                            }
                        </label>
                        </div>
                    } else {
                    return <div className="col-lg sizeContainer" key={'p-t' + keyP}>
                        <label className=" bggray" title="Price">
                            {/* {
                                usePointsAsCurrency ? getPointsValue(row.salePrice) : currency.currencySymbol + numberWithCommas2(row.salePrice,
                                    decimalsToShow )
                            } */}
                                {/* {console.log('doNotCalculateAtOrder: ', doNotCalculateAtOrder)} */}
                            {
                                // doNotCalculateAtOrder == "1" ? (
                                //     <>
                                //     {usePointsAsCurrency
                                //         ? getPointsValue(row.salePrice * row.minQuantity)
                                //         : currency.currencySymbol + numberWithCommas2(row.salePrice * row.minQuantity, decimalsToShow)}
                                //     </>
                                // ) : (
                                    <>
                                    {usePointsAsCurrency
                                        ? getPointsValue(row.salePrice)
                                        : currency.currencySymbol + numberWithCommas2(row.salePrice, decimalsToShow)}
                                    </>
                                // )
                            }
                        </label>

                        <label className="inputQty"> {row.minQuantity} </label>
                    </div>
                }}) } 
            </div>
        </div>
    )
}

export default PrintablePriceTable;
