import axios from 'axios';
import {
	X_TENANT_ID,
	WISHLIST_URL
} from '../../common/ActionUrl';
const publicIp = require("react-public-ip");

const requestOptions = {
	headers: { 'X-TENANT-ID': X_TENANT_ID, },
}

export const updateWishlist = async (data, publicIpLoaded) => {
	let user = localStorage.getItem('user')
		? JSON.parse(localStorage.getItem('user'))
		: ''
	const wishlistSession = localStorage.getItem('-') || '';
	let userId = user?.userId ? user?.userId : null;
	const ipv4 = !!publicIpLoaded ? publicIpLoaded : await publicIp.v4();
	let res = {
		"wishlistItems": data,
		"userId": userId ? userId : wishlistSession,
		"ipAddress": ipv4
	}
	return axios.post(`${WISHLIST_URL}`, res, requestOptions);
};
export const getWishlistById = async (publicIpLoaded) => {
	let user = localStorage.getItem('user')
		? JSON.parse(localStorage.getItem('user'))
		: ''
	let userId = user?.userId ? user?.userId : null;
	if (!!!userId) {
		userId = localStorage.getItem('-') ? localStorage.getItem('-') : null;
	}
	const ipv4 = !!publicIpLoaded ? publicIpLoaded : await publicIp.v4();
	let wishlistItems = await axios.get(`${WISHLIST_URL}/${userId}/${ipv4}`, requestOptions);
	return wishlistItems?.data[0]?.wishlistItems && wishlistItems?.data[0]?.wishlistItems.length > 0 ? wishlistItems?.data[0]?.wishlistItems : [];
};
export const getWishlistList = () => {
	return axios.get(WISHLIST_URL, requestOptions);
};
export const updateUserIdWishlist = async (publicIpLoaded) => {
	const ipv4 = !!publicIpLoaded ? publicIpLoaded : await publicIp.v4();
	let user = localStorage.getItem('user')
		? JSON.parse(localStorage.getItem('user'))
		: ''
	const wishlistSession = localStorage.getItem('-') || '';
	let userId = user?.userId ? user?.userId : '';
	let res = {
		"userId": userId ? userId : '',
		'sessionId' : wishlistSession,
		"ipAddress": ipv4
	}
	let mainRes = await axios.post(`${WISHLIST_URL}/update-userid`, res, requestOptions);
	return mainRes
};