import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Swiper from "react-id-swiper";
import {
  getProductCartQuantity,
  getStock,
  getPersColor,
  getPersFont,
  getPersLocation,
  getPersNote,
  getminQuantity,
  getShowProductPrice,
  getShowMoqNote,
  getMoqNote,
} from '../../helpers/product'
import { Modal } from 'react-bootstrap'
import Rating from './sub-components/ProductRating'
import {connect, useDispatch, useSelector} from 'react-redux'
import ProductImageGalleryPopup from '../../components/product/ProductImageGalleryPopup'
import PortalImageDescription from '../../wrappers/product/PortalImageDescription'
import PortalImageCondensed from "../../wrappers/product/PortalImageCondensed";
import ProductDescriptionInfo from '../../components/product/ProductDescriptionInfo'
import ProductImageGallerySideThumbPopup from './ProductImageGallerySideThumbPopup'
import ProductImageFixedPopup from '../../components/product/ProductImageFixedPopup'
import ProductImageGalleryStickyPopup from '../../components/product/ProductImageGalleryStickyPopup'
import ProductImageGallerySliderPopup from '../../components/product/ProductImageGallerySliderPopup'
import { AuthContext } from '../../common/Auth'
import ProductImageswipeSliderSmall from '../../components/product/ProductImageswipeSliderSmall'
import ProductImageswipeSliderBig from '../../components/product/ProductImageswipeSliderBig'
import {
  fetchDecorationLocations,
  fetchArtworksById, receiveDecorationLocations,
} from '../../redux/actions/productActions'
import AddDecoration from './sub-components/AddDecoration'
import Button from '@material-ui/core/Button/Button'
import { Table } from 'react-bootstrap'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { addToCart } from '../../redux/actions/cartActions'
import { addToWishlist } from '../../redux/actions/wishlistActions'
import { addToCompare } from '../../redux/actions/compareActions'
import * as productsAPIUtil from '../../util/products/productsAPIUtil'
import {
  getDefaultSize,
  getDefaultColor,
} from '../../redux/actions/cartActions'
import Loader from '../../common/Loader'
import { useHistory } from 'react-router-dom'
import {  replaceOrigin } from "../../util/helper";
import ProductImageDescriptionPromotional from "../../wrappers/product/ProductImageDescriptionPromotional";
import ProductAttributeImageDescription from "../../wrappers/product/ProductAttributeImageDescription";
import Axios from "axios";
import { X_TENANT_ID } from "../../common/ActionUrl";
import SharedModal from "../../wrappers/modal/SharedModal";
import { USERTYPES } from "../../constant/userType"
import { useProductDetailState } from "../../pages/shop-product/state/useProductDetailState";
import PrintablePriceTable from "../../wrappers/product/printProduct/PrintablePriceTable";
import { useProductConfigState } from "../../pages/shop-product/state/useProductConfigState";
import ProductPrices from "./sub-components/ProductPrices";
import ProductHatsImageDescription from "../../wrappers/product/ProductHatsImageDescription";
import {useThemeSettings} from "../../layouts/state/useThemeSettings";
function ProductModal(props) {
  const {
    fetchDecorationLocations,
    fetchArtworksById,
    artworkListById,
    setAddnewart,
    decorationLocations,
    artworkList,
    newArtData,
    setNewArtData,
    isRelatedProduct=0,
  } = props

  const { product } = props
  const { currency } = props
  const { discountedprice } = props
  var { finalproductprice } = props
  const { finaldiscountedprice } = props
  const { priceDisplay } = props
  const [selectedColor, setSelectedColor] = useState(null)
  const [loading, setLoading] = useState(false)
  const [addDecorations, setAddDecorations] = useState(false)
  const [productLayout, setProductLayout] = useState('')
  const [productLayoutMain, setProductLayoutMain] = useState('')
  const {
    themeSettingsData,
    settingData,
    storeDetails,
    isDirectToCart,
    showAddToCartOnProd,
    userGroupDiscount,
    decoTypeList,
    userType,
    user,
    usePointsAsCurrency, pointValue,
    ipV4,
  } = useContext(AuthContext)
  const [isAllowoutofStockOrder, setisAllowoutofStockOrder] = useState(false)
  const [productSettings, setProductSettings] = useState(null)
  const [showCompareToggle, setShowCompareToggle] = useState(false)
  const [showWishListToggle, setShowWishListToggle] = useState(false)
  const [productFiels, setProductField] = useState('productId')
  const history = useHistory()
  const [inventorySettings, setInventorySettings] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isMoq, setIsMoq] = useState(false)
  const [isPriceBreak, setIsPriceBreak] = useState(false)
  const [RequiredDecorationForCheckOutPro, setRequiredDecorationForCheckOutPro] = useState(false)
  const [isSkuMoq, setIsSkuMoq] = useState(false)
  const [isRestrictQty,setIsRestrictQty]=useState(false)
  const [minAllQty,setMinAllQty]=useState([])
  const [partPrice, setPartPrice] = useState([])
  const [minQty, setMinQty] = useState('')
  const [prodId, setProdId] = useState(null)
  const [searchTextArtwork,setTextSearchArtwork]=useState('')
  const [isSearchArtwork,setIsSearchArtwork]=useState(false)
  const [IsAllowOutOfStockOrder, setIsAllowOutOfStockOrder] = useState(false)
  const disIndProductPrice = getShowProductPrice(product)
  const [productSettingShowDescription, setProductSettingShowDescription] = useState(true)
  const [artworkSetting, setArtworkSetting] = useState(null)
  const [storeArtwork, setStoreArtwork] = useState([])
  const [temp, setTemp] = useState(0);
  const [selectedImage, setSelectedImage] = useState('');
  const [moqNote, setMoqNote] = useState('Minimum order quantity for this item is [MOQ]');
  const [showMoqNote, setShowMoqNote] = useState(false);
  const [artworkListByIdFinal, setArtworkListByIdFinal] = useState([]);
  const [artworkListByIdFinalCnt, setArtworkListByIdFinalCnt] = useState(false);
  const [singleSelectedDecorations, setSingleSelectedDecorations] = useState(null);
  const [allowOneDec, setallowOneDec] = useState("0")
  const [uniqueLocation, setUniqueLocation] = useState('');
  const [indexPopup, setIndexPopup] = useState([])
  const [mainArrayKeyPopup, setMainArrayKeyPopup] = useState([])
  const [itemsRowsPopup, setItemsRowsPopup] = useState([])
  const [popupText, setPopupText] = useState('');
  const [showDecoPopup, setShowDecoPopup] = useState(false);
  const [isAdded, setIsAdded] = useState(false)
  const [decorationListOrder, setDecorationListOrder] = useState(false);
  const [preventAutoAttach, setPreventAutoAttach] = useState(false)
  const [allowCustomDecoration, setAllowCustomDecoration] = useState(false);
  const [priceTables, setPriceTable] = useState([])
  const [qtyDropDown, setQtyDropDown] = useState(false)
  const [qtyValues, setQtyValues] = useState([])
  const [colorLabel, setColorLabel] = useState('Color')
  const [sizeLabel, setSizeLabel] = useState('Size')
  const [decimalsToShow, setDecimalsToShow] = useState(2)
  const [defaultSizeForAll, setDefaultSizeForAll] = useState([])

  let itemFromCart = null
  let tempitemFromCart = cartItems?.filter((item) => item.id === product.id)
  if (tempitemFromCart?.[0]) {
    itemFromCart = tempitemFromCart[0]
  }
  const dispatch = useDispatch()

  const {
    sortSizeSetting
  } = useThemeSettings({ settingData })

  useEffect(() => {
    if (sortSizeSetting?.length) {
      setDefaultSizeForAll(sortSizeSetting.map(item => item.value));
    }
  }, [sortSizeSetting])

  let groups = []
  let colors = []
  let priceTable = []
  let colors_final = []
  let colors_ids = []
  let colors_hex = []
  let colors_idsColor = []
  let priceTableTemp = []
  product?.ProductPartArray?.ProductPart &&
    product.ProductPartArray.ProductPart.forEach((part) => {
      let priceOption = part
      const size = part.ApparelSize.labelSize
      if (!groups[size]) {
        groups[size] = []
      }
      groups[size].push(priceOption)

      const color = part.ColorArray.Color.colorName
      colors_ids[part.partPrice.partId] = color
      colors_idsColor[color] = part.partPrice.partId
      colors_hex[color] = part.ColorArray.Color.hex

      if (!colors[color]) {
        colors[color] = []
      }
    })
    for (let size in groups) {
      priceTableTemp.push({size: size, priceOptions: groups[size]})
    }
  
    priceTableTemp = priceTableTemp.sort((a,b) => {
      return defaultSizeForAll.indexOf(a.size) - defaultSizeForAll.indexOf(b.size);
    })
    let priceTableTemp1 = []
    let priceTableTemp2 = []
  
    priceTableTemp.map((a) => {
      if(defaultSizeForAll.indexOf(a.size) !== -1) {
        priceTableTemp1.push(a)
      } else {
        priceTableTemp2.push(a)
      }
    })
  
    if(priceTableTemp2.length > 0) {
      priceTableTemp1 = priceTableTemp1.concat(priceTableTemp2)
    }
    
    priceTableTemp1.map(res => {
      priceTable.push(res)
    })
  for (let color in colors) {
    colors_final.push(color)
  }
  const numericSort = (a, b) => {
		const aNumeric = parseInt(a);
		const bNumeric = parseInt(b);
		return aNumeric - bNumeric;
	};
	colors_final = colors_final.sort(numericSort);
  useEffect(() => {
    if (themeSettingsData?.length > 0) {
      themeSettingsData.map((i) => {
        if (i.settingsName === 'theme' && i.values[0].productLayout !== '') {
          setProductLayoutMain(i.values[0].productLayout)
        }
        if (i.settingsName === 'productpageSettings') {
          if (i?.values?.[0]) {
            setProductSettings(i.values[0])
          }
        }
        if (i.settingsName === 'inventorySettings') {
          setInventorySettings(i.values[0])
          if (i.values[0].isAllowoutofStockOrder) {
            setisAllowoutofStockOrder(i.values[0].isAllowoutofStockOrder)
          }
          if (i?.values?.[0]?.isStockFor) {
            setIsStock(i.values[0].isStockFor)
          }
        }
      })
      verifyShowSize();
      verifyShowColor();
    }
  }, [themeSettingsData])
  useEffect(() => {
    if (product) {
      if (product.WebstoreProductPartArray?.length) {
        const partPriceArray = product.WebstoreProductPartArray.find(x => x.active && (selectedColor === x.color || (x.color == colors_final[0] && !selectedColor)))
        if (partPriceArray) {
          const priceTable = partPriceArray.partPrice?.PartPriceArray?.PartPrice ?? []
          setPriceTable(priceTable)
          setQtyValues(priceTable.map(x => x.minQuantity))
        }
      } else if (product?.ProductPartArray?.ProductPart && product?.ProductPartArray?.ProductPart.length > 0) {
        product?.ProductPartArray?.ProductPart &&
          product.ProductPartArray.ProductPart.forEach((part) => {
            let colors = !!part?.ColorArray?.Color?.colorName ? part?.ColorArray?.Color?.colorName : part.color
            let selectedCOlor = !!selectedColor ? selectedColor : colors_final?.[0]
            if (selectedCOlor === colors) {
              if (part) {
                const priceTable = part.partPrice?.PartPriceArray?.PartPrice ?? []
                setPriceTable(priceTable)
                setQtyValues(priceTable.map(x => x.minQuantity))
              }
            }
          })
      }
      setQtyDropDown(!!product.qtyDropDown)
    }
  }, [product, selectedColor])  

  useEffect(() => {
    if(product && !!product?.decorationListOrder) {
      setDecorationListOrder(product?.decorationListOrder)
    }
  }, [product])

  useEffect(() => {
    if(!!product?.relabelColor) {
        setColorLabel(product.relabelColor)
    }
    if(!!product?.relabelSize) {
        setSizeLabel(product.relabelSize)
    }
  },[product])

  useEffect(() => {
    if(product?.allowCustomDecoration === true || product?.allowCustomDecoration === false) {
      setAllowCustomDecoration(product?.allowCustomDecoration)
    } else {
      setAllowCustomDecoration(!!productSettings?.allowCustomDecoration ? productSettings.allowCustomDecoration : false)
    }
    if(typeof product?.decimalsToShow === 'number') {
      setDecimalsToShow(product.decimalsToShow)
    } else if(typeof productSettings?.decimalsToShow === 'number') {
      setDecimalsToShow(productSettings.decimalsToShow)
    } else {
      setDecimalsToShow(2)
    }
  },[productSettings, product])

  useEffect(() => {
    if (!!product?.productLayout ) {
      setProductLayout(product.productLayout);
    } else {
        setProductLayout(productLayoutMain)
    }
  },[product, productLayoutMain])

  const getStoreArtwork = () => {
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        'X-TENANT-ID': X_TENANT_ID,
      },
    }
    Axios
      .get('/api/v1/artworkdata/', requestOptions)
      .then((res) => {
        if (res.data.error) {
          addToast(res.data.message, {
            appearance: 'error',
            autoDismiss: true,
          })
        } else {
          setStoreArtwork(res.data)
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    getStoreArtwork()
    getArtworkLocations()
  }, [])

  const getArtworkLocations = () => {
		productsAPIUtil.getEnabledArtworkLocations(product._id).then(response => {
			setArtworkLocations(response.data);
		}).catch(error => {
			console.log('error', error);
			addToast('Error while loading data!', {
				appearance: "error",
				autoDismiss: true
			});
		})
  }
  
  useEffect(()=>{
    if ( product?.preventAutoAttach === true || product?.preventAutoAttach === false ) {
      setPreventAutoAttach(product?.preventAutoAttach)
    } else {
      setPreventAutoAttach(!!productSettings?.preventAutoAttach ? productSettings.preventAutoAttach : false)
    }
  }, [product, productSettings])
  
  useEffect(() => {
		if(product?.AllowOutofStock === true || product?.AllowOutofStock === false) {
			setIsAllowOutOfStockOrder(product?.AllowOutofStock)
		} else {
		  setIsAllowOutOfStockOrder(isAllowoutofStockOrder)
		}
	  },[product, isAllowoutofStockOrder])
  
  useEffect(() => {
    if (settingData?.length > 0) {
      settingData.map((i) => {
        if (i.page === 'General') {
          if (i.values.itemNumberToShowAtwebstore) {
            setProductField(i.values.itemNumberToShowAtwebstore)
          }
          if (i?.values?.showCompare) {
            setShowCompareToggle(i.values.showCompare)
          }
          if (i?.values?.showWishList) {
            setShowWishListToggle(i.values.showWishList)
          }
        }
        if(i.page === "ArtworkSettings"){
          setArtworkSetting(i.values.isArtworkEnabled)
        }
      })
    }
  }, [settingData])
  const [productDstInventory, setProductDstInventory] = useState(null)
  const [isStock, setIsStock] = useState('')
  const [isStockFor, setIsStockFor] = useState('')
  const [gallerySwiper, getGallerySwiper] = useState(null)
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null)
  const [selectedProductColor, setSelectedProductColor] = useState(
    itemFromCart?.selectedProductColor
      ? itemFromCart.selectedProductColor
      : '',
  )
  const [selectedProductLocation, setSelectedProductLocation] = useState('')
  const [locationLabel, setLocationLabel] = useState('')
  const [showLocationLabel, setShowLocationLabel] = useState(true)
  const [locationPrompt, setLocationPrompt] = useState('')
  const [selectedProductArtwork, setSelectedProductArtwork] = useState('')
  const [selectedProductDecorations, setSelectedProductDecorations] = useState(
    [],
  )
  const [personalizationArray, setPersonalizationArray] = useState(null)

  const [selectedProductSize, setSelectedProductSize] = useState('')
  const [productStock, setProductStock] = useState(
    product.variation ? product.variation[0].size[0].stock : product.stock,
  )
  const [quantityCount, setQuantityCount] = useState(1)
  const [showColor, setShowColor] = useState(false);
  const [showSize, setShowSize] = useState(false);
  const [loadingArtworks, setLoadingartworks] = useState(false);
  const [artworkLocations, setArtworkLocations] = useState([]);
  const artworksListState = useSelector((state) => state.productData.artworkList);
	const artworksProductState = useSelector((state) => state.productData.artworkListById);
  const [isFirstTime, setIsFirstTime] = useState(true)
	const  artworksFromState =  [...artworksListState, ...artworksProductState]

  const wishlistItem = props.wishlistitem
  const compareItem = props.compareitem

  const addToCart = props.addtocart
  const addToWishlist = props.addtowishlist
  const addToCompare = props.addtocompare

  const addToast = props.addtoast
  const cartItems = props.cartitems

  const {
    checkMinQty,
    orderAction,
    getLocations,
  } = useProductConfigState({
    cartItems,
    product,
    artworksFromState,
    artworksProductState,
    allowCustomDecoration,
    selectedProductColor,
    selectedProductDecorations,
    selectedProductLocation,
    artworkSetting,
  })

  const productCartQty = getProductCartQuantity(
    cartItems,
    product,
    selectedProductColor,
    selectedProductSize,
  )
  useEffect(() => {
    if (
      gallerySwiper !== null &&
      gallerySwiper.controller &&
      thumbnailSwiper !== null &&
      thumbnailSwiper.controller
    ) {
      gallerySwiper.controller.control = thumbnailSwiper
      thumbnailSwiper.controller.control = gallerySwiper
    }
  }, [gallerySwiper, thumbnailSwiper])

  let galleryType = null
  if (productLayout === 'product-tab-left') {
    galleryType = 'leftThumb'
  } else if (productLayout === 'product-tab-right') {
    galleryType = 'rightThumb'
  } else if (productLayout === 'product-sticky') {
    galleryType = 'productSticky'
  } else if (productLayout === 'product-slider') {
    galleryType = 'productSlider'
  } else if (productLayout === 'product-fixed-image') {
    galleryType = 'fixedImage'
  } else if (productLayout === 'product-variation') {
    galleryType = ''
  } else if (productLayout === 'product-portal') {
    galleryType = 'productPortal'
  } else if (productLayout === 'product-condensed') {
    galleryType = 'productCondensed'
  } else if (productLayout === 'product-affiliate') {
    galleryType = ''
  } else if (productLayout === 'promotional-product') {
    galleryType = 'productPromotional'
  } else if (productLayout === 'stepper') {
    galleryType = 'stepper'
  } else if (productLayout === 'decoration-expanded') {
    galleryType = 'decoration-expanded'
  } else if (productLayout === 'product-tab-bottom') {
    galleryType = ''
  } else if (productLayout === 'product-simple') {
    galleryType = ''
  } else if (productLayout === 'eComm-product-template') {
    galleryType = ''
  }
  // console.log('galleryType: ', galleryType, " ====", productLayout);

  useEffect(() => {
    if(!!!product.isStockFor || product.isStockFor === 'globalsetting') {
      setIsStockFor(isStock)
    } else {
      setIsStockFor(product.isStockFor)
    }
  }, [product, isStock])

  useEffect(() => {
    if (storeDetails?.accountId) {
      if((artworkListById?.length === 0 || (isRelatedProduct === 1 && artworkListById?.length > 0)))
        fetchArtworksById({
          account_id: storeDetails.accountId,
          product_id: product.id,
          userId: user?.userId || undefined,
          categories: product.ProductCategoryArray?.map(x => x.id) || [],
          restrict: true,
        })
      }
  }, [storeDetails])

  useEffect(() => {
    const locations = getLocations();
    if (locations?.locationIds?.length > 0 && isFirstTime) {
      setIsFirstTime(false)
      fetchDecorationLocations(locations.locationIds)
    } else if(locations?.locationIds?.length === 0 && isFirstTime) {
      setIsFirstTime(false)
      dispatch(
          receiveDecorationLocations({
                data: []
              }
          ),
      )
    }
  }, [product, fetchDecorationLocations])

  useEffect(() => {
    if(product?.RequiredDecorationForCheckOutPro === true || product?.RequiredDecorationForCheckOutPro === false) {
      setRequiredDecorationForCheckOutPro(product?.RequiredDecorationForCheckOutPro )
    }
    else {
      setRequiredDecorationForCheckOutPro(productSettings?.RequiredDecorationForCheckOut)
    }
    if(!!product?.renameLocationNameLabel) {
      setLocationLabel(product?.renameLocationNameLabel)
    } else if(!!productSettings?.renameLocationNameLabel) {
      setLocationLabel(productSettings?.renameLocationNameLabel)
    } else {
      setLocationLabel('Select Location')
    }
    if(product?.showDecorationLocationLabel === false || product?.showDecorationLocationLabel === true) {
      setShowLocationLabel(product?.showDecorationLocationLabel)
    } else {
      setShowLocationLabel(productSettings?.showDecorationLocationLabel)
    }
    if(!!product?.renameLocationNamePrompt) {
      setLocationPrompt(product?.renameLocationNamePrompt)
    } else if(!!productSettings?.renameLocationNamePrompt) {
      setLocationPrompt(productSettings?.renameLocationNamePrompt)
    } else {
      setLocationPrompt('Select Location')
    }
    if (product?.showColorProd === true || product?.showColorProd === false) {
      setShowColor(product?.showColorProd)
    } else {
      setShowColor(productSettings?.showColor)
    }
    if (product?.showSizeProd === true || product?.showSizeProd === false) {
      setShowSize(product?.showSizeProd)
    } else {
      setShowSize(productSettings?.showSize)
    }
    if (product?.showDescription === true || product?.showDescription === false) {
      setProductSettingShowDescription(product?.showDescription)
    } else {
      setProductSettingShowDescription(productSettings?.showDescription)
    }
    if(!!product?.AllowOneDecorationLimitPro && product?.AllowOneDecorationLimitPro !== " ") {
      setallowOneDec(product?.AllowOneDecorationLimitPro)
    } else {
      setallowOneDec(productSettings?.AllowOneDecorationLimit)
    }
    if(product && productSettings && minQty) {
      setMoqNote(getMoqNote(productSettings, product, minQty));
      setShowMoqNote(getShowMoqNote(productSettings, product));
    }
    setPartPrice(product?.WebstoreProductPartArray[0]?.partPrice?.PartPriceArray?.PartPrice,)
    setIsMoq(product?.isProductMoq)
	  setIsPriceBreak(product?.doNotCalculateAtOrder == "1" ? false : product?.isPriceBreak)
    setIsSkuMoq(product?.isProductskuMoq)
	  setIsRestrictQty(product?.isRestrictQty)
    setProdId(product?.productId)
	  setIsSearchArtwork(product?.searchArtwork)
  },[product, productSettings, minQty])

  useEffect(() => {
    if (newArtData) {
      fetchArtworksById({
        account_id: storeDetails.accountId,
        product_id: product.id,
        userId: user?.userId || undefined,
        categories: product.ProductCategoryArray?.map(x => x.id) || [],
        restrict: true,
      })
      if (themeSettingsData?.length > 0) {
        let tempDecoArray = selectedProductDecorations
          ? selectedProductDecorations
          : []
        let locationArray = decorationLocations?.filter(
          (locationArray) => locationArray.id === selectedProductLocation,
        )
        tempDecoArray.push({
          artworkID: newArtData.artworkID,
          location: locationArray[0].locationName,
          locationId: selectedProductLocation,
          variationUniqID: newArtData.variation.design_variation_unique_id,
        })
        setSelectedProductDecorations(tempDecoArray)
        setSelectedProductLocation('')
        setSelectedProductArtwork('')
        setNewArtData(false)
      }
    }
  }, [newArtData])

	useEffect(() => {
		if (partPrice && partPrice.length > 0) {
			let allQty = [];
			let minQty = getminQuantity(partPrice)
			setMinQty(minQty)
			allQty = partPrice.map((item) => {
				return Number(item.minQuantity)
			})
			setMinAllQty(allQty)
		}
	}, [partPrice])
	const searchArtworkName = (e) => {
		setTextSearchArtwork(e.target.value)
	}
  const verifyShowSize = () => {
    if(priceTable.length === 1) {
      setSelectedProductSize(priceTable[0].size);
    }
    if(!showSize && priceTable.length > 1) {
      setShowSize(true);
    }
  }
  const verifyShowColor = () => {
    if(colors_final.length === 1) {
      setSelectedProductColor(colors_final[0]);
    }
    if(!showColor && colors_final.length > 1) {
      setShowColor(true);
    }
  }
  useEffect(() => {
    verifyShowSize()
    verifyShowColor()
  }, [showColor, showSize])
  const addPersonalization = () => {
    let persQty = quantityCount

    let persRows = []
    if (Number(persQty) > 0) {
      let itemQty = Number(persQty)
      let pushedIds = []
      for (let index = 0; index < itemQty; index++) {
        if (personalizationArray?.[index]) {
          persRows.push(personalizationArray[index])
        } else {
          persRows.push({
            color: getPersColor(product, productSettings),
            location: getPersLocation(product, productSettings),
            font: getPersFont(product, productSettings),
            notes: getPersNote(product, productSettings),
            displayText: '',
            itemCode: product.itemCode,
            itemColor: selectedProductColor,
            itemId: product.id,
            itemNo: product.productId,
            itemSize: selectedProductSize,
            orderId: '',
            rowId: '',
            rowQtyId: '',
            rowQtyNo: index + 1,
            sequance: 1,
          })
        }
      }
      setPersonalizationArray(persRows)
    }
  }
  const getPesonalizationTable = () => {
    let rowQtyNo = null
    let rowId = null
    return (
      <Table className="personalizationTabel">
        <thead>
          <tr>
            <td>Item</td>
            <td>{sizeLabel}</td>
            <td>Item {colorLabel}</td>
            <td>Line</td>
            <td>Text</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {personalizationArray.map((personalization, key) => {
            let flag = true
            if (Number(personalization.sequance) !== 1) {
              flag = false
            }
            rowQtyNo = personalization.rowQtyNo
            rowId = personalization.rowId
            return (
              <tr key={key}>
                <td>{flag && 'Item ' + personalization.rowQtyNo}</td>
                <td>{flag && personalization.itemSize}</td>
                <td>{flag && personalization.itemColor}</td>
                <td>{'Line ' + personalization.sequance}</td>
                <td>
                  <input
                    onChange={(e) => {
                      changePersonalizatinArray(e, 'displayText', key)
                    }}
                    value={personalization.displayText}
                  />
                </td>
                <td>
                  {flag && (
                    <AddIcon
                      onClick={() => {
                        AddPersonalizationRow(personalization, key)
                      }}
                    />
                  )}
                  {!flag && (
                    <DeleteIcon
                      onClick={() => {
                        DeletePersonalizationRow(personalization, key)
                      }}
                    />
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }
  const DeletePersonalization = () => {
    setPersonalizationArray(null)
  }
  const DeletePersonalizationRow = (personalization, key) => {
    if (personalizationArray) {
      let tempArray = personalizationArray.filter((persArray, index) => {
        if (key === index) {
          return false
        }
        return persArray
      })
      setPersonalizationArray(tempArray)
    }
  }
  const AddPersonalizationRow = (personalization, key) => {
    if (personalizationArray) {
      let tempArray = []
      personalizationArray.map((persArray, index) => {
        tempArray.push(persArray)
        if (key === index) {
          tempArray.push({
            ...persArray,
            color: getPersColor(product, productSettings),
            location: getPersLocation(product, productSettings),
            font: getPersFont(product, productSettings),
            notes: getPersNote(product, productSettings),
            displayText: '',
            sequance: (Number(persArray.sequance) + 1).toString(),
          })
        }
      })
      setPersonalizationArray(tempArray)
    }
  }
  const selectLocationBox = (selectlocationBox, key) => {
    let decoExistLocations = []
    if (product?.LocationArray?.Location) {
      product.LocationArray.Location.map((location) => {
        if (location?.locationName !== '')
          decoExistLocations.push(location.locationName)
      })
    }
    return (
      <select
        value={selectlocationBox.location}
        onChange={(e) => {
          changePersonalizatinArray(e, 'Location', key)
        }}
      >
        <option value="Select">Select</option>
        {decorationLocations?.map((location, key) => {
          if (decoExistLocations.indexOf(location.locationName) !== -1) {
            return (
              <option value={location.locationName} key={key}>
                {location.locationName}
              </option>
            )
          } else {
            return false
          }
        })}
      </select>
    )
  }
  const changePersonalizatinArray = (e, type, key) => {
    if (personalizationArray) {
      let tempArray = personalizationArray.filter((persArray, index) => {
        if (key === index) {
          if (type === 'Font') {
            persArray.font = e.target.value
          } else if (type === 'Color') {
            persArray.color = e.target.value
          } else if (type === 'Location') {
            persArray.location = e.target.value
          } else if (type === 'Note') {
            persArray.notes = e.target.value
          } else if (type === 'displayText') {
            persArray.displayText = e.target.value
          }
        }
        return persArray
      })
      setPersonalizationArray(tempArray)
    }
  }
  const selectcolorBox = (selectcolorBox, key) => {
    return (
      <select
        onChange={(e) => {
          changePersonalizatinArray(e, 'Color', key)
        }}
        value={selectcolorBox.color}
      >
        <option value="0">Select</option>
        <option value="Black">Black</option>
        <option value="White">White</option>
      </select>
    )
  }
  const selectfontBox = (selectfontBox, key) => {
    return (
      <select
        onChange={(e) => {
          changePersonalizatinArray(e, 'Font', key)
        }}
        value={selectfontBox.font}
      >
        <option value="0">Select</option>
        <option value="Script">Script</option>
        <option value="Block Upper Lower">Block Upper Lower</option>
        <option value="Block Upper">Block Upper</option>
        <option value="Italic Upper Lower">Italic Upper Lower</option>
        <option value="Italic Upper">Italic Upper</option>
        <option value="Drax Standard Font">Drax Standard Font</option>
      </select>
    )
  }
  const changeLocation = (e) => {
    setIsAdded(false)
    let locationId = !!e.currentTarget?.value ? e.currentTarget.value : e
    setSelectedProductLocation(locationId)
    if (selectedProductDecorations.length === 1 && allowOneDec === "2") {
      setSingleSelectedDecorations(null)
      setSelectedProductDecorations([])
    }
  }
  const getDecoHtml = () => {
    let decoExistLocations = []
    let locationName = '';
    let locID = ''
    selectedProductDecorations.map((decoFilter, key) => {
      locID = selectedProductLocation !== undefined && selectedProductLocation !== "" ? selectedProductLocation : decoFilter.locationId
      if(decoFilter.locationId === locID)
        locationName = decoFilter.location
    })
    if(!(!!locationName) && product?.LocationArray?.Location) {
      locID = selectedProductLocation !== undefined && selectedProductLocation !== "" ? selectedProductLocation : ''
      let localName = product.LocationArray.Location.filter(location => (location.locationId === locID && location?.locationName !== "") ? location?.locationName : '')
      locationName = !!localName?.[0]?.locationName ? localName?.[0]?.locationName : ''
    }
    if (product?.LocationArray?.Location) {
      product.LocationArray.Location.map(location => {
        if (location?.locationName !== "")
          decoExistLocations.push(location.locationName)
      })
    }

    let yFilter = []

    artworksFromState.map(itemY => {
      let variationId = [];
      itemY.design.variation.map(designVal => {

        if (designVal?.itemImageThumbnail?.length == 0) {
          variationId.push(designVal.design_variation_unique_id)
        }
      })
      let getUsrPrlocation = false
      if((itemY?.artworkSource === "aether" && user.userId) || allowCustomDecoration || itemY?.artworkSource !== "aether")
       getUsrPrlocation = true
      if(itemY.productToDecorationMapping.length > 0) {
        itemY.productToDecorationMapping.map((parentVal) => {
          parentVal.mapping.map((val)=>{
            if (val.color == selectedProductColor && !variationId.includes(val.variationId)) {
              if (!yFilter.includes(parentVal.locationName) && getUsrPrlocation) {
                yFilter.push(parentVal.locationName)
              }
    
            }
          })
        })
      }
      if((decoExistLocations.indexOf(itemY?.artworkLocation) !== -1 || !(!!itemY?.artworkLocation)) && itemY.productToDecorationMapping.length === 0 && itemY?.design?.variation !== "" && artworksProductState.length === 0) {
        if(!!itemY?.design?.variation && itemY?.design?.variation?.length === 1) {
         if (!yFilter.includes(itemY?.artworkLocation) && !!itemY?.artworkLocation) {
           yFilter.push(itemY.artworkLocation)
         }
        } else {
         !!itemY?.design?.variation && itemY.design.variation.map(res => {
           if (!!res?.design_variation_color && res?.design_variation_color.split(',').includes(selectedProductColor)) {
             if (!yFilter.includes(itemY?.artworkLocation) && !!itemY?.artworkLocation) {
               yFilter.push(itemY.artworkLocation)
             }
           }
         })
        }
        if(!(!!itemY?.artworkLocation)) {
          decoExistLocations.map((resLoc) => {
            if (!yFilter.includes(resLoc)) {
              yFilter.push(resLoc)
            }
          })
        }
      }
      if((decoExistLocations.indexOf(itemY?.artworkLocation) !== -1 || !!itemY?.artworkLocation) && itemY.productToDecorationMapping.length === 0 && itemY?.design?.variation !== "" && artworkSetting && artworksProductState.length === 0) {
        if(!!itemY?.design?.variation && itemY?.design?.variation?.length === 1) {
         if (!yFilter.includes(itemY?.artworkLocation)) {
           yFilter.push(itemY.artworkLocation)
         }
        } else {
         !!itemY?.design?.variation && itemY.design.variation.map(res => {
           if (!!res?.design_variation_color && res?.design_variation_color.split(',').includes(selectedProductColor)) {
             if (!yFilter.includes(itemY?.artworkLocation)) {
               yFilter.push(itemY.artworkLocation)
             }
           }
         })
        }
      }
    })
    if(yFilter.length === 0 && artworkSetting) {
      decoExistLocations.map((resLoc)=> {
        if (!yFilter.includes(resLoc)) {
          yFilter.push(resLoc)
        }
      })
    }
    let filteredX = decorationLocations.filter(itemX => yFilter.includes(itemX.locationName));

    let tempDecorationLocations = filteredX
    if (decoExistLocations?.length > 0 && tempDecorationLocations?.length > 0) {
      tempDecorationLocations = tempDecorationLocations.filter((f) => {
        if (decoExistLocations.indexOf(f.locationName) !== -1) {
          if(product.restrictAfterFirstChoice) {
            let numberChoice = 0;
            let selectedArtworks = selectedProductDecorations.filter((row) => row.artworkID) || []
            numberChoice = selectedArtworks.length === tempDecorationLocations.length 
              ? selectedArtworks.length - 1
              : selectedArtworks.length;
            let arrayArtworks = artworkLocations.filter(x => x.numberChoice === numberChoice && !x.isEnable) ?? [];
            let artworksToReadFiltered = [];
            let arrayArtworksTemp = []; 
            artworksFromState.forEach(x => {
              let index = arrayArtworks.findIndex(z => z.artworkId === x.id);
              if(index === -1) {
                arrayArtworksTemp.push(x);
              }
            })
            arrayArtworksTemp.forEach(x => {
              let insert = x.productToDecorationMapping.filter(y =>  y.locationName === f.locationName).length;
              if(insert > 0) {
                artworksToReadFiltered.push(x);
              }
            });
            return artworksToReadFiltered.length > 0;
          } else {
            return true;
          }
        }
        return false
      })
    }

    let newLocation = []
    if (selectedProductDecorations.length > 0 || tempDecorationLocations.length > 0) {
      tempDecorationLocations &&
        tempDecorationLocations.map((val, index) => {
          let checkLocation =
            selectedProductDecorations && selectedProductDecorations.find((d) => d.locationId === val.id && d.variationUniqID && d.variationUniqID !== "" && d.variationUniqID !== undefined)

          if (checkLocation === undefined || checkLocation === '') {
            newLocation.push(val)
          }
        })
    }
    if(uniqueLocation === '') {
      if(!!(tempDecorationLocations.length === 1) && !!(tempDecorationLocations?.[0]?.locationName)) {
        setSelectedProductLocation(tempDecorationLocations[0].id);
        setUniqueLocation(tempDecorationLocations[0].locationName);
        setTemp(temp + 1)
      }
    }
	  return (
		  <div className="mt-3 mb-3">
			  <div>
				  {addDecorations && selectedProductLocation !== '' && !!tempDecorationLocations && tempDecorationLocations?.length > 1 && (
					  <Button
						  variant="outlined"
						  onClick={() => {
							  setSelectedProductLocation('')
							  setSelectedProductArtwork('')
						  }}
						  className="mr-2 float-right"
					  >
						  Clear selection
					  </Button>
				  )}
				  {addDecorations && (
					  <Button
						  variant="contained"
						  className="mr-2 float-right"
						  onClick={() => {
							  setAddDecorations(false)
						  }}
					  >
						  Cancel
					  </Button>
				  )}
			  </div>
        {(getSettingStatus('showDecoration')) && (!(selectedProductDecorations.length === 1 && allowOneDec === "1")) && (
          <>
          {loadingArtworks && <p className={"ml-3"} >Loading ...</p>}
          {showLocationLabel && <label>
					  <b>{`${locationLabel}:`}</b>
				  </label>}
           {(newLocation.length > 0 || tempDecorationLocations?.length === 1) && (
            <>
              {tempDecorationLocations.length > 1 ? (
                <select
                  style={{ width: 'auto', marginLeft:'0px' }}
                  onChange={(e) => {
                    changeLocation(e)
                  }}
                  value={selectedProductLocation}
                >
                  <option value="">{locationPrompt}</option>
                  {newLocation?.map((locationArray, key) => {
                    return (
                      <option key={key} value={locationArray.id}>
                        {locationArray.locationName}
                      </option>
                    )
                  })}
                </select>
              ) : <label>&nbsp;{uniqueLocation}</label>
              } 
            </>
            )}
          </>
          )}
			  {/* <select
				  className={showLocationLabel ? "ml-2" : ''}
				  style={{ width: 'auto' }}
				  onChange={(e) => {
					  setSelectedProductLocation(e.currentTarget.value)
            if (selectedProductDecorations.length === 1 && allowOneDec === "2") {
              setSingleSelectedDecorations(null)
              setSelectedProductDecorations([])
              setSelectedDecorations(null)
            }
				  }}
				  value={selectedProductLocation}
			  >
				  <option value="">{locationPrompt}</option>
				  {tempDecorationLocations?.map((locationArray, key) => {
					  return (
						  <option key={key} value={locationArray.id}>
							  {locationArray.locationName}
						  </option>
					  )
				  })}
			  </select> */}
			  {
					<div className="row clear pt-1">
						<div className="col-md-6"></div>
						{selectedProductLocation !== '' && isSearchArtwork &&
							<div className="col-md-6">
								<input
									type="text"
									className="inputStyle"
									value={searchTextArtwork}
									name="searchTextArtwork"
									placeholder={(product?.artworkSearchPlaceholderText === undefined ? "Search Artwork…" : (!!product?.artworkSearchPlaceholderText ? product.artworkSearchPlaceholderText : ''))}
									onChange={(e) => searchArtworkName(e)}
								/>
							</div>
						}
					</div>
				}
			  {getSettingStatus('showDecoration') && selectedProductLocation !== '' && (
                  <AddDecoration
                      setSelectedProductArtwork={ setSelectedProductArtwork }
                      selectedProductArtwork={ selectedProductArtwork }
                      addDecoration={ addDecoration }
                      setAddnewart={ setAddnewart }
                      searchTextArtwork={ searchTextArtwork }
                      allowCustomDecoration={ allowCustomDecoration }
                      artworkSetting={ artworkSetting }
                      storeArtwork={ storeArtwork }
                      selectedProductLocation={ selectedProductLocation }
                      decorationLocations={ decorationLocations }
                      selectedProductColor={ selectedProductColor }
                      selectedDecorationsList={ selectedProductDecorations }
                      artworkDuplicatesAllowed={ productSettings?.artworkDuplicatesAllowed }
                      artworkDuplicatesAllowedPro={ product?.artworkDuplicatesAllowed }
                      locationID={ selectedProductLocation }
                      tempDecorationLocations={ tempDecorationLocations }
                      product={ product }
                      artworkLocations={ artworkLocations }
                      allowOneDec={ allowOneDec }
                      isAdded={ isAdded }
                      setIsAdded={ setIsAdded }
                      locationName={ locationName }
                      decorationListOrder={ decorationListOrder }
                      preventAutoAttach={ preventAutoAttach }
                  />
              ) }
		  </div>
	  )
  }
  const changeColor = async(e) => {
    let color = e && e.target && e.target.value && e.target.value ? e.target.value : e
    let getImage = product?.WebstoreProductPartArray.filter((val) => val.color === color && val.isSwapImage === true,)
		if (getImage.length > 0) {
				setSelectedImage(getImage[0]?.image)
		} else {
			let getIndex = product.MediaContent.filter(
				(val) => val.color === color && val.primary,
			)
			if (getIndex.length) {
				setSelectedImage(getIndex[0].url)
			} else {
				let secondarygetIndex = product.MediaContent.filter(
					(val) => val.color === color,
				)
				setSelectedImage(secondarygetIndex[0]?.url)
			}
		}
    let tempDecoArray = [];
		if ( getImage.length === 0) {
			if(getSettingStatus('showDecoration')){
      setIsAdded(false)
			for (let val of artworkListByIdFinal) {
				let artWorkId = val.id

				if (val?.productToDecorationMapping[0]?.isRequiredForStore === '1') {
					let locationId = val?.productToDecorationMapping[0]?.location
					let locationName = val?.productToDecorationMapping[0]?.locationName
					for (let subVal of val.productToDecorationMapping[0].mapping) {

						if (subVal.color === color) {
							const decoPricedetails = await getDecoPrice(val.id);
							let variationId = ''
							if (val.design.variation.length === 1) {
								variationId = val.design.variation[0]
							} else {
								let getVariation = null
								val.design.variation.map((val, index) => {
									if (val.design_variation_color.includes(color)) {
										getVariation = index;
									}
								})
								if (getVariation === 0 || getVariation > 0) {
									variationId = val.design.variation[Number(getVariation)]
								}
							}

							let decoData = {
								"artworkID": artWorkId,
								"location": locationName,
								"locationId": locationId,
								"variationUniqID": variationId.design_variation_unique_id,
								decoPricedetails: decoPricedetails,                
                "isRequiredForStore": val?.productToDecorationMapping[0]?.isRequiredForStore,
							}
							tempDecoArray.push(decoData)
						}
					}
				}
			}
		 }
		}
		setLoading(false)

		setSingleSelectedDecorations(tempDecoArray[tempDecoArray.length - 1])
		setSelectedProductDecorations(tempDecoArray)
		setTemp(temp + 1)
  }
  const [tempValueCheck, setTempValueCheck] = useState(0)
  const colorselectbox = () => {
    let colors_final_temp = colors_final
    if(colors_final_temp.length === 1 && tempValueCheck === 0) {
      setSelectedProductColor(colors_final_temp[0])
      setSelectedColor(colors_final_temp[0])
      changeColor(colors_final_temp[0])
			setTempValueCheck(tempValueCheck+1)
		}
    return (
      <select
        onChange={(e) => {
          setSelectedProductColor(e.target.value)
          setSelectedColor(e.target.value)
          changeColor(e)
        }}
        value={selectedProductColor}
      >
        <option key={'colorkey'}>Select {colorLabel}</option>
        {colors_final_temp.map((color, key) => {
          return (
            <option key={key} value={color}>
              {color}
            </option>
          )
        })}
      </select>
    )
  }
  const [tempValueCheckSize, setTempValueCheckSize] = useState(0)
  const sizeselectbox = () => {
    if((!(!!selectedProductColor) || selectedProductColor == `Select ${colorLabel}`) && priceTable.length === 1 && tempValueCheckSize === 0) {
      setSelectedProductSize(priceTable[0].size)
      setTempValueCheckSize(tempValueCheckSize+1)
    }
    return (
      <select
        onChange={(e) => {
          setSelectedProductSize(e.target.value)
        }}
        value={selectedProductSize}
      >
        <option key={'colorkey'}>Select {sizeLabel}</option>
        {priceTable.map((tableArray, key) => {
          return (
            <option key={key} value={tableArray.size}>
              {tableArray.size}
            </option>
          )
        })}
      </select>
    )
  }
  const getDecoPrice = async (artWorkID, artworkListByIdTemp=[]) => {
    let data = null;
    try {
      setLoading(true)
      let artwork_selected = artworkListByIdTemp?.filter(
        (artorkArray) => artorkArray.id === artWorkID,
      )[0]
      if (artwork_selected) {
        let decodata = {
          offset: '0',
          limit: '500',
          order: 'name',
          orient: 'desc',
          term: {
            id: '',
            name: '',
            vendorId: artwork_selected?.design?.decoVendorId,
            vendorName: artwork_selected?.design?.decoVendorName,
            decoratorType: artwork_selected?.design?.designType,
            decoratorSubType: '',
            decorationIdentifier: '',
            stitchesStart: '',
            stitchesUpto: '',
            setupCharge: '',
            qunatityStart: '',
            quantityUpto: '',
            price: '',
            salePrice: '',
            setupChargeSalePrice: '',
            decorationDetail: '',
          },
        }
        await productsAPIUtil.getDecorationPrice(decodata).then(result => {
          setLoading(false)
          if (result?.data?.data) {
            data = result.data.data
          }
        }).catch(error => {
          setLoading(false)
          console.log('error', error)
        })
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.log('error', error)
    }
    return data;
  }


  const addDecoration = async (variation, artWorkId, selectedProductLocationId, color, artworkListByIdTemp=[]) => {
    setArtworkListByIdFinal(artworkListByIdTemp)
    setArtworkListByIdFinalCnt(artworkListByIdFinalCnt+1)
    let dPrice = await getDecoPrice(artWorkId, artworkListByIdTemp)
    let tempDecoArray = selectedProductDecorations ? selectedProductDecorations : [];
    let locationArray = decorationLocations?.filter((locationArray) => locationArray.id === selectedProductLocationId)

    let artwork_selected = artworkListByIdTemp.filter(val => val.id === artWorkId)[0]

    let decoPriceDetails = []
    if (dPrice?.length > 0) {
      dPrice.map((priceArray) => {
        if (artwork_selected?.design?.designType === priceArray.decoratorType) {
          decoPriceDetails.push(priceArray)
        }
      })
    }
    let decoData = {
      artworkID: artWorkId,
      location: locationArray[0].locationName,
      locationId: locationArray[0].id,
      variationUniqID: variation.design_variation_unique_id,
      decoPriceDetails: decoPriceDetails,
      isRequiredForStore: artwork_selected?.productToDecorationMapping[0]?.isRequiredForStore
    }
    let getDecoOfLocation = tempDecoArray.filter(val => val.locationId === locationArray[0].id)
    if (getDecoOfLocation.length === 0) {
      setSingleSelectedDecorations(decoData)
      tempDecoArray.push(decoData)
    } else if (getDecoOfLocation.length === 1) {
      let replaceTempDecoArray = tempDecoArray.filter(val => val.locationId !== locationArray[0].id)
      tempDecoArray = replaceTempDecoArray
      tempDecoArray.push(decoData)
      setSingleSelectedDecorations(decoData)
      setSelectedProductDecorations(tempDecoArray)
    }
    let getIndex = product.LogoInfo.filter(
      (val) =>
        val.color === color &&
        val.location === locationArray[0].locationName,
    )

    if (getIndex.length > 0) {
      setSelectedImage(getIndex[0]?.image)
    } else {

      let getIndexOfExtra_logo = []
      product.LogoInfo.map(val => {
        if (val.color === color) {

          if (val.extra_logo.filter(e => e.location === locationArray[0].locationName).length > 0) {
            getIndexOfExtra_logo.push(val)
          }
        }
      })

      if (getIndexOfExtra_logo.length) {

        setSelectedImage(getIndexOfExtra_logo[0]?.image)
      } else {
        let getImgColorIndex = product.LogoInfo.filter(
          (val) =>
            val.color === color
        )

        if (getImgColorIndex.length > 0) {
          setSelectedImage(getImgColorIndex[0]?.image)
        }
      }
    }
    setSelectedProductLocation(selectedProductLocationId)
    setSelectedProductArtwork(artWorkId)
    setTemp(temp + 1)
  }
  useEffect(() => {
    if (personalizationArray) {
      addPersonalization()
    }
  }, [quantityCount])
  /*useEffect(() => {
    if (selectedColor) {
      setSelectedProductColor(selectedColor)
    }
  }, [selectedColor])*/
  const [productInventory, setProductInventory] = useState(null)
  useEffect(() => {
    let inventoryArray = null
    let stock = null
    if (productInventory) {
      if (productInventory.productId === product.productId) {
        inventoryArray = productInventory?.PartInventoryArray?.PartInventory
      }
    }
    inventoryArray &&
      inventoryArray.map((stockArray) => {
        if (
          selectedProductColor === stockArray.partColor &&
          stockArray.labelSize === selectedProductSize
        ) {
          if (stockArray?.InventoryLocationArray?.InventoryLocation?.length) {
            stockArray.InventoryLocationArray.InventoryLocation.map(
              (inventoryArray) => {
                if (inventoryArray?.inventoryLocationQuantity?.Quantity) {
                  stock =
                    Number(stock) +
                    Number(
                      inventoryArray.inventoryLocationQuantity.Quantity.value,
                    )
                }
              },
            )
          }
        }
      })
    setProductStock(stock)
  }, [selectedProductSize, selectedProductColor])
  useEffect(() => {
    let componentMounted = true
    const fetchData = async () => {
      // if (product?.poType === "Stock") {
      try {
        await fetchStockData()
      } catch (error) {
        setLoading(false)
        console.log('error', error)
      }

      // }
    }
    fetchData()
    return () => {
      componentMounted = false
    }
  }, [product])

  useEffect(() => {
    if (productInventory && productDstInventory) {
      let sizeDefault = getDefaultSize(product)
      let sizcolorDefault = getDefaultColor(product)
      let stock = null
      const inventoryArray = productInventory.PartInventoryArray?.PartInventory
      inventoryArray &&
        inventoryArray.map((stockArray) => {
          if (
            sizcolorDefault === stockArray.partColor &&
            stockArray.labelSize === sizeDefault
          ) {
            if (stockArray?.InventoryLocationArray?.InventoryLocation?.length) {
              stockArray.InventoryLocationArray.InventoryLocation.map(
                (inventoryArray) => {
                  if (inventoryArray?.inventoryLocationQuantity?.Quantity) {
                    stock =
                      Number(stock) +
                      Number(
                        inventoryArray.inventoryLocationQuantity.Quantity.value,
                      )
                  }
                },
              )
            }
          }
        })
      setProductStock(stock)
    }
  }, [productDstInventory, productInventory])

  const fetchStockData = async () => {
    setIsLoading(true)
    let inventoryData = await productsAPIUtil.getProductStock(product.id)
    setIsLoading(false)
    if (inventoryData && inventoryData.status === 201) {
      setProductDstInventory(inventoryData.data.dtsInventory.data)
      setProductInventory(inventoryData.data.inventory)
    }
  }

  const checkStock = () => {
    if (IsAllowOutOfStockOrder) {
      return true
    }
    if (product?.poType === 'Stock') {
      if (
        productStock &&
        1 + Number(productStock) - Number(productCartQty) > quantityCount
      ) {
        return true
      }
      return false
    } else {
      return true
    }
  }
  const getProductStock = () => {
    return getStock(
      selectedProductSize,
      selectedProductColor,
      productInventory,
      product,
      productDstInventory,
      isStockFor,
    )
  }
  const getSettingStatus = (key) => {
    if (product) {
      if (product[key] === false || product[key] === true) {
        return product[key]
      }
    }
    if (productSettings) {
      if (productSettings[key] === false || productSettings[key] === true) {
        return productSettings[key]
      }
    }
    return false
  }

  const getSettingStatusAdd = (key) => {
    if (product?.[key] === false || product?.[key] === true) {
      return product[key]
    }
    return productSettings?.[key]
  }
  const getInventrySettingStatus = (key) => {
    if (product) {
      if (product[key] === false || product[key] === true) {
        return product[key]
      }
    }
    if (inventorySettings) {
      if (inventorySettings[key] === false || inventorySettings[key] === true) {
        return inventorySettings[key]
      }
    }
    return false
  }

  const isSizeValid = () => {
    return !!selectedProductSize && selectedProductSize !== `Select ${sizeLabel}`;
  }

  const isColorValid = () => {
    return !!selectedProductColor && selectedProductColor !== `Select ${colorLabel}`;
  }

  const isShowInventoryEnabled = getInventrySettingStatus('showInventory');
  const goToCart = () => {
    setUniqueLocation('');
    localStorage.setItem('redirectUrl', window.location.pathname)
    if (isDirectToCart) {
      history.push('/cart')
    }
  }

  let todayDate = new Date().toISOString()

  if (todayDate >= product?.startDate && todayDate <= product?.endDate) {
    if (product?.makeProductSale) {
      showSell = true
    }
  } else if (product?.startDate === '' || product?.startDate === undefined) {
    if (product.makeProductSale) {
      showSell = true
    }
  }

  let showSell = false
  let showSellPrice = false
  let offerPrice = 0
  let discountType = ''

  if (
    product?.showPriceBreak &&
    todayDate >= product?.startDate &&
    todayDate <= product?.endDate
  ) {
    showSellPrice = true
  }
  if (product?.sellingPrice) {
    offerPrice = product?.sellingPrice
  }
  if (product?.discountType === '0' || product?.discountType === '1') {
    discountType = product?.discountType
  }

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, '')
    setQuantityCount(value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
  }

  const onClickArtDesign = (vari, locationName) => {
    setTemp(temp + 1)
    let getArt = artworkListByIdFinal.filter(t => t.design.variation.filter(y => y.design_variation_unique_id == vari.design_variation_unique_id).length > 0);
    if(getArt?.length === 0) {
      getArt = artworkList.filter(t => t.design.variation.filter(y => y.design_variation_unique_id == vari.design_variation_unique_id).length > 0);
    }

    if (getArt.length > 0) {
      let locationData = getArt[0].productToDecorationMapping.filter(val => val.locationName === locationName)
      if(locationData.length === 0)
        locationData = decorationLocations?.filter((locationArray) => locationArray.locationName === locationName)

      if (locationData.length > 0) {
        let decoData = {
          artworkID: getArt[0].id,
          location: locationData[0].locationName,
          locationId: locationData[0].location,
          variationUniqID: vari.design_variation_unique_id
        }
        setSingleSelectedDecorations(decoData)

        let getIndex = product.LogoInfo.filter(
          (val) =>
            val.color === selectedColor &&
            val.location === locationData[0].locationName,
        )

        if (getIndex.length > 0) {
          setSelectedImage(getIndex[0]?.image)
        } else {

          const getIndex = product.LogoInfo.filter((element) => element.color === selectedColor && element.extra_logo.some((subElement) => subElement.location === locationData[0].locationName));

          if (getIndex.length > 0) {
            setSelectedImage(getIndex[0]?.image)

          } else {
            const getIndex = product.LogoInfo.filter(
              (val) =>
                val.color === selectedColor
            )

            if (getIndex.length > 0) {
              setSelectedImage(getIndex[0]?.image)
            }
          }
        }
        tempfun()
      }

    }
    setTemp(temp + 0)
  }
  const [tempValue, setTempValue] = useState(0)
  const tempfun = () => {
    setTempValue(tempValue + 1)
  }
  useEffect(() => {
    if(artworkListById?.length > 0) {
      setArtworkListByIdFinal(artworkListById)
    }
  }, [artworkListById])

  const showPopupText = (mainArraykey, itemsRows, index) => {
		if(product.restrictAfterFirstChoice) {
		  setIndexPopup(index)
		  setMainArrayKeyPopup(mainArraykey)
		  setItemsRowsPopup(itemsRows)
		  setShowDecoPopup(true);
		} else {
		  removeDeco(mainArraykey, itemsRows, index);
		}
  }

  const removeDeco = (mainArraykey, itemsRows, index) => {
		if(index !== '' && index !== null) {
			let tempDecoArray = selectedProductDecorations;
			tempDecoArray = tempDecoArray.filter((decoArray, keyIndex) => keyIndex !== index,);
			setSelectedProductDecorations(tempDecoArray);
		} else {
			setSelectedProductDecorations([]);
		}
	}

  return (
    <Fragment>
      <Modal
        show={props.show}
        onHide={props.onHide}
        className={`product-quickview-modal-wrapper${galleryType === 'productCondensed' ? ' productCondensedModal' : ''}`}
      >
        <Modal.Header closeButton></Modal.Header>
        {loading && <Loader />}
        <div className="modal-body">
          {(galleryType !== 'productPortal' && galleryType !== 'productCondensed' && galleryType !== null && galleryType !== 'productPromotional' && galleryType !== 'stepper' && galleryType !== 'decoration-expanded') && (
            <div className="row">
              <div className={`col-md-6 col-sm-12 col-xs-12 ${galleryType}`}>
                {galleryType === 'leftThumb' ? (
                  <ProductImageGallerySideThumbPopup
                    product={product}
                    setSelectedColor={setSelectedColor}
                    selectedColor={selectedColor}
                    artworkListById={artworkListById}
                    setSelectedImage={setSelectedImage}
                    artworkList={artworkList}
                    singleSelectedDecorations = {singleSelectedDecorations}
                    selectedDecorations={selectedProductDecorations}
                    thumbPosition="left"
                    selectedImage = {selectedImage}
                  />
                ) : galleryType === 'rightThumb' ? (
                  <ProductImageGallerySideThumbPopup
                    product={product}
                    setSelectedColor={setSelectedColor}
                    selectedColor={selectedColor}
                    artworkListById={artworkListById}
                    setSelectedImage={setSelectedImage}
                    artworkList={artworkList}
                    singleSelectedDecorations = {singleSelectedDecorations}
                    selectedDecorations={selectedProductDecorations}
                    selectedImage = {selectedImage}
                  />
                ) : galleryType === 'fixedImage' ? (
                  <ProductImageFixedPopup
                    product={product}
                    setSelectedColor={setSelectedColor}
                    selectedColor={selectedColor}
                    setSelectedImage={setSelectedImage}
                    singleSelectedDecorations = {singleSelectedDecorations}
                    artworkListById={artworkListById}
                    artworkList={artworkList}
                    selectedDecorations={selectedProductDecorations}
                    selectedImage = {selectedImage}
                  />
                ) : galleryType === 'productSticky' ? (
                  <ProductImageGalleryStickyPopup
                    product={product}
                    setSelectedColor={setSelectedColor}
                    selectedColor={selectedColor}
                    artworkListById={artworkListById}
                    setSelectedImage={setSelectedImage}
                    artworkList={artworkList}
                    selectedDecorations={selectedProductDecorations}
                    singleSelectedDecorations = {singleSelectedDecorations}
                    selectedImage = {selectedImage}
                  />
                ) : galleryType === 'productSlider' ? (
                  <ProductImageGallerySliderPopup
                    product={product}
                    setSelectedImage={setSelectedImage}
                    setSelectedColor={setSelectedColor}
                    selectedColor={selectedColor}
                    artworkListById={artworkListById}
                    artworkList={artworkList}
                    singleSelectedDecorations = {singleSelectedDecorations}
                    selectedDecorations={selectedProductDecorations}
                    selectedImage = {selectedImage}
                  />
                ) : (
                  <ProductImageGalleryPopup
                    product={product}
                    setSelectedColor={setSelectedColor}
                    selectedColor={selectedColor}
                    setSelectedImage={setSelectedImage}
                    singleSelectedDecorations = {singleSelectedDecorations}
                    artworkListById={artworkListById}
                    artworkList={artworkList}
                    selectedDecorations={selectedProductDecorations}
                    selectedImage = {selectedImage}
                  />
                )}
              </div>
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="product-details-content quickview-content">
                  <h2 className={'mb-2'}>{product.productName}</h2>
                  <p>
                    <span className="boldcolor">Item Number:</span>{' '}
                    {productFiels
                      ? product[productFiels]
                      : product['productId']}
                  </p>
                  <ProductPrices
                    priceDisplay={priceDisplay}
                    discountedprice={discountedprice}
                    finaldiscountedprice={finaldiscountedprice}
                    finalproductprice={Number(finalproductprice)}
                    currency={currency}
                    offerPrice={offerPrice}
                    showSellPrice={showSellPrice}
                    discountType={discountType}
                    userGroupDiscount={userGroupDiscount}
                    disIndProductPrice={disIndProductPrice}
                    usePointsAsCurrency={usePointsAsCurrency}
                    product={product}
                  />
                  {product?.rating > 0 ? (
                    <div className="pro-details-rating-wrap">
                      <div className="pro-details-rating">
                        <Rating ratingValue={product.rating} />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {productSettingShowDescription === true && ( 
                  <div className="pro-details-list">
                    <h6
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    />
                  </div>
                  )}
                  <div className="pro-details-size-color">
                    {showColor && (
                      <div className="pro-details-color-wrap">
                        <span>{colorLabel}</span>
                        <div className="pro-details-color-content">
                          {colorselectbox()}
                        </div>
                      </div>
                    )}
                    {showSize && (
                      <div className="pro-details-size">
                        <span>{sizeLabel}</span>
                        <div className="pro-details-size-content">
                          {sizeselectbox()}
                        </div>
                      </div>
                    )}
                  </div>
                  {selectedProductDecorations?.length > 0 && (
                    <div className="mt-2">
                      <span>
                        <b>Selected Decorations</b>
                      </span>
                      <div className="col-md-12" style={{ display: 'flex' }}>
                        <div className="row pl-2">
                        {selectedProductDecorations &&
                            selectedProductDecorations.map((decoitem, index) => {

                        let artworkSelected = null
                        if (decoitem.artworkID && (artworkListById?.length || artworkList?.length)) {
                          artworkSelected = artworkListById.filter(
                            (artorkArray) => artorkArray.id === decoitem.artworkID,
                                  )[0]
                                  if(!!!artworkSelected) {
                                    artworkSelected = artworkList.filter(
                                      (artorkArray) => artorkArray.id === decoitem.artworkID,
                                            )[0]
                                  }
                                }
                                let variations = null
                                if (!artworkSelected) {
                                  return ''
                                } else {
                                  variations = artworkSelected.design.variation.filter(
                                    (variationArray) =>
                                      variationArray.design_variation_unique_id ==
                                      decoitem.variationUniqID,
                                  )

                          let label = decoTypeList.filter(
                            (val) => val.code == artworkSelected?.design?.designType,
                          )
                          if (variations)
                                    return variations.map((vari, indexi) => {

                                      return (
                                        <div
                                          className="decorows inlinefexDecorationDiv"
                                          key={'idx' + indexi}
                              onClick={() => {
                                onClickArtDesign(vari, decoitem.location, label[0]?.name)
                              }}
                                        >
                                          <img
                                            className="default-img1"
                                            style={{ maxHeight: '60px', maxWidth: '60px' }}
                                            src={
                                              vari?.itemImageThumbnail?.[0] != ''
                                                ? replaceOrigin(vari.itemImageThumbnail)
                                                : ''
                                            }

                                          />
                                          <div>
                                            <div className="col-md-12 text-left">
                                              <p>
                                    <b>{artworkSelected?.identity}</b>
                                              </p>
                                              <span style={{whiteSpace:"nowrap"}}>{decoitem.location}</span>
                                              {(!decoitem?.isRequiredForStore || decoitem?.isRequiredForStore == "0") &&
                                                <DeleteIcon
                                                  className="float-right"
                                                  onClick={() => {
                                                    showPopupText([], [], index)
                                                  }}
                                                />
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })
                                }
                              })}
                        </div>
                      </div>
                    </div>
                  )}
                  {product.variation ? (
                    <div className="pro-details-size-color">
                      <div className="pro-details-color-wrap">
                        <span>{colorLabel}</span>
                        <div className="pro-details-color-content">
                          {product.variation.map((single, key) => {
                            return (
                              <label
                                className={`pro-details-color-content--single ${single.color}`}
                                key={key}
                              >
                                <input
                                  type="radio"
                                  value={single.color}
                                  name="product-color"
                                  checked={
                                    single.color === selectedProductColor
                                      ? 'checked'
                                      : ''
                                  }
                                  onChange={() => {
                                    setSelectedProductColor(single.color)
                                    setSelectedProductSize(single.size[0].name)
                                    setProductStock(single.size[0].stock)
                                    setQuantityCount(1)
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            )
                          })}
                        </div>
                      </div>
                      <div className="pro-details-size">
                        <span>{sizeLabel}</span>
                        <div className="pro-details-size-content">
                          {product?.variation?.map((single) => {
                            return single.color === selectedProductColor
                              ? single.size.map((singleSize, key) => {
                                  return (
                                    <label
                                      className={`pro-details-size-content--single`}
                                      key={key}
                                    >
                                      <input
                                        type="radio"
                                        value={singleSize.name}
                                        checked={
                                          singleSize.name ===
                                          selectedProductSize
                                            ? 'checked'
                                            : ''
                                        }
                                        onChange={() => {
                                          setSelectedProductSize(
                                            singleSize.name,
                                          )
                                          setProductStock(singleSize.stock)
                                          setQuantityCount(1)
                                        }}
                                      />
                                      <span className="size-name">
                                        {singleSize.name}
                                      </span>
                                    </label>
                                  )
                                })
                              : ''
                          })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="col-md-12">
                    {getSettingStatus('showDecoration') && !addDecorations && (
                      <Button
                        variant="contained"
                        className="mt-2 mr-2"
                        onClick={() => {
                          setAddDecorations(true)
                        }}
                      >
                        Add Decorations
                      </Button>
                    )}
                    {addDecorations && getDecoHtml()}
                    {addDecorations && userType && (userType === USERTYPES.ADMIN || userType === USERTYPES.USER)  && allowCustomDecoration ? <div>
                        <div className="col-md AddNewart ml-3 mt-2">
                            <Button variant="outlined" color="primary" onClick={() => { setAddnewart && setAddnewart(true) }}>Add New Art</Button>
                        </div>
                    </div> : ''}
                  </div>
                  {getSettingStatus('showPersonalization') &&
                    !personalizationArray && (
                      <Button
                        variant="contained"
                        className="mt-2"
                        onClick={() => {
                          addPersonalization()
                        }}
                      >
                        Add { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' }
                      </Button>
                    )}
                  {personalizationArray && (
                    <Button
                      variant="contained"
                      className="mt-2"
                      onClick={() => {
                        DeletePersonalization()
                      }}
                    >
                      Delete { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' }
                    </Button>
                  )}
                  {personalizationArray && (
                    <div style={{ overflow: 'scroll' }} className="mt-2">
                      {getPesonalizationTable()}
                    </div>
                  )}
                  {(isSkuMoq || isMoq) && showMoqNote && (
                    <p style={{color: 'red'}}>{moqNote}</p>
                  )}
                   {(product && priceTables.length > 0 && !!product?.displayPriceGrid) &&
                                        <PrintablePriceTable
                                            priceTable={priceTables}
                                            product={product}
                                            currency={currency}
                                            isModel = {true}
                                            decimalsToShow={decimalsToShow}
                                            doNotCalculateAtOrder={product?.doNotCalculateAtOrder}
                                        />}
                  {product.affiliateLink ? (
                    <div className="pro-details-quality">
                      <div className="pro-details-cart">
                        <a
                          href={product.affiliateLink}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Buy Now
                        </a>
                      </div>
                    </div>
                  ) : (
                    <>
                      {isShowInventoryEnabled && isSizeValid() && isColorValid() && (
                        <div className="col-md-12 mt-3">
                          <label>
                            {inventorySettings?.['inventoryLabel']
                              ? inventorySettings['inventoryLabel']
                              : 'Available'}{' '}
                            {getProductStock()}
                          </label>
                        </div>
                      )}
                      <div className="pro-details-quality">
                        <div className="cart-plus-minus">
                        {qtyDropDown ? <select name="qty-drop-down" id="qty-drop-down"
                            className="cart-plus-minus-box cart-plus-minus"
                            style={ {
                                borderWidth: '1px',
                                padding: '5px',
                                textAlign: "center"
                            } }
                            onChange={ (e) => setQuantityCount(e.target.value) }
                        >
                            <option value="0">QTY</option>
                            {
                                qtyValues.map((o, index)=>{
                                    return (
                                        <option value={ o.toString() }>{ o }</option>
                                    )
                                })
                            }
                        </select> : <>
                        <button
                            onClick={() => {
                              let num =
                                Number(
                                  quantityCount.toString().replace(/,/g, ''),
                                ) > 1
                                  ? Number(
                                      quantityCount
                                        .toString()
                                        .replace(/,/g, ''),
                                    ) - 1
                                  : 1
                              setQuantityCount(
                                num
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                              )
                            }}
                            className="dec qtybutton"
                          >
                            -
                          </button>
                          <input
                            className="cart-plus-minus-box"
                            type="text"
                            value={quantityCount}
                            onChange={handleChange}
                            maxLength={6}
                          />
                          <button
                            onClick={() => {
                              if (IsAllowOutOfStockOrder) {
                                let num =
                                  Number(
                                    quantityCount.toString().replace(/,/g, ''),
                                  ) + 1
                                setQuantityCount(
                                  num
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                )
                              } else {
                                // if (product?.poType === "Stock") {
                                let num1 =
                                  Number(
                                    quantityCount.toString().replace(/,/g, ''),
                                  ) <
                                  getProductStock() - productCartQty
                                    ? Number(
                                        quantityCount
                                          .toString()
                                          .replace(/,/g, ''),
                                      ) + 1
                                    : Number(
                                        quantityCount
                                          .toString()
                                          .replace(/,/g, ''),
                                      )
                                setQuantityCount(
                                  num1
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                )
                              }
                            }}
                            className="inc qtybutton"
                          >
                            +
                          </button>
                          </>
                        }
                        </div>
                        <div className="pro-details-cart">
                          {((showAddToCartOnProd && getSettingStatusAdd('showAddToCart') !== false) || getSettingStatusAdd('showAddToCart') !== false) && (
                              <>
                                {getProductStock() > 0 ||
                                  IsAllowOutOfStockOrder || !selectedProductSize || selectedProductSize === `Select ${sizeLabel}`
                                  || !selectedProductColor || selectedProductColor === `Select ${colorLabel}` ? (
                                  <Link
                                    to={{
                                      state: {
                                        redirectUrl: window.location.pathname,
                                      },
                                    }}
                                    onClick={() => {
                                      if (checkMinQty(prodId, quantityCount, isRestrictQty, minAllQty, isMoq, isSkuMoq, minQty, {color: selectedProductColor, size: selectedProductSize}, product?.doNotCalculateAtOrder)) {
                                        if(showSize) {
                                          if(!selectedProductSize || selectedProductSize === `Select ${sizeLabel}`){
                                            orderAction(`You must select a ${sizeLabel}`, "error");
                                            return;
                                          }
                                        }
                                        if(showColor) {
                                          if(!selectedProductColor || selectedProductColor === `Select ${colorLabel}`){
                                            orderAction(`You must select a ${colorLabel}`, "error");
                                            return;
                                          }
                                        }
                                        addToCart(
                                          product,
                                          addToast,
                                          quantityCount,
                                          selectedProductColor,
                                          selectedProductSize,
                                          selectedProductDecorations?.length > 0
                                            ? selectedProductDecorations
                                            : null,
                                          personalizationArray,
                                          '',
                                          userGroupDiscount,
										                      isPriceBreak,
                                          false,
                                          ipV4,
                                        )
                                        goToCart()
                                      }
                                  }}
                                  disabled={getProductStock() === 0}
                                >
                                  {' '}
                                  Add To Cart{' '}
                                </Link>
                              ) : (
                                <button disabled>Out of Stock</button>
                              )}
                            </>
                            )}
                        </div>
                        {showWishListToggle && (
                          <div className="pro-details-wishlist">
                            <button
                              className={
                                wishlistItem !== undefined ? 'active' : ''
                              }
                              disabled={wishlistItem !== undefined}
                              title={
                                wishlistItem !== undefined
                                  ? 'Added to wishlist'
                                  : 'Add to wishlist'
                              }
                              onClick={() => addToWishlist(product, addToast)}
                            >
                              <i className="pe-7s-like" />
                            </button>
                          </div>
                        )}
                        {showCompareToggle && (
                          <div className="pro-details-compare">
                            <button
                              className={
                                compareItem !== undefined ? 'active' : ''
                              }
                              disabled={compareItem !== undefined}
                              title={
                                compareItem !== undefined
                                  ? 'Added to compare'
                                  : 'Add to compare'
                              }
                              onClick={() => addToCompare(product, addToast)}
                            >
                              <i className="pe-7s-shuffle" />
                            </button>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {galleryType === 'productPortal' && (
                  <ProductImageswipeSliderSmall
                    getGallerySwiper={getGallerySwiper}
                    getThumbnailSwiper={getThumbnailSwiper}
                    thumbnailSwiper={thumbnailSwiper}
                    gallerySwiper={gallerySwiper}
                    setSelectedColor={setSelectedColor}
                    setSelectedProductColor={setSelectedProductColor}
                    selectedColor={selectedColor}
                    product={product}
                  />
                )}
              </div>
            </div>
          )}
          {galleryType === 'productPortal' && (
            <div className="modalportal">
              <PortalImageDescription product={product} isModel={true}/>
            </div>
          )}
          {galleryType === 'productCondensed' && (
            <div className="modalportal">
              <PortalImageCondensed product={product} spaceTopClass="pt-100" spaceBottomClass="pb-100" isModel = {true}/>
            </div>
          )}
          {galleryType === 'productPromotional' && (
            <div className="modalportal">
              <ProductImageDescriptionPromotional product={product} spaceTopClass="pt-100" spaceBottomClass="pb-100" isModel = {true}/>
            </div>
          )}
          {galleryType === 'stepper' && (
            <div className="modalportal">
              <ProductAttributeImageDescription product={product} productSettings = {productSettings} userGroupDiscount={userGroupDiscount} isModel = {true}/>
            </div>
          )}
          {galleryType === 'decoration-expanded' ? 
            <div className="modalportal">
              <ProductHatsImageDescription product={product} productSettings = {productSettings} userGroupDiscount={userGroupDiscount} isModel = {true}/>
            </div> : ''
          }
        </div>
        {showDecoPopup && (
          <SharedModal 
            indexPopup={indexPopup}
            mainArrayKeyPopup={mainArrayKeyPopup}
            itemsRowsPopup={itemsRowsPopup}
            popupText={popupText}
            removeDeco={removeDeco}
            setShowDecoPopup={setShowDecoPopup}
            showDecoPopup={showDecoPopup}
          />
        )}
      </Modal>
    </Fragment>
  )
}

ProductModal.propTypes = {
  addtoast: PropTypes.func,
  addtocart: PropTypes.func,
  addtocompare: PropTypes.func,
  addtowishlist: PropTypes.func,
  cartitems: PropTypes.array,
  compareitem: PropTypes.object,
  currency: PropTypes.object,
  discountedprice: PropTypes.number,
  finaldiscountedprice: PropTypes.number,
  finalproductprice: PropTypes.any,
  onHide: PropTypes.func,
  product: PropTypes.object,
  show: PropTypes.bool,
  wishlistitem: PropTypes.object
};

const mapDispatchToProps = dispatch => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
      selectedProductColor,
      selectedProductSize,
      selectedProductDecorations,
      personalizationArray,
      personalizationCost,
      userGroupDiscount,
      isPriceBreak,
      ipV4
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
          selectedProductColor,
          selectedProductSize,
          selectedProductDecorations,
          personalizationArray,
          personalizationCost,
          userGroupDiscount,
		      isPriceBreak,
          false,
          ipV4,
        )
      );
    },
    addToWishlist: (item, addToast) => {
      dispatch(addToWishlist(item, addToast));
    },
    addToCompare: (item, addToast) => {
      dispatch(addToCompare(item, addToast));
    },
    fetchArtworksById: (data) => {
      dispatch(fetchArtworksById(data))
    },
    fetchDecorationLocations: (data) => dispatch(fetchDecorationLocations(data)),
  };
};
const mapStateToProps = state => {
  return {
    decorationLocations: state.productData.decorationLocations,
    artworkList: state.productData.artworkList,
    artworkListById: state.productData.artworkListById,
    cartitems: state.cartData
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductModal);
