import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import { connect } from "react-redux";
import Loader from "../../common/Loader"
import { Modal } from "react-bootstrap";
import {
  uploadArtwork,
  createArtwork,
  createCustomArtwork
} from "../../util/order/ordersAPIUtil";
import {
  getDecoratorTypes,
  fetchDecorationLocations,
  getDecoratorVendors
} from "../../redux/actions/productActions";
import { useToasts } from "react-toast-notifications";
import { AuthContext } from "../../common/Auth"
import { useProductArtwork } from "./state/useProductArtwork";


const AddnewArtDialogue = ({
  open,
  decoratorsTypeList,
  title,
  handleSubmit,
  cancel,
  show,
  onHide,
  getDecoratorVendors,
  decoratorsVendorsList,
  getDecoratorTypes,
  catalogueItem,
  decorationLocations,
  productId,
  showAddnewartId
}) => {
  const { storeDetails, user, themeProductSettingsData } = useContext(AuthContext)
  const [productSettings, setProductSettings] = useState(null)
  const [customArtworkLocation, setCustomArtworkLocation] = useState([])

  useEffect(() => {
    if (open) {
      getDecoratorTypes()
      getDecoratorVendors()
    }
  }, [])

  const {
    saveArtwork,
  } = useProductArtwork({
    productSettings,
    decoratorsVendorsList,
    productId
  })

  useEffect(() => {
		if (themeProductSettingsData.length > 0) {
			themeProductSettingsData &&
				themeProductSettingsData.map((i) => {
					if (i.settingsName === 'productpageSettings') {
						if (i?.values?.[0]) {
							setProductSettings(i.values[0])
						}
					}
				})
		}
	}, [themeProductSettingsData])

  const [notes, setNotes] = useState('')
  const [decoRatorsType, setDecoRatorsType] = useState('')
  const [validateErrors, setErrors] = useState([])
  const [selectedFile, setFile] = useState(null)
  const [decoLocation, setDecoLocation] = useState('')
  const [artName, setArtName] = useState('')
  const [loader, setLoader] = useState(false)
  const { addToast } = useToasts()
  const handleClose = () => {
    cancel()
  }

  useEffect(() => {
    if (catalogueItem && catalogueItem?.customArtworkLocation?.length > 0) {
      setCustomArtworkLocation(catalogueItem?.customArtworkLocation)
      //this code for signle location is selected
      if (catalogueItem?.customArtworkLocation && catalogueItem?.customArtworkLocation.length === 1) {
        setDecoLocation(catalogueItem?.customArtworkLocation?.[0]?.locationName)
      }
      if (catalogueItem?.customArtworkType && catalogueItem?.customArtworkType.length === 1) {
        setDecoRatorsType(catalogueItem?.customArtworkType?.[0]?.id)
      }
    }
  }, [catalogueItem])

  useEffect(() => {
    if(!!productSettings?.prefixOnCustomArtwork) {
     let prefixOnCustomArtwork = document.getElementsByClassName("prefixOnCustomArtwork")
     let artWorkText = document.getElementsByClassName("artNameText")
      if(!!prefixOnCustomArtwork && !!prefixOnCustomArtwork?.[0]) {
        let widthOf  = prefixOnCustomArtwork[0].offsetWidth
        if(!!artWorkText && !!artWorkText?.[0])
          artWorkText[0].style.paddingLeft = `${Number(widthOf - 10)}px`
      }
    }
  },[productSettings])

  const validateData = () => {
    let error = []
    if (artName == '') {
      error.push('artName')
    }

    if (decoRatorsType == '') {
      error.push('decoRatorsType')
    }

    if (decoLocation == '') {
      error.push('decoLocation')
    }
    if (selectedFile == null) {
      error.push('selectedFile')
    }
    setErrors(error)
    if (error.length) {
      return false
    }
    return true
  }
  const saveArt = () => {
    if (validateData()) {
      setLoader(true)
      let designArray = decoratorsTypeList.filter(
        (design) => design.id === decoRatorsType,
      )[0]
      saveArtwork(selectedFile, artName, notes, designArray, decoLocation, decoRatorsType, 0).then(data => {
        handleSubmit({ newArtData: data, location: decoLocation, showAddnewartId: showAddnewartId }) 
      }).catch(error => {
        console.log('error', error)
        addToast('Artwork is not added! please try again', {
          appearance: 'error',
          autoDismiss: true,
        })
      }).finally(() => setLoader(false))
    }
  }
  const uploadArtworkFile = (e) => {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    const allowedFileTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
    if (files[0]) {
      if (!allowedFileTypes.includes(files[0]?.type)) {
        addToast('That File format cannot be uploaded as a custom artwork. Please upload an image for custom artwork.', {
          appearance: 'error',
          autoDismiss: true,
        })
        return;
      }
  
      setLoader(true)
      uploadArtwork({ File: files[0] })
        .then((res) => {
          setLoader(false)
          if (res.data && res.data.url) {
            const UrlData = res.data
            if (UrlData.url) {
              setFile(UrlData.url)
            }
          }
        })
        .catch((error) => {
          addToast('File is not uploaded! try again', {
            appearance: 'error',
            autoDismiss: true,
          })
          setLoader(false)
        })
    }
  }

  let decoExistLocations = []
  if (
    catalogueItem &&
    catalogueItem.LocationArray &&
    catalogueItem.LocationArray.Location
  ) {
    catalogueItem.LocationArray.Location.map((location) => {
      if (location.locationName && location.locationName != '')
        decoExistLocations.push(location.locationName)
    })
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="product-quickview-modal-wrapper"
    >
      <Modal.Header closeButton>{title}</Modal.Header>
      <div className="modal-body">
        {loader && <Loader />}
        <div className="addNewartDiv">
          <div className="row rowBorder">
            <div className="col-md-3">
              <label className="font-weight-bold">Artwork Name*</label>
            </div>
            <div className="col-md-6" style={{display: !!productSettings?.prefixOnCustomArtwork ? 'block' : '', position: !!productSettings?.prefixOnCustomArtwork ? 'relative' : ''}}>
             <div style={{position:"relative"}}> {!!productSettings?.prefixOnCustomArtwork ? <p className='prefixOnCustomArtwork' style={{position: "absolute", left: '0', top: '50%', transform: 'translate(0%,-50%)', marginBottom : '0px', padding : '0 10px'}}>{`${productSettings?.prefixOnCustomArtwork}- `}</p> : ''}
              <input
                type="text"
                className="inputStyle artNameText"
                value={artName}
                onChange={(e) => setArtName(e.target.value)}
              />
             </div>
              {validateErrors.indexOf('artName') !== -1 && (
                <p className="error" style={{marginTop:"10px"}}>Please enter artwork name.</p>
              )}
            </div>
          </div>
          <div className="row rowBorder">
            <div className="col-md-3">
              <label className="font-weight-bold mt-12">
                Select Decoration Location*
              </label>
            </div>
            <div className="col-md-6">
              {
                customArtworkLocation && customArtworkLocation.length === 1 ?

                  <div className='selectOption d-flex align-items-center' style={{paddingLeft:12}}>
                    {customArtworkLocation?.[0]?.locationName}
                  </div> :

                  <>
                    <select
                      className="selectOption"
                      value={decoLocation}
                      onChange={(e) => {
                        setDecoLocation(e.target.value)
                      }}
                    >
                      <option value="">Select</option>
                      {customArtworkLocation &&
                        customArtworkLocation.map((locationArray, key) => {
                          if (
                            decoExistLocations.indexOf(locationArray.locationName) !==
                            -1
                          ) {
                            return (
                              <option key={key} value={locationArray.locationName}>
                                {locationArray.locationName}
                              </option>
                            )
                          } else {
                            return false
                          }
                        })}
                    </select>
                    {validateErrors.indexOf('decoLocation') !== -1 && (
                      <p className="error">Please select decorator location.</p>
                    )}</>
              }
            </div>
          </div>
          <div className="row rowBorder">
            <div className="col-md-3">
              <label className="font-weight-bold mt-12">
                Select Decoration type*
              </label>
            </div>
            <div className="col-md-6">
              {
                catalogueItem && catalogueItem?.customArtworkType && catalogueItem?.customArtworkType.length === 1 ?
                  <div className='selectOption d-flex align-items-center' style={{paddingLeft:12}}>
                    {catalogueItem?.customArtworkType[0].name}
                  </div> : <>
                    <select
                      className="selectOption"
                      value={decoRatorsType}
                      onChange={(e) => {
                        setDecoRatorsType(e.target.value)
                      }}
                    >
                      <option value="">Select</option>
                      {catalogueItem &&
                        catalogueItem?.customArtworkType &&
                        catalogueItem?.customArtworkType.map(
                          (decoratorsArray, key) => {
                            return (
                              <option key={key} value={decoratorsArray.id}>
                                {decoratorsArray.name}
                              </option>
                            )
                          },
                        )}
                    </select>
                    {validateErrors.indexOf('decoRatorsType') !== -1 && (
                      <p className="error">Please select decorator type.</p>
                    )}</>
              }
            </div>
          </div>
          <div className="row rowBorder">
            <div className="col-md-3">
              <label className="font-weight-bold">Note</label>
            </div>
            <div className="col-md-6">
              <textarea
                value={notes}
                className="inputStyle"
                onChange={(e) => {
                  setNotes(e.target.value)
                }}
              ></textarea>
            </div>
          </div>
          <div className="row rowBorder">
            <div className="col-md-3">
              <label className="font-weight-bold">Choose File*</label>
            </div>
            <div className="col-md-6">
              <input
                type="file"
                name="artwork"
                accept=".jpg, .jpeg, .png, .pdf"
                onChange={(e) => uploadArtworkFile(e)}
              />
              {validateErrors.indexOf('selectedFile') !== -1 && (
                <p className="error">Please select file.</p>
              )}
            </div>
          </div>
          <div className="text-center pb-3 pt-3">
            <Button
              variant="contained"
              color={'primary'}
              disabled={false}
              onClick={(e) => saveArt()}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className="ml-2"
              onClick={() => {
                handleClose()
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
const mapDispatchToProps = dispatch => {
  return {
    getDecoratorVendors: () => dispatch(getDecoratorVendors()),
    fetchDecorationLocations: (data) => dispatch(fetchDecorationLocations(data)),
    getDecoratorTypes: (data) => dispatch(getDecoratorTypes(data)),
  };
};
const mapStateToProps = state => {
  return {
    decorationLocations: state.productData.decorationLocations,
    decoratorsVendorsList: state.productData.decoratorsVendorsList,
    decoratorsTypeList: state.productData.decoratorsTypeList,
    artworkList: state.productData.artworkList
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddnewArtDialogue);
