import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import useElementSize from "../../util/custom-hooks/useElementSize";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import { productImageExt, productImageUrl } from "../../util/helper";
import {AuthContext} from '../../common/Auth'

const ProductImageFixed = ({ product,
  selectedDecorations,
  artworkList, artworkListById, selectedColor, setSelectedColor, selectedImage, singleSelectedDecorations, setSelectedThumbImgColor, selectedThumbImgColor
}) => {

  const [imgW, imgH] = useElementSize(
    '.product-fixed-image .containerimg img',
  )
  const [imageW, setImageW] = useState(imgW)
  const [imageH, setImageH] = useState(imgH)
  const [DefaultProductBackgroundColor, setDefaultProductBackgroundColor] = useState()

	const {
		settingData
	  } = useContext(AuthContext)

	useEffect(() => {
		if (settingData?.length > 0) {
		  settingData.map((i) => {
			if (i.page === 'General') {
			  if (!!i?.values?.DefaultProductBackgroundColor) {
				setDefaultProductBackgroundColor(i?.values?.DefaultProductBackgroundColor);
			  }
			}
		  })
		}
	}, [settingData])

  useEffect(() => {
		setTimeout(() => {
		  const element = document.querySelectorAll('.product-fixed-image .containerimg img');
		  setImageW(element[0]?.offsetWidth)
		  setImageH(element[0]?.offsetHeight)
		}, 1000);
	  },[selectedDecorations]);

	useEffect(() => {
		setTimeout(() => {
			const element = document.querySelectorAll('.product-fixed-image .containerimg img');
			setImageW(element[0]?.offsetWidth)
			setImageH(element[0]?.offsetHeight)
		}, 500);
	},[imgW, imgH]);

  let image = null;
  let defaultColor = ""
  let temp = product?.WebstoreProductPartArray.filter((val) => val.image===selectedImage && val?.isSwapImage,)
		 if (temp.length > 0) {
		   if (product?.WebstoreProductPartArray) {
			   product.WebstoreProductPartArray.map((single, index) => {
				if (image == null && selectedImage === single.image) {
					image = single.image
				  }
			   })
		   }
		 }else{
			product.MediaContent.map(mediaArray => {
				if (image == null && selectedImage === mediaArray.url) {
				  image = mediaArray.url
          if(mediaArray?.isNewLogo == "1" && !!mediaArray?.processedImage) {
            image = mediaArray.processedImage
          }
				}
			  })
		 }

  
  if (image == null || image == "") {
    if (product.MediaContent && product.MediaContent.length > 0) {
      image = product.MediaContent[0].url
      defaultColor = product.MediaContent[0].color
    }
  }
  if (selectedColor == "" || selectedColor == null) {
    selectedColor = defaultColor;
  }
  const getReducePer = (width, param) => {
    let reducePer = 100;
    while (((Number(width) * reducePer) / 100) > param) {
      reducePer = reducePer - 1;
    }
    return reducePer;
  }

  useEffect(() => {
		setTimeout(() => {
			const element = document.querySelectorAll('.product-fixed-image .containerimg img');
			if(element.length > 0) {
				setImageW(element[0]?.offsetWidth)
				if(element[0]?.offsetWidth <= 451 && element[0]?.offsetWidth > 0) {
					const element1 = document.querySelectorAll('.product-fixed-image .containerimg');
					if(element1.length > 0) {
						element1[0].style.width = `${element[0]?.offsetWidth}px`
					}
				}
			}
		}, 100);
	},[singleSelectedDecorations]);

  let singlelogoDetails = {}
  let findlogoinfo = ''
  
  if (singleSelectedDecorations != "" && singleSelectedDecorations != null && singleSelectedDecorations != "null" && singleSelectedDecorations != undefined) {
    for (let i = 0; i < product?.LogoInfo.length; i++) {
      const logoInfoData = product?.LogoInfo[i]
      if (logoInfoData.color === selectedColor) {
        if (logoInfoData.image === selectedImage && logoInfoData.location == singleSelectedDecorations.location) {
          findlogoinfo = logoInfoData
          break;
        } else if (logoInfoData.location == singleSelectedDecorations.location) {
          findlogoinfo = logoInfoData
          break;
        } else if (logoInfoData.image === selectedImage) {
          let extra_logo = logoInfoData.extra_logo.filter(val => val.location == singleSelectedDecorations.location)

          if (extra_logo.length > 0) {

            findlogoinfo = extra_logo
            findlogoinfo[0].image = logoInfoData.image
            findlogoinfo[0].color = logoInfoData.color
            findlogoinfo[0].ih = logoInfoData.ih
            findlogoinfo[0].iw = logoInfoData.iw
            findlogoinfo[0].location = extra_logo[0].location
            findlogoinfo[0].h = extra_logo[0].h

            findlogoinfo[0].w = extra_logo[0].w
            findlogoinfo[0].x = extra_logo[0].x
            findlogoinfo[0].y = extra_logo[0].y
            if (logoInfoData?.isNewLogo == "1" && !!logoInfoData?.processedImage) {
              findlogoinfo[0].processedImageWidth = logoInfoData?.processedImageWidth
              findlogoinfo[0].processedImageHeight = logoInfoData?.processedImageHeight
              findlogoinfo[0].isNewLogo = logoInfoData?.isNewLogo
              findlogoinfo[0].processedImage = logoInfoData?.processedImage
            }
            break;
          }
        }
      }
    }
  }
  if (findlogoinfo !== '' && findlogoinfo?.length) {
    singlelogoDetails = findlogoinfo[0]
  } else if (findlogoinfo !== '') {
    singlelogoDetails = findlogoinfo
  }
  let imgWidth = singlelogoDetails.iw
  let imgHeight = singlelogoDetails.ih
  let singleX = singlelogoDetails?.x
  let singleY = singlelogoDetails?.y
  let singleW = singlelogoDetails?.w
  let singleH = singlelogoDetails?.h
  if(singlelogoDetails?.isNewLogo == "1" && !!singlelogoDetails?.processedImage) {
    imgWidth = singlelogoDetails?.processedImageWidth
    imgHeight = singlelogoDetails?.processedImageHeight
    singleX = singlelogoDetails?.newX
    singleY = singlelogoDetails?.newY
    singleW = singlelogoDetails?.newW
    singleH = singlelogoDetails?.newH
  }

  if(imgWidth > 900 || imgHeight > 1100){
    var ratio = Math.min(800 / imgWidth, 800 / imgHeight);

    imgWidth = imgWidth * ratio
    imgHeight = imgHeight * ratio
  }

  let reducePer = getReducePer(imgHeight, imageH)
  // imgWidth = (imgWidth * reducePer) / 100
  // imgHeight = (imgHeight * reducePer) / 100

  //artwork box left top position set set
  let positionSetLeft = (imageW * singleX) / imgWidth
  let positionSetTop = (imageH * singleY) / imgHeight
  //artwork box width/height set
  let positionSetBoxWidth = (imageW * singleW) / imgWidth
  let positionSetBoxHeight = (imageH * singleH) / imgHeight

  const photoZoom = (zmImg='') => {
    const node = document.querySelector(".single-image");
		let zoomPictureNode = document.getElementsByClassName("lg-img-wrap")
		if(!!zoomPictureNode && !!zoomPictureNode?.[0] && !!DefaultProductBackgroundColor && zmImg !== '')
			zoomPictureNode[0].classList.add(zmImg)

      if (node) {
        const clone = node.cloneNode(true);
        zoomPictureNode[0].classList.add('lg-img-zoom')
        if (zoomPictureNode) {
          zoomPictureNode[0].appendChild(clone);
        }
        var fourChildNode = zoomPictureNode[0].querySelector('.containerimg');
        if(!!fourChildNode) {				
          fourChildNode.style.maxWidth = 'unset'
          fourChildNode.style.margin = 'unset'
          fourChildNode.style.width = 'unset'
        }
      }
  
      //get outerbox
      let getSingleImage = singlelogoDetails
      let getOuterBOx = document.querySelector('.lg-img-wrap .react_lightgallery_item')
      if(!!getOuterBOx)
      {
        let imgWidthZoom = getSingleImage?.iw
        let imgHeightZoom = getSingleImage?.ih
        let singleZoomX = getSingleImage?.x
        let singleZoomY = getSingleImage?.y
        let singleZoomW = getSingleImage?.w
        let singleZoomH = getSingleImage?.h

        if(getSingleImage?.isNewLogo == "1" && !!getSingleImage?.processedImage) {
          imgWidthZoom = getSingleImage?.processedImageWidth
          imgHeightZoom = getSingleImage?.processedImageHeight
          singleZoomX = getSingleImage?.newX
          singleZoomY = getSingleImage?.newY
          singleZoomW = getSingleImage?.newW
          singleZoomH = getSingleImage?.newH
        }
        let imgWidth = imgWidthZoom
        let imgHeight = imgHeightZoom
    
        if(imgWidth > 900 || imgHeight > 1100){
          var ratio = Math.min(800 / imgWidth, 800 / imgHeight);
      
          imgWidth = imgWidth * ratio
          imgHeight = imgHeight * ratio
        }
  
        // add left top postion to artwork
        let positionSetLeft = (getOuterBOx.offsetWidth * singleZoomX) / imgWidth
        let positionSetTop = (getOuterBOx.offsetHeight * singleZoomY) / imgHeight
        let positionSetBoxWidth = (getOuterBOx?.offsetWidth * singleZoomW) / imgWidth
        let positionSetBoxHeight = (getOuterBOx?.offsetHeight * singleZoomH) / imgHeight
    
        let overlayImag = document.querySelectorAll(".lg-img-wrap .overlayimg")
        const css = '.overlayimg:hover{  transform: scale(1.5); }';
        const style = document.createElement('style');
        if (style.sheet) {
          style.sheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }
        for (let i = 0; i < overlayImag.length; i++) {
          overlayImag[i].style.left = positionSetLeft + "px"
          overlayImag[i].style.top = positionSetTop + "px"
          overlayImag[i].style.width = positionSetBoxWidth + "px"
          overlayImag[i].style.height = positionSetBoxHeight + "px"
          overlayImag[i].style.transition = 'transform 0.3s';
          overlayImag[i].appendChild(style)
        }
    
        //add width height to artwork
    
        let overlayImag2 = document.querySelectorAll(".lg-img-wrap .overlayimg .img-fluid")
        for (let i = 0; i < overlayImag2.length; i++) {
          overlayImag2[i].style.maxWidth = positionSetBoxWidth + 'px'
          overlayImag2[i].style.maxHeight = positionSetBoxHeight + 'px'
          overlayImag2[i].style.width = '100%'
          overlayImag2[i].style.height = '100%'
          overlayImag2[i].style.objectFit = 'contain'
        }
      }
      let getOuterBOxCheck2 = document.querySelector('.lg-img-wrap .lightGal')
      if(!getOuterBOxCheck2) {
        return
      }
      let zoomInBtn = document.getElementById('lg-zoom-in');
      let zoomOutBtn = document.getElementById('lg-zoom-out');

      let scaleItem = 1

      zoomInBtn.addEventListener('click', function (event) {
        getOuterBOxCheck2.style.transform = `scale(${scaleItem + 0.5}) translateX(-50%)`
        scaleItem = scaleItem + 0.5
      });
      zoomOutBtn.addEventListener('click', function (event) {
        getOuterBOxCheck2.style.transform = `scale(${scaleItem - 0.5}) translateX(-50%)`
        scaleItem = scaleItem - 0.5
      });
	}

  return (
    <div className="product-large-image-wrapper">
      {product.discount || product.new ? (
        <div className="product-img-badges">
          {product.discount ? (
            <span className="pink">-{product.discount}%</span>
          ) : (
            ''
          )}
          {product.new ? <span className="blue">New</span> : ''}
        </div>
      ) : (
        ''
      )}

      <div className="product-fixed-image">
        <div>
          <div class={`${productImageExt(image) ? 'product-img-bg' : ''} single-image lightGal`}>
            <div className="containerimg" style={{maxWidth:'451px', margin:'0px auto'}}>
              <LightgalleryProvider onAfterOpen={() => photoZoom(productImageExt(image) ? 'product-img-bg' : '')}>
                <LightgalleryItem
                  galleryClassName=" "
                  group="any"
                  src={productImageUrl(image)}
                >
                  {image && image != '' && image != null ? (
                    <img
                      src={productImageUrl(image)}
                      alt=""
                      className="img-fluid"
                    />
                  ) : (
                    ''
                  )}
                  {singlelogoDetails && Object.keys(singlelogoDetails).length > 0 && selectedDecorations != "" && selectedDecorations != null && artworkListById?.length > 0 && selectedDecorations.length > 0 && selectedDecorations.map((li, key) => {
                    let to = ""
                    let lef = ""
                    let wid = ""
                    let hei = ""
                    if (li.location == singlelogoDetails.location) {
                      to = singlelogoDetails.y;
                      lef = singlelogoDetails.x;
                      wid = singlelogoDetails.w;
                      hei = singlelogoDetails.h;
                      if(singlelogoDetails?.isNewLogo == "1" && !!singlelogoDetails?.processedImage) {
                        lef = singlelogoDetails?.newX
                        to = singlelogoDetails?.newY
                        wid = singlelogoDetails?.newW
                        hei = singlelogoDetails?.newH
                      }
                    } else {
                      singlelogoDetails.extra_logo && singlelogoDetails.extra_logo.map(el => {
                        if (singlelogoDetails.location == el.location) {
                          to = el.y;
                          lef = el.x;
                          wid = el.w;
                          hei = el.h;
                          if(singlelogoDetails?.isNewLogo == "1" && !!singlelogoDetails?.processedImage) {
                            to = el?.newY
                            lef = el?.newX
                            wid = el?.newW
                            hei = el?.newH
                          }
                        }
                      })
                    }
                    to = ((Number(to) * reducePer) / 100).toString();
                    lef = ((Number(lef) * reducePer) / 100).toString();
                    wid = ((Number(wid) * reducePer) / 100).toString();
                    hei = ((Number(hei) * reducePer) / 100).toString();
						  let artwork_selected = null
						  if (li?.artworkID !== '' && li?.artworkID !== undefined) {
							  const idToSearch = singleSelectedDecorations ? singleSelectedDecorations.artworkID : li.artworkID
							  artwork_selected = artworkListById?.find(
								  (artworkArray) => artworkArray.id === idToSearch,
							  )
						  }

						  let rowVariation = null
						  if (artwork_selected?.design?.variation?.length > 0) {
							  rowVariation = artwork_selected.design.variation.filter(
								  (variationArray) =>
									  variationArray.design_variation_unique_id ==
									  li.variationUniqID,
							  )[0]
						  }
                    if(rowVariation) {
                      return (
                        <div
                          className="overlayimg"
                          key={key}
                          style={{
                            top: positionSetTop + 'px',
                            left: positionSetLeft + 'px',
                          }}
                        >
                          {
                          rowVariation && rowVariation?.itemImageThumbnail && rowVariation.itemImageThumbnail[0] ?
                            <img
                              className="img-fluid"
                              src={
                                rowVariation?.itemImageThumbnail
                                  ? productImageUrl(
                                    rowVariation.itemImageThumbnail[0],
                                  )
                                  : ''
                              }

                              width={wid + 'px'}
                              height={hei + 'px'}
                              // style={{ maxWidth: wid + 'px' }}
                              style={{
                                maxWidth: positionSetBoxWidth + 'px',
                                maxHeight: positionSetBoxHeight + 'px',
                              }}
                            />
                            :
                            <img className="img-fluid"
                              src={rowVariation && rowVariation?.itemImageThumbnail != "" ? productImageUrl(rowVariation) : productImageUrl(rowVariation)}

                              width={wid + 'px'}
                              height={hei + 'px'}
                              style={{
                                maxWidth: positionSetBoxWidth + 'px',
                                maxHeight: positionSetBoxHeight + 'px',
                              }}
                            />
                        }
                        </div>
                      )
                    }})}

                </LightgalleryItem>
              </LightgalleryProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

ProductImageFixed.propTypes = {
  product: PropTypes.object
};

export default ProductImageFixed;
