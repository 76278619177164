import {useContext, useEffect, useState} from "react";
import {GET_PRODUCT, X_TENANT_ID} from "../../../common/ActionUrl";
import axios from "axios";
import {AuthContext} from "../../../common/Auth";
import { useThemeSettings } from "../../../layouts/state/useThemeSettings";
import { useThemeSettingsData } from "../../../layouts/state/useThemeSettingsData";
import { useDispatch } from "react-redux";
import { isLoading, fetchProductStock} from "../../../redux/actions/productActions";
import { useProductConfigState } from "./useProductConfigState";

export const useProductDetailState = (props) => {
    const {
        tabName, 
        themeSettingsData, 
        userGroupDiscount, 
        settingData, 
        categoriesList, 
        categoriesByUserGroup, 
        loadingUserCategories,
    } = useContext(AuthContext);
    const [product, setProduct] = useState(null);
    const [isLoadingProduct, setIsLoadingProduct] = useState(false);
    const dispatch = useDispatch();
    const {productId} = props
    const {
        title,
    } = useThemeSettings({ settingData })
    const {
        inventorySettings,
        productSettings,
    } = useThemeSettingsData({ themeSettingsData })

    const {
        getInventorySettingStatus
    } = useProductConfigState({product})

    useEffect(() => {
        if (product?.MediaContent?.[0] && product?.MediaContent?.[0].displayGroup) {
            const group = product.MediaContent.filter(x => x.group === product?.MediaContent?.[0].displayGroup).filter(x => !x.primary);
            const primary = product?.MediaContent.filter(x => x.primary);
            product.MediaContent = product.MediaContent.filter(x => x.group !== product?.MediaContent?.[0].displayGroup).filter(x => !x.primary);
            group.unshift(...primary)
            product.MediaContent.unshift(...group);
        }
    }, [product])

    const getProductById = ()=>{
        const requestOptions = {
            headers: { 'Content-Type': 'application/json', 'X-TENANT-ID': X_TENANT_ID },
        };
        let pattern = new RegExp('^[0-9]*$');
        if ( productId !== '' && pattern.test(productId)) {
            setIsLoadingProduct(true)
            dispatch(isLoading(true));
            axios
                .get(GET_PRODUCT + productId, requestOptions)
                .then((res)=>{
                    if ( res.data && res.data.id && res.data.isEnabled === true) {
                        let userCategoriesFlag = false;
                        if(categoriesByUserGroup.length > 0) {
                            if(res.data.ProductCategoryArray.length > 0){
                                categoriesByUserGroup.forEach(x => {
                                    res.data.ProductCategoryArray.forEach(y => {
                                        if(x === y.id) {
                                            userCategoriesFlag = true;
                                        }
                                    })
                                })
                            }
                        } else {
                            userCategoriesFlag = true;
                        }
                        if(userCategoriesFlag) {
                            setProduct(res.data);
                            if(!!!res.data.AllowOutofStock || getInventorySettingStatus(res.data)) {
                                dispatch(fetchProductStock(productId))
                            }
                        }
                    }
                })
                .catch((err)=>{
                    console.log('err', err);
                }).finally(() => {
                    dispatch(isLoading(false))
                    setTimeout(()=>{
                        setIsLoadingProduct(false)
                    }, 1000)
            });
        }
    };
    const resetProduct = () => {
        setIsLoadingProduct(false)
        setProduct(null)
    }


    useEffect(() => {
        if (!!productId && loadingUserCategories) {
            getProductById();
        }
    }, [productId, loadingUserCategories]);

    const getCategoryTree = (categoryName, tree) => {
        const category = categoriesList.find(x => x.categoryName === categoryName)
        if(!!category?.parent_id) {
            const parent = categoriesList.find(x => x.id === category.parentId)
            tree.push(parent.categoryName)
            getCategoryTree(parent.categoryName, tree)
        }
        return tree;
    }

    return {
        product,
        productSettings,
        tabName,
        userGroupDiscount,
        title,
        resetProduct,
        isLoadingProduct,
        getCategoryTree,
    }
}
