import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import Swiper from "react-id-swiper";

const ProductImageGallery = ({ product, gallerySwiper, setSelectedProductColor, thumbnailSwiper, getThumbnailSwiper, getGallerySwiper, selectedColor, setSelectedColor }) => {
  useEffect(() => {
    let key = 0;
    if (selectedColor) {
      if (product.MediaContent) {
        product.MediaContent.map((single, index) => {
          if (single.color === selectedColor) {
            key = index;
          }
        })
      }

      if (document.querySelectorAll('.swiper-pagination-bullet')) {
        let color_current = "";
        if (document.querySelector("#sw1")) {
          if (document.querySelector("#sw1").querySelector(".swiper-slide-active")) {
            if (document.querySelector("#sw1").querySelector(".swiper-slide-active").querySelector("span")) {
              color_current = document.querySelector("#sw1").querySelector(".swiper-slide-active").querySelector("span").innerText;

            }
          }
        }
        if (color_current != selectedColor) {
          const frameZones = Array.from(document.querySelectorAll('.swiper-pagination-bullet'));
          let flag = true;
          frameZones && frameZones.map(async (el, k) => {
            if (k === key && flag) {
              flag = false;
              let opts = { view: window, bubbles: true, cancelable: true, buttons: 1 };
              el.dispatchEvent(new MouseEvent("mousedown", opts));
              await new Promise(r => setTimeout(r, 50));
              el.dispatchEvent(new MouseEvent("mouseup", opts));
              el.dispatchEvent(new MouseEvent("click", opts));
            }
          })
        }
      }
    }
  }, [selectedColor])
  // effect for swiper slider synchronize
  useEffect(() => {
    if (
      gallerySwiper !== null &&
      gallerySwiper.controller &&
      thumbnailSwiper !== null &&
      thumbnailSwiper.controller
    ) {
      gallerySwiper.controller.control = thumbnailSwiper;
      thumbnailSwiper.controller.control = gallerySwiper;
    }
  }, [gallerySwiper, thumbnailSwiper]);

  const thumbnailSwiperParams = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    on: {
      'init': () => { },
      'slideChangeTransitionEnd': (el) => {
        if (document.querySelector("#sw1")) {
          if (document.querySelector("#sw1").querySelector(".swiper-slide-active")) {
            if (document.querySelector("#sw1").querySelector(".swiper-slide-active").querySelector("span")) {
              let color_current = document.querySelector("#sw1").querySelector(".swiper-slide-active").querySelector("span").innerText;
              if (selectedColor != null && selectedColor != "null" && color_current != selectedColor) {
                setSelectedColor(color_current)
              }
            }
          }
        }
      }
    },
    getSwiper: getThumbnailSwiper,
    spaceBetween: 20,
    slidesPerView: product?.MediaContent?.length >= 4 ? 4: product?.MediaContent?.length >= 3 ? 3 : product?.MediaContent?.length >= 2 ? 2 : 1,
    loopedSlides: 4,
    touchRatio: 0.2,
    freeMode: true,
    loop: product?.MediaContent?.length <= 1 ? false : true,
    slideToClickedSlide: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    renderPrevButton: () => (
      <>
        {product?.MediaContent?.length > 4 && 
          <button className="swiper-button-prev ht-swiper-button-nav">
            <i className="pe-7s-angle-left"  />
          </button>
        }
      </>
    ),
    renderNextButton: () => (
      <>
        {product?.MediaContent?.length > 4 && 
          <button className="swiper-button-next ht-swiper-button-nav">
            <i className="pe-7s-angle-right"  />
          </button>
        }
      </>
    )
  };

  return (
    <Fragment>
      <div className="product-small-image-wrapper mt-15" id={"sw1"}>
        <Swiper {...thumbnailSwiperParams}>
          {product.MediaContent &&
            product.MediaContent.map((single, key) => {
              return (
                <div key={key}>
                  <div className="single-image">
                    <LightgalleryProvider>
                      <LightgalleryItem galleryClassName=" " group="any" src={single.url}>
                        <img
                          src={process.env.PUBLIC_URL + single.url}
                          className="img-fluid"
                          alt=""
                          onClick={() => { if (single.color != "") { setSelectedColor(single.color) } }}
                        />
                        <span style={{ display: "none" }}>{single.color}</span>
                      </LightgalleryItem>
                    </LightgalleryProvider>
                  </div>
                </div>
              );
            })}
        </Swiper>
      </div>
    </Fragment>
  );
};

ProductImageGallery.propTypes = {
  product: PropTypes.object
};

export default ProductImageGallery;
