import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { getDiscountPrice, getFirstPrice } from "../../helpers/product";
import ProductHatsDescriptionInfo from "../../components/product/ProductHatsDescriptionInfo";
import ProductImageGalleryHats from "../../components/product/ProductImageGalleryHats";
import { AuthContext } from "../../common/Auth";
import { useThemeSettingsData } from "../../layouts/state/useThemeSettingsData";

const ProductHatsImageDescription = ({
  spaceTopClass,
  spaceBottomClass,
  product,
  currency,
  cartItems,
  wishlistItems,
  artworkList,
  compareItems,
  artworkListById
}) => {
  const { addToast } = useToasts()
  artworkListById = artworkListById?.concat(artworkList?.filter(al => artworkListById?.every(ali => ali?.id != al?.id)));
  const [selectedColor, setSelectedColor] = useState(null)
  const [selectedThumbImgColor, setSelectedThumbImgColor] = useState(null)
  const [selectedDecorations, setSelectedDecorations] = useState(null)
  const [singleSelectedDecorations, setSingleSelectedDecorations] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const [colorList, setColorList] = useState('');  
  const [toggle, setToggle] = useState(false)
  const [isReplace, setIsReplace] = useState(true)
  const [decimalsToShow, setDecimalsToShow] = useState(2);
  const [tempValue, setTempValue] = useState(0)
  const { userGroupDiscount, themeSettingsData } = useContext(AuthContext)
  
  let productPrice = product.price ? product.price : 0
  let productDiscount = product.discount ? product.discount : 0
  let discountedPrice = getDiscountPrice(productPrice, productDiscount)
  let finalProductPrice = Number(getFirstPrice(product, decimalsToShow))
  
  const {
    productSettings,
  } = useThemeSettingsData({ themeSettingsData });
  
  const [swiperKey, setSwiperKey] = useState(0);
  
  const [sliderListingData, setSliderListingData] = useState([])
  const [decorationLocation, setDecorationLocations] = useState([]);
  const [decorationLocationsMain, setDecorationLocationsMain] = useState([]);

    useEffect(() => {
        const groupedItems = {};
        product.LogoInfo.forEach(item => {
            const color = item.color;
            const matchingMediaContent = product.MediaContent.filter(res =>
                res.color === color &&
                res.url === item.image &&
                (item.location || item.extra_logo.length)
            );

            if (matchingMediaContent.length > 0) {
                if (!groupedItems[color]) {
                    groupedItems[color] = [];
                }
                groupedItems[color].push(...matchingMediaContent);
            }
        });
        const colorListData = Object.keys(groupedItems)
        setColorList(colorListData)
        const reorderedMediaContent = Object.values(groupedItems).flatMap(group => group.length > 1 ? group : group[0]);
        product.MediaContent = reorderedMediaContent;
        if (product?.MediaContent?.[0] && product?.MediaContent?.[0].displayGroup) {
            const group = product.MediaContent.filter(x => x.group === product?.MediaContent?.[0].displayGroup).filter(x => !x.primary);
            const primary = product?.MediaContent.filter(x => x.primary);
            product.MediaContent = product.MediaContent.filter(x => x.group !== product?.MediaContent?.[0].displayGroup).filter(x => !x.primary);
            group.unshift(...primary)
            product.MediaContent.unshift(...group);
        }
        let tempData = product?.MediaContent.filter(res => res.color === product?.MediaContent?.[0]?.color)
        setSliderListingData(tempData)
        setSwiperKey(prevKey => prevKey + 1);
    }, [product]);

  const tempfun = () => {
    setTempValue(tempValue + 1)
  }

  const wishlistItem = wishlistItems?.filter(
    (wishlistItem) => wishlistItem.id === product.id,
  )[0]

  const compareItem = compareItems?.filter(
    (compareItem) => compareItem.id === product.id,
  )[0]

  const finalDiscountedPrice = +(
    discountedPrice * currency.currencyRate
  ).toFixed(decimalsToShow)

  useEffect(() => {
    if (!!productSettings?.decimalsToShow) {
      setDecimalsToShow(Number(productSettings?.decimalsToShow));
    }
  }, [productSettings])

  let showSell = false
  let showSellPrice = false
  let offerPrice = 0
  let discountType = ''
  let todayDate = new Date().toISOString()
  let finalProductDiscPrice = 0

  if (todayDate >= product?.startDate && todayDate <= product?.endDate) {
    if (product?.makeProductSale) {
      showSell = true
    }
  } else if (product?.startDate == '' || product?.startDate == undefined) {
    if (product.makeProductSale) {
      showSell = true
    }
  }

  if (
    product?.showPriceBreak &&
    todayDate >= product?.startDate &&
    todayDate <= product?.endDate
  ) {
    showSellPrice = true
  }

  if (product?.sellingPrice) {
    offerPrice = product.sellingPrice
  }

  if (product?.discountType == '0' || product?.discountType == '1') {
    discountType = product?.discountType
  }

  if (Number(userGroupDiscount > 0)) {
    finalProductPrice =
      Number(finalProductPrice) -
      (Number(finalProductPrice) * Number(userGroupDiscount)) / 100
  }

  if (discountType == '1') {
    if (offerPrice > 0) {
      finalProductDiscPrice = Number(finalProductPrice) - Number(offerPrice)
    }
  } else if (discountType == '0') {
    if (offerPrice > 0) {
      finalProductDiscPrice =
        Number(finalProductPrice) -
        (Number(finalProductPrice) * Number(offerPrice)) / 100
    }
  }
  const getImageForLocation = (location, tempData, idx) => {
    if (!tempData) return null;
  
    for (let index = 0; index < tempData.length; index++) {
      const single = tempData[index];
      for (const logoInfo of product?.LogoInfo || []) {
        if (
          (logoInfo.location == location.locationName && single.color == logoInfo.color && single.url == logoInfo?.image) ||
          (logoInfo.extra_logo?.some(val => val.location == location.locationName) && single.color == logoInfo.color && single.url == logoInfo?.image)
        ) {
          return {single, idx};
        }
      }
    }
  
    return null;
  };
  
  useEffect(() => {
    if (!selectedColor) return;
    const tempData = product?.MediaContent.filter(res => res.color === selectedColor);
    if (!tempData) return;

    let newArray = [];
    let getData = null;
    let i = 0
    decorationLocationsMain?.length && decorationLocationsMain.forEach((location, index) => {
      getData = getImageForLocation(location, tempData, i);
      if (getData) {
        i++;
        decorationLocationsMain[index] = Object.assign({}, location, { noImage: false });
        const obj = Object.assign({}, getData.single, { locationData: `${location.id}-${getData.idx}` });
        newArray.push(obj);
      } else {
        decorationLocationsMain[index] = Object.assign({}, location, { noImage: true });
      }
    });
    let new_location_array = decorationLocationsMain.filter(x => !x.noImage)
    setDecorationLocations(new_location_array)
    setSliderListingData(newArray.length > 0 ? newArray : tempData);
    setSwiperKey(prevKey => prevKey + 1);
  }, [selectedColor, decorationLocationsMain]);

  return (
    <div
      className={`shop-area product-huts ${spaceTopClass ? spaceTopClass : ''} ${spaceBottomClass ? spaceBottomClass : ''
        }`}
    >
      <div className="pl-2 pr-2">
        <div className="row m-0">
          <div className="col-lg-4 col-md-6">
            {sliderListingData?.length > 0 &&
            <ProductImageGalleryMemoized
              product={product}
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
              artworkListById={artworkListById}
              selectedDecorations={selectedDecorations}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
              singleSelectedDecorations={singleSelectedDecorations}
              setSelectedThumbImgColor={setSelectedThumbImgColor}
              selectedThumbImgColor={selectedThumbImgColor}
              isReplace={isReplace}
              setIsReplace={setIsReplace}
              sliderListingData={sliderListingData}
              swiperKey={swiperKey}
              />}
          </div>

          <div className="col-lg-8 col-md-6">
          {sliderListingData?.length &&
            <ProductHatsDescriptionInfo
              product={product}
              colorList={colorList}
              setDecorationLocations={setDecorationLocations}
              setDecorationLocationsMain={setDecorationLocationsMain}
              decorationLocationsMain={decorationLocationsMain}
              decorationLocation={decorationLocation}
              setSelectedColor={setSelectedColor}
              setSelectedThumbImgColor={setSelectedThumbImgColor}
              selectedColor={selectedColor}
              setToggle={setToggle}
              toggle={toggle}
              selectedThumbImgColor={selectedThumbImgColor}
              setSelectedDecorations={setSelectedDecorations}
              discountedPrice={discountedPrice}
              currency={currency}
              finalDiscountedPrice={finalDiscountedPrice}
              finalProductPrice={finalProductPrice}
              cartItems={cartItems}
              sliderListingData={sliderListingData}
              wishlistItem={wishlistItem}
              compareItem={compareItem}
              addToast={addToast}
              showSell={showSell}
              showSellPrice={showSellPrice}
              offerPrice={offerPrice}
              discountType={discountType}
              finalProductDiscPrice={finalProductDiscPrice}
              tempfun={tempfun}
              setSingleSelectedDecorations={setSingleSelectedDecorations}
              singleSelectedDecorations={singleSelectedDecorations}
              setSelectedImage={setSelectedImage}
              selectedImage={selectedImage}
              isReplace={isReplace}
              setIsReplace={setIsReplace}
              selectedDecorations ={selectedDecorations }
              swiperKey={swiperKey}
            />}
          </div>
        </div>
      </div>
    </div>
  )
};

ProductHatsImageDescription.propTypes = {
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  currency: PropTypes.object,
  galleryType: PropTypes.string,
  product: PropTypes.object,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  wishlistItems: PropTypes.array
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
    cartItems: state.cartData,
    wishlistItems: state.wishlistData,
    artworkList: state.productData.artworkList,
    artworkListById: state.productData.artworkListById,
    compareItems: state.compareData
  };
};
const ProductImageGalleryMemoized = React.memo(ProductImageGalleryHats);

export default connect(mapStateToProps)(ProductHatsImageDescription);
