import uuid from "uuid/v4";
import moment from "moment";
import {
    ADD_ROWS_TO_CART,
    ADD_TO_CART,
    CART_DATA,
    DECREASE_QUANTITY,
    DELETE_ALL_FROM_CART,
    DELETE_DECO_CART,
    DELETE_FROM_CART,
    DELETE_SIZE_FROM_CART,
    EDIT_COLOR_CART,
    EDIT_QTY_CART,
    EDIT_SIZE_CART,
    UPDATE_TO_CART,
    UPDATE_TO_CART_COMMENTS,
} from "../actions/cartActions";
import {updateCart} from "../../util/cart/cartAPIUtil"

const initState = [];

const cartReducer = (state = initState, action) => {
  let cartItems = state,
    product = action.payload
  let publicIp = action.ipV4

  if (action.type === ADD_TO_CART) {
    // for non variant products
    if (product.variation == 'undefined') {
      const cartItem = cartItems.filter((item) => item.id === product.id)[0]
      if (cartItem === undefined) {
        return [
          ...cartItems,
          {
            ...product,
            quantity: product.quantity ? product.quantity : 1,
            cartItemId: uuid(),
            dateTime: moment(),
          },
        ]
      } else {
        return cartItems.map((item) =>
          item.cartItemId === cartItem.cartItemId
            ? {
                ...item,
                quantity: product.quantity
                  ? item.quantity + product.quantity
                  : item.quantity + 1,
              }
            : item,
        )
      }
      // for variant products
    } else {
      const cartItem = cartItems.filter(
        (item) =>
          item.id === product.id &&
          product.selectedProductColor &&
          product.selectedProductColor === item.selectedProductColor &&
          product.selectedProductSize &&
          product.selectedProductSize === item.selectedProductSize &&
          (product.cartItemId ? product.cartItemId === item.cartItemId : true),
      )[0]

      if (cartItem === undefined) {
        return [
          ...cartItems,
          {
            ...product,
            quantity: product.quantity ? product.quantity : 1,
            cartItemId: uuid(),
          },
        ]
      } else if (
        cartItem !== undefined &&
        (cartItem.selectedProductColor !== product.selectedProductColor ||
          cartItem.selectedProductSize !== product.selectedProductSize)
      ) {
        return [
          ...cartItems,
          {
            ...product,
            quantity: product.quantity ? product.quantity : 1,
            cartItemId: uuid(),
          },
        ]
      } else {
        return cartItems.map((item) =>
          item.cartItemId === cartItem.cartItemId
            ? {
                ...item,
                quantity: product.quantity
                  ? item.quantity + product.quantity
                  : item.quantity + 1,
                selectedProductColor: product.selectedProductColor,
                selectedProductSize: product.selectedProductSize,
                selectedProductDecorations: product.selectedProductDecorations,
                personalizationArray: product.personalizationArray,
              }
            : item,
        )
      }
    }
  }

  if (action.type === UPDATE_TO_CART || action.type === UPDATE_TO_CART_COMMENTS) {
    const cartItemToSave = cartItems.map((item) =>
      item.cartItemId === product.cartItemId
        ? {
            ...item,
          }
        : item,
    )
    updateCart(cartItemToSave, publicIp);
    return cartItemToSave;
  }

  if (action.type === DECREASE_QUANTITY) {
    if (product.quantity === 1) {
      const remainingItems = (cartItems, product) =>
        cartItems.filter(
          (cartItem) => cartItem.cartItemId !== product.cartItemId,
        )
      return remainingItems(cartItems, product)
    } else {
      return cartItems.map((item) =>
        item.cartItemId === product.cartItemId
          ? { ...item, quantity: item.quantity - 1 }
          : item,
      )
    }
  }
    if (action.type === DELETE_FROM_CART) {
        let isPriceBreak = action.isPriceBreak
        const mainIndex = action.mainIndex;
        const rowIndex = action.rowIndex;
        const remainingItems = (cartItems, product) => {
            cartItems = cartItems.filter((cartItem, index) => {
                if (cartItem.product.id === product.id && index === mainIndex) {
                    cartItem.currentOrder = cartItem.currentOrder.filter((rowSata, key) => key !== rowIndex)
                    cartItem.dateTime = moment()
                    return cartItem
                } else {
                    return cartItem
                }
            }).filter(cartItem => cartItem.currentOrder.length)
            if (isPriceBreak) {
                let totalSum = 0;
                let tempcatItems = cartItems.map((cartItem, index) => {
                    if (cartItem.product.id == product.id) {
                        return cartItem
                    }
                }).filter(x => x);
                tempcatItems.map((object, index) => {
                    object && object.currentOrder && object.currentOrder.length > 0 && object.currentOrder.map((curr, currIndex) => {
                        curr && curr.length > 0 && curr.map((sizeObj, index) => {
                            totalSum = totalSum + Number(sizeObj.quantity)
                        })
                    })
                })
                let priceArray = []
                let obj1;
                tempcatItems.map((catItem, index) => {
                    let productPartArray = catItem?.product?.WebstoreProductPartArray && catItem?.product?.WebstoreProductPartArray.length > 0 ? product?.WebstoreProductPartArray : product?.ProductPartArray?.ProductPart
                    catItem && catItem.currentOrder && catItem.currentOrder.length > 0 && catItem.currentOrder.map((curr, currIndex) => {
                        curr && curr.length > 0 && curr.map((obj, index) => {
                            obj1 = productPartArray.find((productPart) => productPart.color == obj.color && productPart.size == obj.size)
                        })
                        if (obj1) {
                            priceArray.push(obj1)
                        }
                    })
                })
                let minQuantity = []
                priceArray && priceArray.length > 0 && priceArray.map((price) => {
                    let min = price?.partPrice?.PartPriceArray?.PartPrice.filter(
                        (val) => Number(val.minQuantity) <= Number(totalSum),
                    )
                    if (min) {
                        minQuantity.push(min)
                    }
                })
                let allMaxQty = []
                minQuantity.map((qtyMin) => {
                    let maxQty = qtyMin && qtyMin.length > 0 && qtyMin.reduce((prev, current) => (Number(prev?.minQuantity) > Number(current.minQuantity)) ? prev : current)
                    if (maxQty) {
                        allMaxQty.push(maxQty)
                    }
                })
                let salePrice = allMaxQty && allMaxQty.length > 0 && allMaxQty.reduce((prev, current) => (Number(prev?.salePrice) > Number(current.salePrice)) ? prev : current)
                cartItems && cartItems.length > 0 && cartItems.map((cartItem, index) => {
                    if (cartItem.product.id == product.id) {
                        cartItem && cartItem.length > 0 && cartItem.currentOrder.map((curr, currIndex) => {
                            curr && curr.length > 0 && curr.map((order, index) => {
                                order.price = (salePrice?.salePrice && Number(salePrice?.salePrice) != 0) ? Number(salePrice?.salePrice) : Number(order.price)
                            })
                        })
                    }
                });
            }
            updateCart(cartItems, publicIp)
            return cartItems
        }
        return remainingItems(cartItems, product)
  }
  if (action.type === DELETE_ALL_FROM_CART) {
    cartItems= cartItems.filter((item) => {
      return false
    })
	updateCart(cartItems, publicIp)

	return cartItems

  }
  if (action.type === ADD_ROWS_TO_CART) {
    let newItem = action.payload
	let isPriceBreak = action.isPriceBreak
    const checkcartItem = cartItems.filter(
      (cartItem) => cartItem.product.cartItemId === newItem.product.cartItemId,
    )[0]
    let kitProducts = []
    if(newItem && newItem?.product && newItem?.product?.ProductAsKit.length > 0 && newItem?.product?.isKitEnabled === true ) {
      let kitData = newItem?.product?.ProductAsKit[0]?.KitProduct.map((newItem2) => {
        let getProductPart = newItem2 && (newItem2?.webstoreProduct) && newItem2?.webstoreProduct?.ProductPartArray?.ProductPart.map((newData) => { return newData })
          if(getProductPart && getProductPart.length > 0) {
              let getSize = getProductPart[0] && getProductPart[0]?.ApparelSize?.labelSize
              let getColor = getProductPart[0] && getProductPart[0]?.ColorArray?.Color?.colorName
              let getSku = getProductPart[0] && getProductPart[0]?.sku
              let getPrice = getProductPart[0] && getProductPart[0]?.partPrice?.PartPriceArray?.PartPrice[0]?.salePrice
              let getImage = newItem2?.MediaContent[0]?.url
              let preTotalPrice = (newItem && newItem?.rowsData) ? newItem?.rowsData?.[0]?.[0]?.totalPrice : 0
              let tempRowData = [
                  [
                      {
                          "color": getColor,
                          "decorations": [],
                          "id": newItem2?.pId,
                          "image": getImage,
                          "personalization": null,
                          "price": getPrice,
                          "product": { sku: getSku },
                          "quantity": 1,
                          "setupCharges": [],
                          "size": getSize,
                          "totalPrice": Number(getPrice) + Number(preTotalPrice) ,
                          "productKits":true,
                          "isShowPrice": newItem.product.isShowPrice,
                          "isQuantity": newItem.product.isQuantity,
                      }
                  ]
              ]
              newItem2.currentOrder = tempRowData
              newItem2.dateTime = moment()
              newItem2.product = newItem2?.webstoreProduct
              kitProducts.push({
                  currentOrder: tempRowData,
                  product: newItem2?.webstoreProduct,
                  dateTime: moment(),
                  showProductAsKits:true
              })
          }
      }) 
    }
   let tempData;
    if (checkcartItem === undefined) {
      tempData = [
        ...cartItems,
        {
          currentOrder: newItem.rowsData,
          product: { ...newItem.product, cartItemId: uuid() },
          dateTime: moment(),
        },
      ]
      if(kitProducts && kitProducts.length){
        kitProducts.map((obj)=>{
          Object.assign(obj.product,{"cartItemId": uuid()})
          tempData.push(obj)
        })
      }
    } else {
      tempData = cartItems.filter((cartItem) => {
        if (cartItem.product.id === newItem.product.id) {
          cartItem.currentOrder = newItem.rowsData
          cartItem.dateTime = moment()
        }
        return cartItem
      })
      if(kitProducts && kitProducts.length > 0){
        kitProducts.map((obj)=>{
          Object.assign(obj.product,{"cartItemId": uuid()})
          tempData.push(obj)
        })
      }
    }
	  if (isPriceBreak) {
		  let totalSum = 0;
		  let tempcatItems = tempData.map((cartItem, index) => {
			  if (cartItem.product.id == newItem.product.id) {
				  return cartItem
			  }
		  }).filter(x => x);
          tempcatItems.map((object, index) => {
              object.currentOrder && object.currentOrder.map((curr, currIndex) => {
                  curr && curr.length > 0 && curr.map((sizeObj, index) => {
                      totalSum = totalSum + Number(sizeObj?.quantity && sizeObj?.quantity)
                  })
              })
          })
		  let priceArray = []
		  let obj1;
		  tempcatItems.map((catItem, index) => {
			  let productPartArray = catItem?.product?.WebstoreProductPartArray && catItem?.product?.WebstoreProductPartArray.length > 0 ? product?.product?.WebstoreProductPartArray : product?.product?.ProductPartArray?.ProductPart
			  catItem?.currentOrder&&catItem?.currentOrder.length>0&&catItem.currentOrder.map((curr, currIndex) => {
				curr.map((obj, index) => {
					obj1 = productPartArray.find((productPart) => productPart.color == obj.color && productPart.size == obj.size)
				})
				if(obj1){
					priceArray.push(obj1)
				}
			})
			  })
		  let minQuantity = []
		  priceArray.map((price) => {
			  let min = price?.partPrice?.PartPriceArray?.PartPrice.filter(
				  (val) => Number(val.minQuantity) <= Number(totalSum),
			  )
			  minQuantity.push(min)
		  })
		  let allMaxQty = []
		  minQuantity.map((qtyMin) => {
			  let maxQty = qtyMin && qtyMin.length>0 && qtyMin.reduce((prev, current) => (Number(prev?.minQuantity) > Number(current.minQuantity)) ? prev : current)
			  if (maxQty) {
				  allMaxQty.push(maxQty)
			  }
		  })
		  let salePrice = allMaxQty.length > 0 && allMaxQty.reduce((prev, current) => (Number(prev?.salePrice) > Number(current?.salePrice)) ? prev : current)
		  tempData.map((cartItem, index) => {
			  if (cartItem.product.id == newItem.product.id) {
				  cartItem.currentOrder.map((curr,currIndex)=>{
					curr.map((order, index) => {
						order.price = (salePrice?.salePrice && Number(salePrice?.salePrice) != 0) ? Number(salePrice?.salePrice) : Number(order.price)
					})
				  })
			  }
		  });
	  }
	  updateCart(tempData, publicIp)
	  return tempData;
  }
  if (action.type === DELETE_DECO_CART) {
    let rowIndexTemp = action.rowIndex
    let decoIndexTemp = action.decoIndex
    let rowMainIndexTemp = action.mainIndex

    const remainingItems = (cartItems, product) => {
      cartItems.filter((cartItem , cartIndex) => {
        if (cartItem.product.id == product.product.id && rowMainIndexTemp === cartIndex) {
          let tempOrder = cartItem.currentOrder
          if (tempOrder[rowIndexTemp]) {
            let tempRows = tempOrder[rowIndexTemp]
            tempRows = tempRows.filter((rowData) => {
              if (rowData.decorations[decoIndexTemp]) {
                rowData.decorations = rowData.decorations.filter(
                  (deco, key) => key !== decoIndexTemp,
                )
              }
              return rowData
            })
            tempOrder[rowIndexTemp] = tempRows
          }
          tempOrder = tempOrder.filter((rowArray) => rowArray.length)
          cartItem.currentOrder = tempOrder
          return cartItem
        } else {
          return cartItem
        }
      })
      cartItems = cartItems.filter((item) => item.currentOrder.length)
	  updateCart(cartItems, publicIp)
      return cartItems
    }
    return remainingItems(cartItems, product)
  }
  if(action.type === EDIT_COLOR_CART) {
    const remainingItems = (cartItems, product) => {
      let rowIndexTemp = action.rowIndex
      let colorIndex = action.color
      let imageIndex = action.image
      cartItems.filter((cartItem, index) => {
        if (
          index == rowIndexTemp &&
          cartItem.product.id == product.product.id
        ) {
          let tempOrder = cartItem.currentOrder

          if (tempOrder) {
            let tempRow = tempOrder[0]
            if (tempRow[0]) {
              tempRow[0].color = colorIndex
              tempRow[0].image = imageIndex
              tempOrder[0] = tempRow
            }
            tempOrder[0] = tempRow
          }
          cartItem.currentOrder = tempOrder
          cartItem.dateTime = moment()
          return cartItem
        } else {
          return cartItem
        }
      })
      cartItems = cartItems.filter((item) => item.currentOrder.length)
	  updateCart(cartItems, publicIp)
      return cartItems
    }
    return remainingItems(cartItems, product)
  }
  if (action.type === EDIT_QTY_CART) {
	let sizeindexTemp = action.sizeindex
	let newItem = action.payload
	let rowIndexTemp = action.rowIndex
	let mainRowIndex = action.mainRowIndex
	let qtyTemp = action.qty
	let totalQty = Number(qtyTemp)
	const remainingItems = (cartItems, product) => {
		let isPriceBreak = action.isPriceBreak
    cartItems.filter((cartItem, index) => {
			if (cartItem.product.id === product.product.id) {
        let tempOrder = cartItem.currentOrder
        if (tempOrder) {
					let tempRow = tempOrder[mainRowIndex]
          if (tempRow && tempRow.length >= sizeindexTemp && tempRow[sizeindexTemp]) {
						let productSize = tempRow[sizeindexTemp].size
						let productColor = tempRow[sizeindexTemp].color
						let productPartArray = product?.product?.WebstoreProductPartArray && product?.product?.WebstoreProductPartArray.length > 0 ? product?.product?.WebstoreProductPartArray : product?.product?.ProductPartArray?.ProductPart
						let productSizeData =
							productPartArray.filter(
								(p) => p.ApparelSize.labelSize == productSize,
							)
						let productPriceData = []
						if (productSizeData.length > 0) {
							let productColorData = productSizeData.filter(
								(p) => p.ColorArray.Color.colorName == productColor,
							)
							if (productColorData.length > 0) {
								productPriceData =
									productColorData[0]?.partPrice?.PartPriceArray?.PartPrice.filter(
										(val) => Number(val.minQuantity) <= Number(totalQty),
									)

								if (productPriceData.length > 0) {
									productPriceData = productPriceData.reduce((prev, current) => (Number(prev?.minQuantity) > Number(current.minQuantity)) ? prev : current)
								}

							}
						}
							if (index === rowIndexTemp) {
								tempRow[sizeindexTemp].quantity = qtyTemp
								tempRow[sizeindexTemp].price =
									Object.keys(productPriceData).length > 0 ? productPriceData?.salePrice : tempRow[sizeindexTemp].price
							}
            if(tempRow)
						  tempOrder[mainRowIndex] = tempRow
					}
          if(tempRow)
					  tempOrder[mainRowIndex] = tempRow
				}
				//tempOrder = tempOrder.filter((rowArray) => rowArray.length)
				cartItem.currentOrder = tempOrder
				cartItem.dateTime = moment()
				return cartItem
			}
			else {
				return cartItem
			}
		})
		if (isPriceBreak) {
			let totalSum = 0;
			let tempcatItems = cartItems.map((cartItem, index) => {
				if (cartItem.product.id == newItem.product.id) {
					return cartItem
				}
			}).filter(x => x);
			tempcatItems.map((object, index) => {
			  object&&object.currentOrder.map((curr, currIndex) => {
				  curr&&curr.length>0&&curr.map((sizeObj, index) => {
						totalSum = totalSum + Number(sizeObj?.quantity&&sizeObj?.quantity)
					})
				})
			})
			let priceArray = []
			let obj1;
			tempcatItems.map((catItem, index) => {
				let productPartArray = catItem?.product?.WebstoreProductPartArray && catItem?.product?.WebstoreProductPartArray.length > 0 ? product?.product?.WebstoreProductPartArray : product?.product?.ProductPartArray?.ProductPart
				catItem?.currentOrder&&catItem?.currentOrder.length>0&&catItem.currentOrder.map((curr, currIndex) => {
				  curr.map((obj, index) => {
					  obj1 = productPartArray.find((productPart) => productPart.color == obj.color && productPart.size == obj.size)
            let rPrice = 0
            if(obj1?.size === obj?.size && obj?.color === obj1?.ColorArray?.Color?.colorName) {
              obj1.partPrice.PartPriceArray.PartPrice.map(
                (partPrice, k) => {
                  if (
                      Number(partPrice.minQuantity) <= Number(totalSum)
                  ) {
                    rPrice = partPrice.salePrice
                  } else if (k === 0) {
                    rPrice = partPrice.salePrice
                  }
                },
              )
              obj.price = rPrice
            }
				  })
				  /*if(obj1){
					  priceArray.push(obj1)
				  }*/
			  })
				})
			/*let minQuantity = []
			priceArray.map((price) => {
				let min = price?.partPrice?.PartPriceArray?.PartPrice.filter(
					(val) => Number(val.minQuantity) <= Number(totalSum),
				)
				minQuantity.push(min)
			})
			let allMaxQty = []
			minQuantity.map((qtyMin) => {
				let maxQty = qtyMin && qtyMin.length>0 && qtyMin.reduce((prev, current) => (Number(prev?.minQuantity) > Number(current.minQuantity)) ? prev : current)
				if (maxQty) {
					allMaxQty.push(maxQty)
				}
			})
			let salePrice = allMaxQty.length > 0 && allMaxQty.reduce((prev, current) => (Number(prev?.salePrice) > Number(current?.salePrice)) ? prev : current)
			cartItems.map((cartItem, index) => {
				if (cartItem.product.id == newItem.product.id) {
					cartItem.currentOrder.map((curr,currIndex)=>{
					  curr.map((order, index) => {
						  order.price = (salePrice?.salePrice && Number(salePrice?.salePrice) != 0) ? Number(salePrice?.salePrice) : Number(order.price)
					  })
					})
				}
			});*/
		}
		cartItems = cartItems.filter((item) => item.currentOrder.length)
		updateCart(cartItems, publicIp)
		return cartItems
	}
	return remainingItems(cartItems, product)
  }
    if (action.type === EDIT_SIZE_CART) {
        let newItem = action.payload
        let sizeindexTemp = action.sizeindex
        let rowIndexTemp = action.rowIndex
        let qtySize = action.size
        let mainRowIndex = action.mainRowIndex
        let isPriceBreak = action.isPriceBreak
        let color = action.color;

        const remainingItems = (cartItems, product) => {
            let partArray = []
            if (product?.product?.WebstoreProductPartArray && product?.product?.WebstoreProductPartArray.length > 0) {
                partArray = product.product.WebstoreProductPartArray
            } else {
                partArray = product?.product?.ProductPartArray?.ProductPart
            }
            let productPartData = partArray
            cartItems.filter((cartItem, index) => {

                if (cartItem.product.id == product.product.id && index === mainRowIndex) {
                    let tempOrder = cartItem.currentOrder
                    if (tempOrder[rowIndexTemp]) {
                        let tempRow = tempOrder[rowIndexTemp]
                        if (tempRow[sizeindexTemp]) {
                            let newPrice = tempRow[sizeindexTemp].price
                            productPartData.map((val, i) => {
                                if (val.ApparelSize.labelSize == qtySize && val.ColorArray.Color.colorName === color) {
                                    newPrice =
                                        val.partPrice.PartPriceArray.PartPrice[0].salePrice
                                }
                            })
                            tempRow[sizeindexTemp].size = qtySize
                            tempRow[sizeindexTemp].price = newPrice
                            tempOrder[rowIndexTemp] = tempRow
                        }
                        tempOrder[rowIndexTemp] = tempRow
                    }
                    tempOrder = tempOrder.filter((rowArray) => rowArray.length)
                    cartItem.currentOrder = tempOrder
                    cartItem.dateTime = moment()
                    return cartItem
                } else {
                    return cartItem
                }
            })
            if (isPriceBreak) {
                let totalSum = 0;
                let tempcatItems = cartItems.map((cartItem, index) => {
                    if (cartItem.product.id == newItem.product.id) {
                        return cartItem
                    }
                }).filter(x => x);
                tempcatItems.map((object, index) => {
                    object && object.currentOrder.map((curr, currIndex) => {
                        curr && curr.length > 0 && curr.map((sizeObj, index) => {
                            totalSum = totalSum + Number(sizeObj?.quantity && sizeObj?.quantity)
                        })
                    })
                })
                let priceArray = []
                let obj1;
                tempcatItems.map((catItem, index) => {
                    let productPartArray = catItem?.product?.WebstoreProductPartArray && catItem?.product?.WebstoreProductPartArray.length > 0 ? product?.product?.WebstoreProductPartArray : product?.product?.ProductPartArray?.ProductPart
                    catItem?.currentOrder && catItem?.currentOrder.length > 0 && catItem.currentOrder.map((curr, currIndex) => {
                        curr.map((obj, index) => {
                            obj1 = productPartArray.find((productPart) => productPart.color == obj.color && productPart.size == obj.size)
                            let rPrice = 0
                            if(obj1?.size === obj?.size && obj?.color === obj1?.ColorArray?.Color?.colorName) {
                              obj1.partPrice.PartPriceArray.PartPrice.map(
                                (partPrice, k) => {
                                  if (
                                      Number(partPrice.minQuantity) <= Number(totalSum)
                                  ) {
                                    rPrice = partPrice.salePrice
                                  } else if (k === 0) {
                                    rPrice = partPrice.salePrice
                                  }
                                },
                              )
                              obj.price = rPrice
                            }
                        })
                        /*if (obj1) {
                            priceArray.push(obj1)
                        }*/
                    })
                })
                /*let minQuantity = []
                priceArray.map((price) => {
                    let min = price?.partPrice?.PartPriceArray?.PartPrice.filter(
                        (val) => Number(val.minQuantity) <= Number(totalSum),
                    )
                    minQuantity.push(min)
                })
                let allMaxQty = []
                minQuantity.map((qtyMin) => {
                    let maxQty = qtyMin && qtyMin.length > 0 && qtyMin.reduce((prev, current) => (Number(prev?.minQuantity) > Number(current.minQuantity)) ? prev : current)
                    if (maxQty) {
                        allMaxQty.push(maxQty)
                    }
                })
                let salePrice = allMaxQty.length > 0 && allMaxQty.reduce((prev, current) => (Number(prev?.salePrice) > Number(current?.salePrice)) ? prev : current)
                cartItems.map((cartItem, index) => {
                    if (cartItem.product.id == newItem.product.id) {
                        cartItem.currentOrder.map((curr, currIndex) => {
                            curr.map((order, index) => {
                                order.price = (salePrice?.salePrice && Number(salePrice?.salePrice) != 0) ? Number(salePrice?.salePrice) : Number(order.price)
                            })
                        })
                    }
                });*/
            }
            cartItems = cartItems.filter((item) => item.currentOrder.length)
            updateCart(cartItems, publicIp)
            return cartItems
        }
        return remainingItems(cartItems, product)
    }
  if (action.type === DELETE_SIZE_FROM_CART) {
    let sizeindexTemp = action.sizeindex
    let rowIndexTemp = action.rowIndex
    let isPriceBreak = action.isPriceBreak
    const remainingItems = (cartItems, product) => {
      cartItems.filter((cartItem) => {
        if (cartItem.product.id == product.product.id) {
          let tempOrder = cartItem.currentOrder
          if (tempOrder[rowIndexTemp]) {
            let tempRow = tempOrder[rowIndexTemp]
            if (tempRow[sizeindexTemp]) {
              tempRow = tempRow.filter((rowSata, key) => key !== sizeindexTemp)
              tempOrder[rowIndexTemp] = tempRow
            }
            tempOrder[rowIndexTemp] = tempRow
          }
          tempOrder = tempOrder.filter((rowArray) => rowArray.length)
          cartItem.currentOrder = tempOrder
          cartItem.dateTime = moment()
          return cartItem
        } else {
          return cartItem
        }
      })
      if (isPriceBreak) {
        let totalSum = 0;
        cartItems.map((object, index) => {
            object && object.currentOrder.map((curr, currIndex) => {
                curr && curr.length > 0 && curr.map((sizeObj, index) => {
                    totalSum = totalSum + Number(sizeObj?.quantity && sizeObj?.quantity)
                })
            })
        })
        let obj1;
        cartItems.map((catItem, index) => {
            let productPartArray = catItem?.product?.WebstoreProductPartArray && catItem?.product?.WebstoreProductPartArray.length > 0 ? product?.product?.WebstoreProductPartArray : product?.product?.ProductPartArray?.ProductPart
            catItem?.currentOrder && catItem?.currentOrder.length > 0 && catItem.currentOrder.map((curr, currIndex) => {
                curr.map((obj, index) => {
                    obj1 = productPartArray.find((productPart) => productPart.color == obj.color && productPart.size == obj.size)
                    let rPrice = 0
                    if(obj1?.size === obj?.size && obj?.color === obj1?.ColorArray?.Color?.colorName) {
                      obj1.partPrice.PartPriceArray.PartPrice.map(
                        (partPrice, k) => {
                          if (
                              Number(partPrice.minQuantity) <= Number(totalSum)
                          ) {
                            rPrice = partPrice.salePrice
                          } else if (k === 0) {
                            rPrice = partPrice.salePrice
                          }
                        },
                      )
                      obj.price = rPrice
                    }
                })
            })
        })
      }
      cartItems = cartItems.filter((item) => item.currentOrder.length)
	    updateCart(cartItems, publicIp)
      return cartItems
    }
    return remainingItems(cartItems, product)
  }
  if (action.type === CART_DATA) {
	cartItems=action.payload
	return cartItems;
  }
  return state
};

export default cartReducer;
