import PropTypes from "prop-types";
import React from "react";

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent
);

export const processForMobile = (htmlText) =>{
  const newHtmlContent = htmlText?.replace(/font-size: (\d+)px/g, (match, size) => {
    let newSize = parseInt(size);
    if (newSize > 50) {
      newSize = Math.min(newSize, 40);
    }
    return `font-size: ${newSize}px;`;
  });

  let htmlWithImages = newHtmlContent?.replace(/width:(\d+)px/g, (match, width) => {
    let newWidth = parseInt(width);
    if (newWidth > 400) {
      newWidth = `${Math.min(newWidth, 100)}%`;
    }else{
      newWidth = `${newWidth}px`;
    }
    return `width: ${newWidth}`;
  });

  return htmlWithImages;
}

const SectionTitle = ({
  titleText,
  subTitleText,
  spaceClass,
  borderClass,
  titleStyle,
  subTitleStyle,
  titlepositionClass,
  subTitlepositionClass
}) => {
  return (
    <div className={`section-title ${borderClass ? borderClass : ""} ${spaceClass ? spaceClass : ""}`} >
      <h2 className={titlepositionClass} style={titleStyle} dangerouslySetInnerHTML={{ __html: isMobile ? processForMobile(titleText) : titleText }}></h2>
      <p className={subTitlepositionClass} style={subTitleStyle} dangerouslySetInnerHTML={{ __html: isMobile ? processForMobile(subTitleText) : subTitleText }}></p>
    </div>
    
  )
}

SectionTitle.propTypes = {
  borderClass: PropTypes.string,
  positionClass: PropTypes.string,
  spaceClass: PropTypes.string,
  subTitleText: PropTypes.string,
  subtitleColorClass: PropTypes.string,
  titleText: PropTypes.string
};

export default SectionTitle;
